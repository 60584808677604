import {IRefItem, TValidationResult} from '../../types/Validation.types';

const strings = {
    field_is_required: 'Field is required',
};

type TRefValue = unknown;

function isRefItem(value: unknown): value is IRefItem {
    return typeof value === 'object'
           && value !== null
           && '_id' in value
           && typeof (value as IRefItem)._id === 'string';
}

function isRefArray(value: unknown): value is IRefItem[] {
    return Array.isArray(value) && value.every(isRefItem);
}

export const isValidRef = (
    value: TRefValue,
    type: 'ref' | 'refList' | 'connectedRef',
): TValidationResult => {
    if (type === 'ref' || type === 'connectedRef') {
        return {
            isValid: isRefItem(value),
            message: strings.field_is_required,
        };
    }

    if (type === 'refList') {
        const isValid = isRefArray(value) && value.length > 0;

        return {
            isValid,
            message: strings.field_is_required,
        };
    }

    return {isValid: false, message: strings.field_is_required};
};
