
import {TabsManager, toast} from '@aktek/f4kit';
import {Row} from '@tanstack/react-table';
import {useCallback} from 'react';

import F4TableSpreadsheet from '@/components/F4TableSpreadsheet/F4TableSpreadsheet';
import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import SmartFilterModal from '@/components/SmartFilter/SmartFilter.Modal';
import {strings} from '@/localization/i18n';

import useF4CurrentTab from '../Home/hooks/useF4CurrentTab';
import {DATA_TABLE_EMPTY_PROPS} from './constants/DataTable.EmptyProps.c';
import {MODES, useDataTable} from './hooks/useDataTable';
import useDataTableActions from './hooks/useDataTableActions';

type TDataTableViewTabState = {
    sboId: string;
};

const DataTableView = () => {
    const currentTab = useF4CurrentTab<TDataTableViewTabState>();
    const {sboId} = currentTab.getTabState();
    const tabTitle = currentTab.getConfig()?.title;

    const createTabConfig = {
        tabKey: 'recordEditor',
        title: `${strings('add_to')} ${tabTitle}`,
        props: {sboId},
    };

    const openRecordTab = (row, mode: 'edit' | 'create' | 'view' = 'view', canEdit = false) => {
        TabsManager.openNewTab(
            createTabConfig.tabKey,
            `${strings(mode)} ${tabTitle}`,
            {
                props: createTabConfig.props,
                mode,
                _id: row.original?.['_id'],
                ...(mode === 'view' && {canEdit}),
            },
        );
    };

    const {
        getRows,
        getColumns,
        getRowsCount,
        filter,
        // setFilter,
        onRibbonAction,
        ribbonActions,
        smartFilterModalRef,
        mode,
        setMode,
        f4TableSpreadSheetRef,
        tableRef,
        permissions,
    } = useDataTable(sboId);

    const {
        onRowAction,
        rowActions,
    } = useDataTableActions(permissions, openRecordTab);

    const onRowDoubleClick = useCallback((event, table, row: Row<unknown>) => {
        if (!permissions?.can_view) return;
        openRecordTab(row, 'view', permissions?.can_edit);
    }, [permissions]);

    return (
        <div className="bg-neutral-50 h-full p-4">
            {mode != MODES.TableView && <F4TableSpreadsheet
                ref={f4TableSpreadSheetRef}
                key={sboId +'-'+ mode}
                sboId={sboId}
                mode={mode}
                filter={filter}
                onCancel={() => {
                    setMode(MODES.TableView);
                }}
                onDelete={() => {
                    toast.success(strings('deleted_successfully'));
                    setMode(MODES.TableView);
                }}
                onSave={() => {
                    toast.success(strings('saved_successfully'));
                    setMode(MODES.TableView);
                }}
            />}
            {mode == MODES.TableView && <F4ViewableComponent
                hasViewMode
                eventId="data-table"
                tableRef={tableRef}
                onRowAction={onRowAction}
                idColumnName="_id"
                onRowDoubleClick={onRowDoubleClick}
                rowActions={rowActions}
                onRibbonAction={onRibbonAction}
                defaultSortedColumn="designation"
                name={strings('data_table')}
                getColumns={getColumns}
                createBehavior="tab"
                createTab={createTabConfig}
                emptyProps={DATA_TABLE_EMPTY_PROPS}
                canCreate={permissions?.can_create ?? false}
                getRowsCount={getRowsCount}
                hasRefreshButton={false}
                customRibbonActions={ribbonActions}
                getAllData={getRows}
                customAddButtonTooltip={strings('record')}
            />}
            <SmartFilterModal
                ref={smartFilterModalRef}
                sboId={sboId}
                filter={filter}
                // onChange={setFilter}
            />
        </div>
    );
};

export default DataTableView;
