import {DateTime} from '@aktek/f4kit';

const DateTimeCellEdit = ({value, onChange}) => {
    return <div className="flex items-center gap-1">

        <DateTime

            mode="datetime"
            value={value}
            onChange={onChange}
            placeholder="DD/MM/YYYY HH:mm:ss"
            minDate={0}
            maxDate={0}
            dateFormat="DD/MM/YYYY"
        />

    </div>;
};

export default DateTimeCellEdit;
