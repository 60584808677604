import {Icon} from '@aktek/f4kit';
import {faExclamationTriangle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {faDotCircle} from '@fortawesome/pro-duotone-svg-icons';
import {faExclamation, faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import {TConfig} from 'node_modules/@aktek/f4kit/dist/components/TierOne/Spreadsheet/Types/TConfig.t';

import {strings} from '@/localization/i18n';

import RefAddButton from '../components/AddRefButton';

export type TStringKey = keyof (typeof enFallback | typeof enSpreadsheet);

export type TStringValue = typeof enFallback[TStringKey] | typeof enSpreadsheet[keyof typeof enSpreadsheet];

console.log('....MorphSpreadsheet.Config.tsx -> Line 15', strings('spreadsheet_label_prepend_rows'));
export const Config: TConfig = {
    Icons: {
        sortIcon: (sort, isTarget, onUp, onDown, onReset) => (
            <RefAddButton
                onDown={onDown}
                onUp={onUp}
                isDown={sort === 'down'}
                isTarget={isTarget}
                onReset={onReset}
            />
        ),
        infoIcon: (message) => ( <Icon icon={faInfoCircle} tooltip={message} />),
        error: (message) => ( <Icon icon={faExclamationCircle} color="error-500" tooltip={message} />),
        modifiedIcon: (message) => ( <Icon icon={faDotCircle} color="warning-500" tooltip={message} />),

    },
    Messages: {
        isRequired: strings('spreadsheet_message_is_required'),
        prependRows: strings('spreadsheet_message_prepend_rows'),
        appendRows: strings('spreadsheet_message_append_rows'),
        warningRowsLimit: strings('spreadsheet_message_warning_rows_limit'),
        dangerRowsLimit: strings('spreadsheet_message_danger_rows_limit'),
        deleteNow: strings('spreadsheet_message_delete_now'),
        confirmationForDelete: strings('spreadsheet_confirmation_deleted_items'),
        spreadsheetLimitsMessage: strings('spreadsheet_limits_message'),
        areYouSureProceed: strings('spreadsheet_are_you_sure_proceed'),
    },
    Labels: {
        PREPEND_ROWS: strings('spreadsheet_label_prepend_rows'),
        APPEND_ROWS: strings('spreadsheet_label_append_rows'),
        SAVE: strings('spreadsheet_save'),
        ROWS: strings('spreadsheet_rows'),
        ID_COLUMN: 'ID',
        COUNT_NEW_ITEMS: strings('spreadsheet_count_new_items'),
        COUNT_MODIFIED_ITEMS: strings('spreadsheet_count_modified_items'),
        COUNT_DELETED_ITEMS: strings('spreadsheet_count_deleted_items'),
        DELETE: strings('spreadsheet_delete'),
        SPREADSHEET: strings('spreadsheet'),
        EDITING_CELL: strings('spreadsheet_editing'),
        EXIT: strings('spreadsheet_exit'),
        PROCEED: strings('spreadsheet_proceed'),
        CANCEL: strings('spreadsheet_cancel'),
        ERRORS: strings('spreadsheet_errors'),
    },
};

