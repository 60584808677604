import {useF4FormContext} from '@aktek/f4form';
import {FitHeight, Label} from '@aktek/f4kit';

import F4ImageColorSelector from '@/components/F4Elements/F4ExplorerNodeDesign';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import {TreeViewSelector} from '@/components/F4Elements/F4TreeViewSelector';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA} from '../../constants/constantIds.c';

type TFolderEditContentProps = {
    info: {
    folderName: string;
    parentId: string;
    icon: string;
    color: string;
},
    data: unknown[],
    updateInfoState: (key: string, value: string) => void,
    setSearchValue: React.Dispatch<React.SetStateAction<string>>,
    searchValue: string,
    finalInitialValue: unknown,
};

const FolderModalsContent = (props: TFolderEditContentProps) => {
    const {
        info,
        data,
        updateInfoState,
        setSearchValue,
        searchValue,
        finalInitialValue,
    } = props;

    const hasData = data && data.length > 0;

    const f4formContext = useF4FormContext();

    const handleTreeViewChange = (value) => {
        f4formContext?.setEditStatus(true);
        updateInfoState('parentId', value);
    };

    return (
        <FitHeight className="h-full" remainingSpaceClassName="overflow-hidden">

            <div className="sticky !shadow-none elevation-5 -top-5 bg-white -mt-1">

                <div className="flex items-start justify-start gap-3 mb-6" >
                    <div>
                        <F4ImageColorSelector
                            name="assets"
                            initialValue={finalInitialValue}
                            onChange={({type, value}) => {
                                if (type === 'color') {
                                    updateInfoState('color', value);
                                    updateInfoState('icon', null);

                                    return;
                                }

                                updateInfoState('icon', value);
                                updateInfoState('color', null);
                            }}
                        />
                    </div>

                    <F4Textbox
                        name="label"
                        label={strings('folder_name')}
                        autoFocus
                        required
                        placeholder=""
                        onChange={(e:string) => updateInfoState('folderName', e.trim())}
                        type="text"
                        colSpan={8}
                        f4FormDivClassName="flex flex-col justify-center items-start"
                    />
                </div>
                <SearchBox onSearch={setSearchValue}
                    autoFocusOnOpen={false}
                    className="!bg-neutral-100 rounded-lg mb-4"
                    textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                />
            </div>
            <>
                <TreeViewSelector
                    key="tree"
                    size="xs"
                    data={data}
                    scrollTimeOut={200}
                    forceExpandAllNodes={true}
                    searchTerm={searchValue}
                    parentId={info?.parentId}
                    value={info?.parentId || FOLDERS_AND_DATA}
                    scrollToValue="once"
                    onChange={handleTreeViewChange}
                />

                {!hasData && <Label
                    size="sm"
                    text={strings('no_results_found')}
                    className="mt-5 ml-3 overflow-hidden"
                    color="neutral-600"/>}
            </>
        </FitHeight>
    );
};

export default FolderModalsContent;
