import {FetchSBOData} from '../api/FetchSBOData';
import {getClearAffectsByFieldId} from './getClearAffectsByFieldId';
import getColumnFromField from './getColumnFromField';
import getSupportedFields from './getSupportedFields';
import getTagColumn from './getTagColumn';
import mapFields from './MapFields';
import {type TBuildColumnsResponse} from './Types/BuildColumns.types';

export default async (sboId: string, mode: 'creation' | 'viewing'): Promise<TBuildColumnsResponse> => {
    const res = await FetchSBOData(sboId);
    if (!res) return {sboId, columns: []};

    const {
        editableFieldsIDs,
        fieldsMap,
        sectionsMap,
        creationHasRecordTags,
        viewingHasRecordTags,
        sections,
    } = res;

    const isCreating = mode === 'creation';
    const editableFieldsIDsSet = new Set(editableFieldsIDs);
    const supportedFields = getSupportedFields(fieldsMap);
    const clearAffectsByField = getClearAffectsByFieldId(sections, sectionsMap, supportedFields, fieldsMap);

    const columns = mapFields(sections, sectionsMap, supportedFields, (field) => {
        if (!field) return;

        return getColumnFromField(field, editableFieldsIDsSet, fieldsMap, clearAffectsByField);
    });

    if (isCreating ? creationHasRecordTags : viewingHasRecordTags) {
        columns.push(getTagColumn(isCreating));
    }

    return {sboId, columns};
};

export const isConditionFunctionMatched = (conditionalStr: string): (o: unknown) => boolean => {
    return new Function('o', `return ${conditionalStr};`) as (o: unknown) => boolean;
};
