import {faClone, faCopy} from '@fortawesome/free-regular-svg-icons';
import {faArrowUpFromBracket} from '@fortawesome/pro-light-svg-icons';
import {faCloudArrowDown, faFilter, faHistory, faPlus, faRefresh} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {TTableRibbonAction} from '../Types/Ribbon.t';
import {TTableRibbonKnownActions} from '../Types/Table.t';

export const RibbonKnownActions : Record<TTableRibbonKnownActions, TTableRibbonAction> = {
    'add': {
        key: 'add',
        label: 'Add',
        buttonProps: {variant: 'primary'},
        icon: faPlus,
    },
    'refresh': {
        key: 'refresh',
        icon: faRefresh,
        label: 'Refresh',
        tooltip: strings('refresh_data'),
    },
    'upload': {
        key: 'upload',
        label: 'upload',
        icon: faArrowUpFromBracket,
    },
    'download': {
        key: 'download',
        label: 'download',
        icon: faCloudArrowDown,
    },

    'copy': {
        key: 'copy',
        label: 'copy',
        icon: faCopy,
    },
    'duplicate': {
        key: 'duplicate',
        label: 'duplicate',
        icon: faClone,
    },
    'filter': {
        key: 'filter',
        label: 'filter',
        icon: faFilter,
    },
    'history': {
        key: 'history',
        label: 'history',
        icon: faHistory,
    },

};
