import type {TSpreadsheetTypes} from '@aktek/f4kit';
import {AvailableSpaceVertical, LoadingIndicator, Spreadsheet, TabsManager, toast} from '@aktek/f4kit';
import {XSmartFilter} from '@aktek/types';
import React, {forwardRef, RefObject, useImperativeHandle, useRef} from 'react';

import EmptyTableState from '@/components/Table/Components/EmptyTableState';
import {strings} from '@/localization/i18n';

import {CreateNewMorphs} from './api/CreateNewMorphs';
import {DeleteRows} from './api/DeleteRows';
import {SaveModifications} from './api/SaveModifications';
import {Config} from './config/MorphSpreadsheet.Config';
import {ValueConverter} from './converters/ValueConvert';
import ValidateBulkCell from './helpers/ValidateBulkCell.async';
import useMorphSpreadsheet from './hooks/useMorphSpreadsheet';
import {getOnSelectedContextMenu} from './menu/ContextMenu';
import renderCell from './Render/RenderCell';

type TF4TableSpreadsheetProps = {
    mode: 'creation' | 'edit';
    sboId: string;
    onSave?: () => void;
    onDelete?: () => void;
    onCreate?: () => void;
    onCancel?: () => void;
    sorting?: object;
    userFilter?: XSmartFilter;
}

export type F4TableSpreadsheetRef = {
    save: () => Promise<boolean>;
}

export type TSorting = {
    column: string;
    order: 'up' | 'down';
}

export const useF4TableSpreadsheetRef = () => {
    return useRef<F4TableSpreadsheetRef>();
};

const F4TableSpreadsheet = ((props: TF4TableSpreadsheetProps, ref: RefObject<F4TableSpreadsheetRef>) => {
    const isEditing = props.mode === 'edit';
    const state = useMorphSpreadsheet(props);
    const spreadsheetRef = React.useRef<TSpreadsheetTypes['TSpreadsheetRefObject']>(null);

    useImperativeHandle(ref, () => ({
        save: async () => {
            // const newRowsCreated = await spreadsheetRef.current?.createNewRows();
            const changesSaved = await spreadsheetRef?.current?.saveChanges();
            // const rowsDeleted = await spreadsheetRef.current?.deleteRows();

            changesSaved && toast.success(strings('saved_successfully'));
            !changesSaved && toast.error(strings('failed_to_save'));

            return changesSaved;
        },
    }));

    if (state.isPageLoading) {
        return (
            <div className="flex items-center justify-center p-10">
                <LoadingIndicator />
            </div>
        );
    }

    if (isEditing && state.isEmpty) {
        return <EmptyTableState
            message={strings('no_data')}
            addLabel={strings('bulk_add_records')}
            onClick={() => {
                TabsManager.openNewTab('BulkRecordEditor', strings('bulk_record_editor'), {sboId: props.sboId});
            }}
        />;
    }

    return <AvailableSpaceVertical>
        <AvailableSpaceVertical.RemainingSpace>
            <Spreadsheet
                ref={spreadsheetRef}
                idColumn="_id"
                initialCount={props.mode === 'creation' ? 2 : 0}
                sorting={props.sorting}
                deleteRows={(ids) => DeleteRows(props.sboId, ids)}
                onSave={props.onSave}
                onDelete={props.onDelete}
                onCreate={props.onCreate}
                onCancel={props.onCancel}
                onHeaderSaveClick={() => {
                    if (props.mode === 'creation') {
                        spreadsheetRef.current?.createNewRows();

                        return;
                    }

                    if (props.mode === 'edit') {
                        spreadsheetRef.current?.saveChanges();
                    }
                }}
                confirm={async (title, message) => {
                    console.log('....F4TableSpreadsheet.tsx__53__: confirm', {title, message}, '\n_________');

                    return true;
                }}
                aSyncBulkCellValidation={async (cells) => {
                    const result = ValidateBulkCell(cells);

                    return result;
                }}
                columns={state.columns}
                valueConverter={ValueConverter}
                defaultData={state.dataByKey[state.key]}
                cellRenderer={renderCell}
                contextMenu={getOnSelectedContextMenu(props.mode, spreadsheetRef)}
                config={Config}
                createNewRows={(newIds, modifications) => CreateNewMorphs(props.sboId, newIds, modifications)}
                saveModifications={(modifications) => SaveModifications(props.sboId, modifications)}
            />
        </AvailableSpaceVertical.RemainingSpace>
    </AvailableSpaceVertical>
    ;
});

export default forwardRef<F4TableSpreadsheetRef, F4TableSpreadsheetProps>(F4TableSpreadsheet);
