export const DISPLAY_CURRENCY_TYPES = {
    '$': '$',
    '£': '£',
    '€': '€',
    'A$': 'A$',
    'CAD$': '$',
    'COP$': '$',
    '¥': '¥',
    '₣': '₣',
    'L': 'L',
    'R$': 'R$',
};
