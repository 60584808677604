import {useContext, useEffect, useState} from 'react';

import useFilesAndFoldersState from '@/hooks/useFilesAndFoldersState';
import {useTheme} from '@/hooks/useTheme';
import {UserAuth} from '@/types/UserAuth';

import usePermissionsLoader from '../Permissions/usePermissionsLoader';
import UserContextLoader from './components/UserContext.Loader';
import {UserContext} from './UserContext';

export default function UserContextProvider({children}:{children: React.ReactNode}) {
    const [userAuth, setUserAuth] = useState<UserAuth>(() => ls.getUserData());
    const [path, setPath] = useState('');

    const filesAndFoldersState = useFilesAndFoldersState();
    const theme = useTheme();

    const userId = userAuth?.currentUser?._id || '';
    const companyId = userAuth?.currentCompany?._id || '';

    const permissionsState = usePermissionsLoader({userId, companyId});

    useEffect(() => {
        if (!userAuth?.auth_token) return;
        filesAndFoldersState?.refresh();
    }, [userAuth?.auth_token]);

    const contextValue = {
        userAuth,
        setUserAuth,
        filesAndFoldersState,
        permissionsState,
        theme,
        setPath,
        path,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {/* Loading indicator until permissions are loaded */}
            <UserContextLoader isLoading={permissionsState.isLoading} authToken={userAuth.auth_token} />
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }

    return userContext;
}

