export default (isCreating) => {
    return {
        key: 'tags',
        id: 'tags',
        label: 'Tags',
        type: 'tags',
        field: {type: 'tags'},
        originalField: {type: 'tags'},
        getHidden: (row, column, isNewRow) => {
            if (!isCreating && isNewRow) {
                return {isHidden: true};
            }

            return {isHidden: false};
        },
    };
};
