import cn from 'classnames';

export const DatetimeClasses = (layout:boolean) => ({
    container: cn(
        'flex justify-between w-full items-start',
        layout && 'flex-col gap-2 ',
        !layout && 'flex-auto gap-6 ',
    ),
    text: layout ? 'h' : 'w',
    textbox: cn(
        'text-neutral-600 rounded-lg placeholder-neutral-300 !bg-neutral-100 duration-300 w-full',
    ),
    textboxContainer: cn(
        layout && 'w-full',
        !layout && 'w-3/4',
    ),
    inputClassName: '!text-neutral-600 rounded-lg placeholder-neutral-300 !bg-neutral-100 duration-300 !w-full',
    popoverWrapperClassName: '!w-full',

});
