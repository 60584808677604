import {useF4FormContext} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';

import {validateMax, validateMin} from '../../helpers/ValidateMinMax.fn';
import ValidateInputPasting from '../../helpers/Validation/ValidateInputPasting.fn';
import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';
import SBOFieldCommon from './SBO.Field.Common';
// import {handleKeyDown} from './SBO.Field.IntgerDecimal';

export default function SBOFieldImageVideoAlbum(props: TSBOEditorFieldProps) {
    const {field, isExpanded, onExpandChange} = props;
    // const {field, tableFields, sboActions, children} = props;
    // const {toggleFieldTableVisibility} = sboActions;
    const f4FormContext = useF4FormContext();

    return (
        <SBOFieldCommon {...props} isExpanded={isExpanded} onExpandChange={onExpandChange}>
            <div className="flex flex-col w-full bg-neutral-100 rounded-xl items-center gap-1" >

                {/* ********** Minimum ********** */}
                <div className="flex flex-col items-center justify-between gap-2 w-full">
                    <div className="flex items-center gap-2 w-full group/minimum ">
                        <Label text="Minimum" className="!w-60 self-start mt-2" color="neutral-400"/>
                        <F4Textbox
                            name={`fieldsMap.${field.id}.minimum`}
                            isGhost
                            inputClassName="!text-base font-medium"
                            className="text-neutral-600 group-hover/minimum:!bg-neutral-50 rounded-lg"
                            // onKeyDown={(e) => handleKeyDown(e, false, false)}
                            isNumeric
                            validate={(value) => {
                                if (!value) return {isValid: true};

                                // Validate pasted input first
                                const pasteValidation = ValidateInputPasting(value, false);
                                if (!pasteValidation.isValid) return pasteValidation;

                                const updatedField = (f4FormContext.getData() as XSBO)?.fieldsMap?.[field.id];

                                return validateMin(value, updatedField?.maximum, 'integer', 'regular', false);
                            }}/>
                    </div>

                    {/* ********** Maximum ********** */}
                    <div className="flex items-center gap-2 w-full group/maximum ">
                        <Label text="Maximum" className="!w-60 self-start mt-2" color="neutral-400"/>
                        <F4Textbox
                            name={`fieldsMap.${field.id}.maximum`}
                            isGhost
                            inputClassName="!text-base font-medium"
                            className="text-neutral-600 group-hover/maximum:!bg-neutral-50 rounded-lg"
                            // onKeyDown={(e) => handleKeyDown(e, false, false)}
                            isNumeric
                            validate={(value) => {
                                if (!value) return {isValid: true};

                                // Validate pasted input first
                                const pasteValidation = ValidateInputPasting(value, false);
                                if (!pasteValidation.isValid) return pasteValidation;

                                const updatedField = (f4FormContext.getData() as XSBO)?.fieldsMap?.[field.id];

                                return validateMax(updatedField?.minimum, value, 'integer', 'regular', false);
                            }}/>
                    </div>

                    <div className="flex flex-row items-start w-full gap-4">
                        <F4ToggleSwitch
                            wrapper={{className: '!w-60'}}
                            className="w-44"
                            switchClassName="bg-neutral-200"
                            name={`fieldsMap.${field.id}.showName`}
                            description="Display file name"
                            defaultValue={false}
                            size="sm"
                        />

                        <F4ToggleSwitch
                            className="w-44"
                            switchClassName="bg-neutral-200"
                            name={`fieldsMap.${field.id}.saveMetadata`}
                            description="Save metadata"
                            defaultValue={false}
                            size="sm"
                        />
                    </div>
                </div>
            </div>
        </SBOFieldCommon>
    );
}

