import React from 'react';

import {TsPages, TUseRowsProps, TUseRowsState} from '../Types/Table.t';

const useRows = ({getRows, rows: _rows = [], paginationState, sortingState, countState, searchState}: TUseRowsProps): TUseRowsState => {
    const {currentPage} = paginationState;

    const [rowsByPages, setRowsByPage] = React.useState<TsPages>({
        [currentPage]: _rows,
    });

    const setRowsForPage = (pageIndex, rows) => {
        setRowsByPage((rowsByPages) => ({
            ...rowsByPages,
            [pageIndex]: rows,
        }));
    };

    const [isRowLoading, setIsRowLoading] = React.useState(true);

    const fetchRows = async () => {
        setIsRowLoading(true);
        const {currentPage} = paginationState;
        const rows = await getRows(paginationState, sortingState, countState, searchState);

        setRowsForPage(currentPage, rows);
        setIsRowLoading(false);
    };

    React.useEffect(() => {
        fetchRows();
    }, [currentPage, sortingState, paginationState.pageSize, searchState.query]);

    React.useEffect(() => {
        setRowsByPage({});
    }, [paginationState.pageSize]);

    const rows =rowsByPages[currentPage];

    return {
        count: countState.count,
        rows,
        isRowLoading,
        fetchRows,
    };
};

export default useRows;
