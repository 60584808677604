import {getThousandSeparatedValue} from './helpers/Integer.GetThousandSeparatedValue';
import IntegerCellEdit, {TIntegerCellEditProps} from './Integer.Cell.Edit';

const IntegerCellView = (props: TIntegerCellEditProps) => {
    const value = props.showThousandsSeparator ? getThousandSeparatedValue(props.value) : props.value;

    return <IntegerCellEdit {...props} value={value + ''} isDisabled/>;
};

export default IntegerCellView;
