import {Icon, Label} from '@aktek/f4kit';
import {faChevronDown, faChevronRight, faEye, faEyeSlash, faInfoCircle, faInputText} from '@fortawesome/pro-regular-svg-icons';
import {useEffect} from 'react';

import F4NSwitch from '@/components/F4Elements/F4NSwitch';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleButton from '@/components/F4Elements/F4ToggleButton';
import {ToggleIcon} from '@/components/F4Elements/F4ToggleIcon';

import {fieldTypesDictionary} from '../../helpers/GetSectionActionsContextMenuEntries.fn';
import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';

const N_SWITCH_OPTIONS = [
    {icon: faInputText, value: true, label: '', tooltip: 'Show description under the field name', tooltipProps: {showDelay: 600}},
    {icon: faInfoCircle, value: false, label: '', tooltip: 'Show description as an info button', tooltipProps: {showDelay: 600}},
];

export default function SBOFieldCommon(props: TSBOEditorFieldProps) {
    const {field, tableFields, sboActions, children, isExpanded = field.name === '', onExpandChange} = props;
    const {toggleFieldTableVisibility} = sboActions;
    const icon = fieldTypesDictionary[field.type]?.icon;
    const color = fieldTypesDictionary[field.type]?.color;

    // Expose field expansion functionality
    useEffect(() => {
        const fieldElement = document.getElementById(`field-${field.id}`);

        if (fieldElement) {
            fieldElement.expandField = () => onExpandChange?.(true);
        }
    }, [field.id, onExpandChange]);

    const handleExpandToggle = () => {
        onExpandChange?.(!isExpanded);
    };

    return (
        <div
            className={`
                flex flex-col w-full rounded-xl items-center p-2 px-4 gap-3
                transition-colors duration-200 ease-in-out
                hover:bg-neutral-100
                ${isExpanded ? 'bg-neutral-100' : ''}
            `}
            id={`field-${field.id}`}
        >
            {/* ********** Field Name ********** */}
            <div className="flex items-start justify-between w-full">
                <div className="flex gap-2 w-full !items-start group/name">
                    <Icon className="w-3 h-3 mt-2.5 p-1.5 hover:!bg-neutral-300 rounded-lg"
                        color="neutral-600"
                        onClick={handleExpandToggle}
                        icon={isExpanded ? faChevronDown : faChevronRight} />

                    <Icon icon={icon}
                        color={color} className="w-4 h-4 py-3.5"/>

                    <F4Textbox name={`fieldsMap.${field.id}.name`}
                        id={`field-${field.id}`}
                        isGhost
                        required
                        size="sm"
                        placeholder="Name of field"
                        inputClassName="!text-base h-full py-2"
                        className="text-neutral-600 group-hover/name:!bg-neutral-50 rounded-lg focus:ring-0 mt-1"
                        errorClassName="ml-3"/>

                </div>

                {/* ********** Required + Show in Table ********** */}
                { <div className="flex items-center gap-3 float-end mt-1 ml-2">
                    <F4ToggleButton isGhost text={'Required'}
                        name={`fieldsMap.${field.id}.isRequired`} className="text-sm" size="sm"/>

                    <ToggleIcon
                        value={tableFields.indexOf(field.id)!== -1 ? true : false}
                        onChange={() => toggleFieldTableVisibility(field.id)}
                        icons={{on: faEye, off: faEyeSlash}}
                        tooltip={{on: 'Hide in Data Table', off: 'Show in Data Table'}}
                        tooltipProps={{showDelay: 600}}
                        colors={{on: 'neutral-600', off: 'neutral-600'}}
                        className="w-4 h-4"/>

                </div>}
            </div>

            <div id={`field-container-${field.id}`} className={`${!isExpanded ? 'hidden' : 'contents'}`}>
                {/* ********** Description ********** */}
                <div className="flex items-center justify-between gap-2 w-full">
                    <div className="flex items-center gap-2 w-full group/description ">
                        <Label text="Description" className="!w-60" color="neutral-400"/>

                        <F4Textbox
                            name={`fieldsMap.${field.id}.description`}
                            isGhost
                            inputClassName="!text-base font-medium"
                            className="text-neutral-600 group-hover/description:!bg-neutral-50 rounded-lg"/>

                    </div>

                    {/* ********** Show Description ********** */}
                    <div className="flex items-center gap-3">
                        <F4NSwitch name={`fieldsMap.${field.id}.showDescription`}
                            options={N_SWITCH_OPTIONS}
                            defaultValue={false}
                            className="!bg-neutral-50 gap-1"
                            size="xs"
                            buttonProps={
                                {
                                    className: '!w-9 !min-w-9',
                                    isGhost: true,
                                    size: 'md',
                                }
                            }
                        />

                    </div>
                </div>

                {children}

                {/* ********** Show on condition ********** */}
                <div className="flex items-center w-full group/condition">
                    <Label text="Show on condition" className="!w-60" color="neutral-400"/>
                    <F4Textbox isGhost
                        inputClassName="!text-base font-medium !w-full"
                        className="text-neutral-600 group-hover/condition:!bg-neutral-50 rounded-lg"/>
                </div>
            </div>

        </div>
    );
}
