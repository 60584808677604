import _compact from 'lodash/compact';

import {strings} from '@/localization/i18n';

import {TImageColorSelectorValueType} from '../ImageOrColorSelector.Modal';

const GetTabOptions = (availableTypes: TImageColorSelectorValueType[])=> _compact([
    availableTypes.includes('image') && {
        key: 'image',
        label: strings('icon'),
    },
    availableTypes.includes('color') && {
        key: 'color',
        label: strings('color'),
    },
]);

export default GetTabOptions;
