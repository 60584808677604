import {XSBO} from '@aktek/types';
import {useCallback} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

import {TSBOActions} from '../types/SBOEditor.t';
import SBOEditorSection from './SBO.EditorSection';
import SBOEditorSectionFields from './SBO.EditorSectionFields';

type TSBOEditorSectionsProps = {
    sboActions: TSBOActions;
    currentSBO: XSBO;
    expandedFieldIds: Set<string>;
    setExpandedFieldIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export default function SBOEditorSections(props: TSBOEditorSectionsProps) {
    const {currentSBO, sboActions, expandedFieldIds, setExpandedFieldIds} = props;

    const moveSection = useCallback((dragIndex: number, hoverIndex: number) => {
        const newSections = [...currentSBO.sections];
        const [draggedSectionId] = newSections.splice(dragIndex, 1);
        sboActions.moveSection(draggedSectionId, hoverIndex);
    }, [currentSBO, sboActions]);

    return (
        <DndProvider backend={HTML5Backend} options={{
            enableMouseEvents: true,
            enableTouchEvents: true,
            enableKeyboardEvents: true,
            scrollAngleRanges: [
                {start: 30, end: 150}, // top
                {start: 210, end: 330}, // bottom
            ],
        }}>
            <div className="flex flex-col gap-4">
                {currentSBO.sections.map((sectionId, index)=>{
                    const section = currentSBO?.sectionsMap[sectionId];
                    if (!section) return null;

                    return <SBOEditorSection
                        key={sectionId}
                        currentSBO={currentSBO}
                        index={index}
                        section={section}
                        sboActions={sboActions}
                        moveSection={moveSection}
                    >
                        <SBOEditorSectionFields
                            currentSBO={currentSBO}
                            sboActions={sboActions}
                            section={section}
                            expandedFieldIds={expandedFieldIds}
                            setExpandedFieldIds={setExpandedFieldIds}
                        />
                    </SBOEditorSection>;
                })}
            </div>
        </DndProvider>
    );
}
