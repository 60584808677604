import {faEye, faPen} from '@fortawesome/pro-regular-svg-icons';
import {Row} from '@tanstack/react-table';
import {useCallback, useMemo} from 'react';

import {TTableRowAction} from '@/components/Table/Types/TableActionMenuEntry.t';
import {strings} from '@/localization/i18n';
import {TUser} from '@/types/User.t';

const useDataTableActions = (permissions, openRecordTab) => {
    const onRowAction = useCallback((row: Row<TUser>, action: TTableRowAction) => {
        switch (action.key) {
            case 'edit-record':
                openRecordTab(row, 'edit');
                break;

            case 'view':
                openRecordTab(row, 'view', permissions?.can_edit);
                break;
        }
    }, [permissions]);

    const rowActions = useMemo(() => [
        {
            key: 'edit-record',
            label: strings('edit'),
            isVisible: permissions?.can_edit ?? false,
            icon: faPen,
        },
        {
            key: 'view',
            label: strings('view'),
            isVisible: permissions?.can_view ?? false,
            icon: faEye,
        },
    ], [permissions]);

    return {
        onRowAction,
        rowActions,
    };
};

export default useDataTableActions;
