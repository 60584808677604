import {F4FormDiv} from '@aktek/f4form';
import {TModalRef} from '@aktek/f4kit';
import {GroupsV4Service} from '@aktek/helios-frontend-sdk';
import {forwardRef, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4UserSelector from '@/components/F4Elements/F4UserSelector';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {TGroup} from '../types/Groups.t';

const CreateGroupModal = (props: TF4CreatableModalProps, ref: TModalRef) => {
    const {viewState, ...rest} = props;
    const [group, setGroup] = useState<TGroup>();
    const {users: usersPermissions} = useAdminPermissions();

    const getGroupById = async (id) => {
        const res = await GroupsV4Service.getGroupById(id);
        setGroup(res.getData() as TGroup);

        return res;
    };

    return (
        <F4CreatableModal<TGroup>
            title={strings('group')}
            ref={ref}
            formKey="groups"
            viewState={viewState}
            createSuccessMessage={strings('group_created_successfully')}
            editSuccessMessage={strings('group_edited_successfully')}
            getFunction={getGroupById} // This should be changed at the level of the SDK. !!TODO: Live with it a bit.
            storeFunction={GroupsV4Service.storeGroup}
            steps={[{key: 'usersAndInfo', label: strings('users_and_info')}]}
            {...rest}
        >
            <F4FormDiv colSpan={12} className="flex flex-col gap-4">
                <F4Textbox
                    autoFocus
                    required
                    name={`name`}
                    label={strings('group_name')}
                />

                {usersPermissions?.can_view && <F4UserSelector
                    name="users"
                    state={group?.users}
                    getFunction={viewState?.mode == 'edit' && getGroupById}
                    className={'mt-5'}
                    groupId={group?._id}
                />}
            </F4FormDiv>
        </F4CreatableModal>
    );
};

export default forwardRef(CreateGroupModal);
