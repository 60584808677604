import {TSpreadsheetTypes} from '@aktek/f4kit';
import {SpreadsheetService} from '@aktek/morph-frontend-sdk';
import {XSDKResponsePromise} from '@aktek/types';
import isEmpty from 'lodash/isEmpty';

type RowModifications = Record<string, unknown>;

type ModifiedData = Record<string, { action: 'edit'; value: RowModifications }>;

export const SaveModifications = async (
    sboId: string,
    modifications: TSpreadsheetTypes['TRowModificationsMap'],
): Promise<boolean> => {
    const modifiedData = _transformUndefinedToRemove(modifications);

    if (!isEmpty(modifiedData)) {
        const modifiedResponse = await (SpreadsheetService.storeSpreadsheetData(sboId, modifiedData) as XSDKResponsePromise<unknown>);

        return modifiedResponse.isSuccessful();
    }

    return true;
};

const _transformUndefinedToRemove = (modifications: TSpreadsheetTypes['TRowModificationsMap']): ModifiedData =>{
    const result: ModifiedData = {};

    modifications.forEach((rowModifications, rowId) => {
        const rowIdNumber = Number(rowId);
        if (isNaN(rowIdNumber)) return;
        const value = _getConvertedRowModifications(rowModifications);

        value._id = Number(value._id);

        if (rowIdNumber !== value._id) return; // This is just more validation to prevent any issues results from id string or number

        result[rowIdNumber] = {
            action: 'edit',
            value,
        };
    });

    return result;
};

const _getConvertedRowModifications = (rowModifications: TSpreadsheetTypes['TRowModifications']): RowModifications => {
    const result: RowModifications = {};

    Object.entries(rowModifications).forEach(([fieldId, value]) => {
        const isDeleted = rowModifications[fieldId] === undefined;
        result[fieldId] = isDeleted ? '$$REMOVE' : value;
    });

    delete result.rowId;

    return result;
};
