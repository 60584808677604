import {Label, ShortcutIndicator} from '@aktek/f4kit';
import {useState} from 'react';

import useTabShortcuts from '@/app/Home/hooks/useTabShortcuts';

export default function PlaygroundIOSelect() {
    const [showingShortcut, setShowingShortcut] = useState(null);

    const showShortcut = (shortcut: string) => {
        setShowingShortcut(shortcut);
        setTimeout(() => {
            setShowingShortcut(null);
        }, 500);
    };

    useTabShortcuts({
        'Alt+x': {
            action: ()=>{
                showShortcut('Alt+x');
            },
            description: 'Love the letter X',
        },
        'Alt+z': {
            action: ()=>{
                showShortcut('Alt+z');
            },
            description: 'Love the letter Z',
        },
        'Alt+q': {
            action: ()=>{
                showShortcut('Alt+q');
            },
            description: 'Love the letter Q',
        },

    });

    return <div>
        <div className="flex flex-col gap-1">
            I only respond to <ShortcutIndicator shortcut="Alt+x" /> <ShortcutIndicator shortcut="Alt+z" />
                and one more hidden one 😉 </div> <br/>

        <Label text="Would you like to know what it is?" /><br/>
        <Label text="Call Alt+h for help" fontWeight="bold" />
        <br/><br/>
        <Label text="You will see the shortcut pressed at the bottom left of your screen, you can hover over it to get reminded on what it does"/><br/>

        <Label text="Notice which tab is active by looking at the title of the tab" size="sm" />

        <br/><br/>

        <div className="p-3 items-center justify-between text-center self-center flex-col flex border-2 w-48 h-48 rounded-lg border-solid border-neutral-800 bg-neutral-50">
            <Label text="Shortcut Detected" />
            {showingShortcut && <ShortcutIndicator shortcut={showingShortcut} />}
            <div className="h-5"/>
        </div>

    </div>;
}
