export default function ValidateInputPasting( value: number, isFloat: boolean = false): { isValid: boolean; messages: string[] } {
    // Convert to string for regex testing
    const strValue = value?.toString() || '';

    // Initialize result
    const result = {isValid: true, messages: []};

    // Check for empty value
    if (!strValue && strValue !== '0') {
        return result; // Empty is considered valid
    }

    // Check for invalid characters
    if (isFloat) {
        // For float, allow digits, one decimal point/comma, and +/- at start
        if (!/^[+-]?\d*[.,]?\d*$/.test(strValue)) {
            result.isValid = false;
            result.messages.push('Invalid number format');
        }
    } else {
        // For integer, only allow digits and +/- at start
        if (!/^[+-]?\d*$/.test(strValue)) {
            result.isValid = false;
            result.messages.push('Invalid number format');
        }
    }

    return result;
}
