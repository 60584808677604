import {toast} from '@aktek/f4kit';
import {useEffect, useMemo, useRef, useState} from 'react';

import Env from '@/config/env';
import {isValidFileType} from '@/helpers/File';
import {FileSizeMB} from '@/helpers/FileSizeMB.fn';
import {uploadFileToURL} from '@/helpers/FileUpload';
import {strings} from '@/localization/i18n';

type TImage ={
    src: string
    alt: string
}

const useImageOrColorSelectorImageUploader = (title, value, onChange, allowedTypes, maxSizeMB) => {
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const _imageObject = useMemo(() => {
        if (!value) return null;

        return {
            src: Env.getFileURL(value),
            alt: title || '',
        };
    }, [value]);

    const [imageObject, setImage] = useState<TImage | null>(_imageObject);

    useEffect(() => {
        setImage(_imageObject);
    }, [_imageObject]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleClearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleOnChange = async (event) => {
        setIsLoading(true);
        const file = event.target.files[0];

        if (!file) {
            setIsLoading(false);
            handleClearFileInput();

            return;
        }

        const isValidType = await isValidFileType(file, allowedTypes);

        if (!allowedTypes.includes(file.type) || !isValidType) {
            toast.error({
                subject: strings('invalid_image_type'),
                details: strings('invalid_image_type_desc'),
            });
            setIsLoading(false);
            handleClearFileInput();

            return;
        }

        if (FileSizeMB(file) > maxSizeMB) {
            toast.error({
                subject: strings('invalid_file_size'),
                details: strings('the_image_size_should_not_exceed_mb', `${maxSizeMB}MB`),
            });
            setIsLoading(false);
            handleClearFileInput();

            return;
        }

        const {url, fileName} = Env.getNewEmptyFileURL();

        try {
            await uploadFileToURL(file, url);
            setImage({
                src: fileName,
                alt: title || '',
            });

            onChange?.(fileName);
            setIsLoading(false);
            toast.success(strings('image_uploaded_successfully'));

            handleClearFileInput();
        } catch (err) {
            handleClearFileInput();
            setIsLoading(false);
            toast.error(err?.message);
        }
    };

    return {
        imageObject,
        handleImageClick,
        handleClearFileInput,
        handleOnChange,
        isLoading,
        setImage,
        fileInputRef,
    };
};

export default useImageOrColorSelectorImageUploader;
