
import {F4FormElementDecorator} from '@aktek/f4form';
import {Button, Icon, Image, toast, TSize, usePropState} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {faUser} from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import {useEffect, useRef, useState} from 'react';

import AKTEKLogo from '@/assets/images/aktek_logo.png';
import Env from '@/config/env';
import {isValidFileType} from '@/helpers/File';
import {FileSizeMB} from '@/helpers/FileSizeMB.fn';
import {uploadFileToURL} from '@/helpers/FileUpload';
import {strings} from '@/localization/i18n';

type TImageSelectorProps = {
    isDisabled?: boolean;
    size?: TSize;
    title?:string;
    value?: string;
    showUserIcon?: boolean;
    maxSizeMB: number;
    defaultImage?: TImage;
    allowedTypes: Array<string>;
    onChange?: (e: string) => void;
    setIsLoading?: (e: boolean) => void;
    isViewMode?: boolean;
    className?: string;
    uploadLabel?:string;
    replaceLabel?: string
}

type TImage ={
    src: string
    alt: string
}

const DEFAULT_ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export function AvatarImageSelector({
    onChange,
    value='',
    isDisabled,
    size='3xl',
    title,
    className,
    maxSizeMB=1,
    allowedTypes = DEFAULT_ALLOWED_IMAGE_TYPES,
    defaultImage={src: AKTEKLogo, alt: 'AKTEK'},
    showUserIcon,
    isViewMode = false,
    uploadLabel = strings('upload_logo'),
    replaceLabel = strings('replace_logo'),
}: TImageSelectorProps) {
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    const getImage = (id) => {
        return {
            src: Env.getFileURL(id),
            alt: title || '',
        };
    };

    const [_value, setValue] = usePropState<string>(value, '', onChange);
    const [image, setImage] = useState<TImage | null>(_value ? getImage(_value) : null);

    useEffect(()=>{
        value && setValue(value);
    }, [value]);

    useEffect(()=>{
        if (!_value && !image) return setImage(null);

        setImage(_value ? getImage(_value) : null);
    }, [_value]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleClearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleClearImage = () => {
        handleClearFileInput();

        setValue('');
        setImage(null);
    };

    const handleOnChange = async (event) => {
        setIsLoading(true);
        const file = event.target.files[0];

        if (!file) {
            setIsLoading(false);
            handleClearFileInput();

            return;
        }

        const isValidType = await isValidFileType(file, allowedTypes);

        if (!allowedTypes.includes(file.type) || !isValidType) {
            toast.error({
                subject: strings('invalid_image_type'),
                details: strings('invalid_image_type_desc'),
            });
            setIsLoading(false);
            handleClearFileInput();

            return;
        }

        if (FileSizeMB(file) > maxSizeMB) {
            toast.error({
                subject: strings('invalid_file_size'),
                details: strings('the_image_size_should_not_exceed_mb', `${maxSizeMB}MB`),
            });
            setIsLoading(false);
            handleClearFileInput();

            return;
        }

        const {url, fileName} = Env.getNewEmptyFileURL();

        try {
            await uploadFileToURL(file, url);
            setImage({src: fileName});

            onChange(fileName);
            setIsLoading(false);
            toast.success(strings('image_uploaded_successfully'));

            handleClearFileInput();
        } catch (err) {
            handleClearFileInput();
            setIsLoading(false);
            toast.error(err?.message);
        }
    };

    return <>
        <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleOnChange}
        />

        <div className="w-full flex flex-wrap justify-start items-center gap-2">
            <div className={
                cn(
                    'border-2 border-neutral-200 flex justify-center items-center w-[6.5rem] h-[6.5rem] rounded-xl relative overflow-hidden',
                    className,
                )}>
                {(!image && showUserIcon) && <Icon icon={faUser} size="5xl" />}
                {!image && !showUserIcon && <Image
                    alt={defaultImage?.alt}
                    src={defaultImage?.src}
                    className="rounded-lg !w-full !h-full"
                    // className="rounded-lg !w-full !h-full !object-cover"
                />}
                {image && <Image
                    alt={image?.alt}
                    src={image?.src}
                    className="rounded-lg !w-full !h-full !object-cover"
                />}
            </div>
            <div className="flex justify-center items-center gap-2">
                <Button
                    label={image ? replaceLabel : uploadLabel}
                    onClick={handleImageClick}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    variant={isLoading ? 'primary' :'white'}
                    className={`${isViewMode? 'hidden' : ''}`}
                />
                {image
                 && <Button isGhost
                     icon={faTrash}
                     onClick={handleClearImage}
                     isDisabled={isDisabled}
                     className={`!text-error-700 hover:!bg-error-50 ${isViewMode ? 'hidden' :''}`}/>}
            </div>
        </div>
    </>;
}

export default F4FormElementDecorator<TImageSelectorProps>(AvatarImageSelector);
