import {TabNav, TTab} from '@aktek/f4kit';

import {getImageOrColorSelectorClasses} from '../styles/ImageOrColorSelector.styles';

type TImageOrColorSelectorHeaderProps = {
    onChange?: (value) => void,
    tabOptions: TTab[],
    option: 'image' | 'color',
}

const ImageOrColorSelectorHeader = (props: TImageOrColorSelectorHeaderProps) => {
    const {onChange, tabOptions, option} = props;

    const classes = getImageOrColorSelectorClasses();

    return (
        <div className={classes.headerContainer}>
            <TabNav
                value={option}
                onChange={onChange}
                tabs={tabOptions}
                orientation="horizontal"
                getButtonProps={(tab) => {
                    return {
                        className: classes.buttonClassName(tab.key === option),
                    };
                }}
            />
        </div>
    );
};

export default ImageOrColorSelectorHeader;
