import {Button, Label} from '@aktek/f4kit';
import {faPlus} from '@fortawesome/pro-light-svg-icons';

import {strings} from '@/localization/i18n';

type TEmptyTableStateProps = {
    onClick?: ()=>void;
    addLabel?: string;
    search?: string;
    name?: string;
    message?: string;
    customName?:string
    customMessage?:string;
}

export default function EmptyTableState({onClick, addLabel = strings('add_new'), message, search, name, customName, customMessage} : TEmptyTableStateProps) {
    const label = name == 'company'
        ? strings('no_records_found', strings('companies').toLowerCase())
        : strings('no_records_found', name?.toLowerCase() + 's');

    const description = customMessage ?? strings('this_table_has_no_records_yet_create_a_new_record_to_view_it_here', name);

    return (
        <div className="flex flex-col w-[32rem] h-full mx-auto text-center justify-center items-center gap-4 py-10">

            <div className="flex flex-col gap-2">
                <Label
                    color="neutral-900"
                    className="text-base"
                    fontWeight="semibold"
                    text={message ?? label}
                />
                <Label
                    size="sm"
                    color="neutral-600"
                    fontWeight="normal"
                    text={search
                        ? `${strings('your_search_searchTerm_did_not_match_any_record_please_try_again', search, (customName || name))}`
                        :description }
                />
            </div>
            {onClick && <Button
                label={addLabel}
                icon={faPlus}
                className="font-semibold text-base"
                onClick={onClick}
            />
            }
        </div>
    );
}
