import {Card, Icon, Image, Label, TContextMenuEntry} from '@aktek/f4kit';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import {Tabs} from '@/app/Home/Tabs';
import AskForMenu from '@/asks/AskForMenu';
import Env from '@/config/env';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';

import {GetContextMenu} from '../helpers/Folder.GetContextMenu.fn';
import {TExplorerUIState} from '../hooks/useExplorerUI';
import {GetFoldersRightPanelHeaderClasses} from '../styles/FoldersRightPanel.Content.styles';

export const FoldersRightPanelContent = ({explorerUIState}:{explorerUIState: TExplorerUIState}) => {
    const {folders: foldersPermissions, reportStructure} = useAdminPermissions();
    const {currentCompany} = useUserAuth();

    const {
        refresh,
        deleteFolder,
        deleteShortcut,
        openDataTable,
        moveDataTableLocation,
        onReportDoubleClick,
    } = useGlobalFilesAndFolders();

    const {selectedFolder, setSelectedFolder} = explorerUIState;

    const onContextMenu = (event, item) =>
        AskForMenu(
            event,
            GetContextMenu({
                item,
                foldersPermissions,
                reportStructure,
                currentCompany,
                onSave: refresh,
                deleteFolder,
                openDataTable,
                moveDataTableLocation,
                deleteShortcut,
            }) as TContextMenuEntry[], {placement: 'bottom-start'});

    const classes = GetFoldersRightPanelHeaderClasses();

    return <div className={classes.container}>
        {selectedFolder?.children?.map((item, index) => {
            const icon = item.type === 'shortcut' && item.component
                ? Tabs[item.component]?.icon
                : item.icon;

            const color = item.customColor || 'neutral-400';

            return (
                <Card
                    key={item.id + index}
                    className={classes.card}
                    onContextMenu={(event) => onContextMenu(event, item)}
                    onDoubleClick={() => onReportDoubleClick(item, setSelectedFolder)}
                    borderColor="neutral-200"
                    backgroundColor="white"
                >

                    {item?.customIcon && (
                        <div className={classes.imageContainer}>
                            <Image
                                src={Env.getFileURL(item.customIcon)}
                                alt="custom image"
                                className={classes.image}
                            />
                        </div>
                    )}
                    {!item?.customIcon && <Icon icon={icon as IconDefinition} className="mr-2.5" color={color} />}
                    <Label
                        text={item.label}
                        size="xs"
                        className={classes.label}
                        color="neutral-900"
                    />

                </Card>
            );
        })}
    </div>;
};
