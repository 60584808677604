
import {Drawer} from '@aktek/f4kit';
import {useState} from 'react';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import useTabShortcuts from '../Home/hooks/useTabShortcuts';
import ExplorerSideBar from './components/ExplorerSideBar';
import FoldersRightPanel from './components/FoldersRightPanel';
import RecentlyUsed from './components/RecentlyUsed';
import useExplorerUI from './hooks/useExplorerUI';
import {getClasses} from './styles/Explorer.styles';

export default function Explorer({}): React.ReactElement {
    const classes = getClasses();
    const {state, linkToFolder} = useGlobalFilesAndFolders();
    const explorerUIState = useExplorerUI(state, linkToFolder);
    const [isDrawerOpen, setIsDrawerOpen] = useState(ls.drawer?.explorer ?? true);

    useTabShortcuts({
        '[': {
            action: ()=>{
                setIsDrawerOpen((prev) =>{
                    openCloseDrawer('explorer', !prev);

                    return !prev;
                });
            },
            description: 'toggle the drawer in explorer',
        },
    });

    return <div className={classes.full}>
        <Drawer
            isOpen={isDrawerOpen}
            onOpenChange={() => {
                setIsDrawerOpen((prev) =>{
                    openCloseDrawer('explorer', !prev);

                    return !prev;
                });
            }}
            sideWidth={96}
            tooltipDelay={1000}
            buttonSize="sm"
        >
            <ExplorerSideBar explorerUIState={explorerUIState} />
            <>
                {explorerUIState.rightPanelView == 'recent' && <RecentlyUsed explorerUIState={explorerUIState} />}
                {explorerUIState.rightPanelView == 'folders' && <FoldersRightPanel explorerUIState={explorerUIState} />}
            </>
        </Drawer>
    </div>;
}
