import {HttpStatusCode} from 'axios';
import React from 'react';

// TODO: remove this from the backend and use axios http status code
const HTTP = {
    INVALID_TOKEN: 498,
};
import {toast} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';
import InitSDK from '@/services/InitSDK';

// import {TError, TResponse} from './types/SDKRequest';

type TEvents = {
    logout: () => void
}

const useInitializeSDKs = ({logout}: TEvents) => {
    const onResponse = React.useCallback((response:TResponse, options) => {
        // toast.success(response.data.Message);
    }, []);

    const onError = React.useCallback((error : TError = {}, options) => {
        const {code, message, status, data} = error;

        switch (status) {
            case HTTP.INVALID_TOKEN:
                // TODO: check the code
                toast.error(strings('invalid_token'));
                logout();
                break;
            case HttpStatusCode.NotFound:
                toast.error(error.data.Message);

                break;
            case HttpStatusCode.BadRequest:
                toast.error(error.data.Message);

                break;
            case HttpStatusCode.PaymentRequired:

                toast.error(error.data.Message);
                break;
            case HttpStatusCode.Unauthorized:
                // toast.error(error.data.Message);
                toast.error(strings('login_failed'));

                return {code, message, data, status};

            case HttpStatusCode.Forbidden:
                toast.error(error.data.Message);

                break;
            case HttpStatusCode.Locked:
                toast.error(error.data.Message);
                break;

            case HttpStatusCode.UnavailableForLegalReasons:
                toast.error(error.data.Message);
                break;

            case HttpStatusCode.InternalServerError:
                toast.error(error.data.Message);
                break;

            default:
                toast.error(error.data.Message);
                break;
        }
    }, []);

    InitSDK({onResponse, onError});

    /*
    React.useEffect(() => {
        InitSDK({onResponse, onError});
    }, []);*/
};

export default useInitializeSDKs;
