import {TSpreadsheetTypes} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {faCopy, faCut, faEraser, faPlus} from '@fortawesome/pro-regular-svg-icons';

import AskForMenu from '@/asks/AskForMenu';
import {strings} from '@/localization/i18n';

export const getOnSelectedContextMenu = (
    mode: 'edit' | 'creation',
    spreadsheetRef: TSpreadsheetTypes['TSpreadsheetRef'],
): TSpreadsheetTypes['onContextMenu'] => {
    return (event, cell, cellInfo, tableSelectionManager) => {
        event.preventDefault();
        const menuOptions = [
            {
                key: 'delete',
                icon: faTrash,
                label: strings('delete_selected_rows'),
                hide: false,
                color: 'danger-700',
                onClick: () => {
                    spreadsheetRef?.current?.deleteSelectedRows();
                },
            },
            {
                key: 'copy',
                icon: faCopy,
                label: strings('copy_selection'),
                color: 'danger-700',
                onClick: () => {
                    spreadsheetRef.current?.copy();
                },
            },
            {
                key: 'cut',
                icon: faCut,
                label: strings('cut_selection'),
                color: 'danger-700',
                onClick: () => {
                    spreadsheetRef.current?.cut();
                },
            },
            {
                key: 'clear',
                icon: faEraser,
                label: strings('clear_selection'),
                color: 'danger-700',
                onClick: () => {
                    spreadsheetRef.current?.clearCells();
                },
            },
            {
                key: 'insert_above',
                icon: faPlus,
                label: strings('insert_row_above', ((cellInfo.rowIndex || 0) ) + ''),
                color: 'danger-700',
                onClick: () => {
                    spreadsheetRef.current?.appendEmptyRows(cellInfo.rowIndex, cellInfo.rowId, true);
                },
                isVisible: mode === 'creation',
            },
            {
                key: 'insert_below',
                icon: faPlus,
                label: strings('insert_row_below', ((cellInfo.rowIndex || 0) ) + ''),
                color: 'danger-700',
                onClick: () => {
                    spreadsheetRef.current?.appendEmptyRows(cellInfo.rowIndex, cellInfo.rowId, false);
                },
                isVisible: mode === 'creation',
            },
        ];
        AskForMenu(event, menuOptions);
    };
};
