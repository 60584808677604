import {Icon, Label} from '@aktek/f4kit';
import {faWarning} from '@fortawesome/pro-regular-svg-icons';

const ImageOrColorSelectorWarning = ({label, show}: {label: string, show?: boolean}) => {
    if (!show) return null;

    return (
        <div className="flex flex-row items-start gap-2 bg-warning-50 rounded-lg px-3 py-2">
            <Icon icon={faWarning} color="warning-500" className="w-4 h-4" />
            <Label text={label} size="sm" color="warning-600" />
        </div>
    );
};

export default ImageOrColorSelectorWarning;
