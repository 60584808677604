import {useF4FormContext} from '@aktek/f4form';
import {Icon} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {faGripDotsVertical} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';

import AskForMenu from '@/asks/AskForMenu';

import GetFieldContextMenuEntries from '../helpers/GetFieldContextMenuEntries.fn';
import {TSBOEditorFieldProps} from '../types/SBOEditor.t';
import SBORenderField from './Fields/SBO.RenderField';

const FIELD_DRAG_TYPE = 'field';

type DragItem = {
    fieldId: string;
    sectionId: string;
    index: number;
    type: string;
};

export default function SBOEditorField(props: TSBOEditorFieldProps) {
    const {field, sectionId, sboActions, index, isExpanded, onExpandChange} = props;
    const f4FormContext = useF4FormContext<XSBO>();
    const currentSBO = f4FormContext.getData();
    const dropRef = useRef<HTMLDivElement>(null);
    const dragRef = useRef<HTMLDivElement>(null);

    const [{isDragging}, drag, preview] = useDrag({
        type: FIELD_DRAG_TYPE,
        item: () => ({
            fieldId: field.id,
            sectionId,
            index,
            type: FIELD_DRAG_TYPE,
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging() || monitor.getItem()?.fieldId === field.id,
        }),
    });

    const [{handlerId}, drop] = useDrop({
        accept: FIELD_DRAG_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!dropRef.current) return;

            const dragIndex = item.index;
            const hoverIndex = index;
            const fromSectionId = item.sectionId;
            const toSectionId = sectionId;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex && fromSectionId === toSectionId) return;

            // Get rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Get mouse position
            const clientOffset = monitor.getClientOffset();
            if (!clientOffset) return;

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            // Time to actually perform the action
            sboActions.moveField(item.fieldId, fromSectionId, toSectionId, hoverIndex);

            // Note: we're mutating the monitor item here!
            item.index = hoverIndex;
            item.sectionId = toSectionId;
        },
    });

    // Remove default drag preview
    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, [preview]);

    // Apply drop ref to the main container
    drop(dropRef);
    // Apply drag ref only to the handle
    drag(dragRef);

    return (
        <div
            ref={dropRef}
            className={`
                pb-4
                relative group/field
                ${isDragging ? 'opacity-50 bg-neutral-100 -ml-0' : 'opacity-100 -ml-3.5'}
                transition-all duration-200 ease-in-out
            `}
            style={{
                zIndex: isDragging ? 1000 : 1,
            }}
            data-handler-id={handlerId}
            onContextMenu={(event)=>{
                AskForMenu(event, GetFieldContextMenuEntries(sectionId, field, sboActions, currentSBO));
            }}
        >
            <div className="flex w-full">
                <div
                    ref={dragRef}
                    className="cursor-move"
                >
                    <Icon
                        icon={faGripDotsVertical}
                        color="neutral-600"
                        className="invisible group-hover/field:visible w-4 h-4 pr-2 mt-6"
                        onClick={(event)=>{
                            AskForMenu(event, GetFieldContextMenuEntries(sectionId, field, sboActions, currentSBO));
                        }}
                    />
                </div>
                <SBORenderField
                    {...props}
                    isExpanded={isExpanded}
                    onExpandChange={onExpandChange}
                />
            </div>
            {/* Drop indicator - now always visible during dragging */}
            <div className={`
                absolute inset-0 pointer-events-none
                 border-b-4 border-primary-200
                opacity-0 transition-opacity duration-200
                ${isDragging ? 'opacity-100' : ''}
            `} />
        </div>
    );
}
