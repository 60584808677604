import {TSpreadsheetTypes} from '@aktek/f4kit';
import React from 'react';

import getSpreadsheetColumns from '../BuildColumns/BuildColumns';
export type TSBOState = {
    columns: TSpreadsheetTypes['TColumn'][];
    isSBOLoading: boolean;
};

export type TSBOReturn = TSBOState & {
    getSBO: (sboId: string) => Promise<unknown[] | undefined>;
};

const useFetchSBO = (sboId: string, mode: string): TSBOReturn => {
    const [sboState, setSBOState] = React.useState<TSBOState>({
        columns: [],
        isSBOLoading: false,
    });

    const getSBO = async (sboId: string) => {
        if (!sboId) return;

        setSBOState((state) => ({...state, isSBOLoading: true}));
        const res = await getSpreadsheetColumns(sboId, mode);
        const {columns} = res;
        setSBOState((state) => ({...state, columns, isSBOLoading: false}));

        return columns;
    };

    React.useEffect(() => {
        getSBO(sboId);
    }, [sboId]);

    return {
        ...sboState,
        getSBO,
    };
};

export default useFetchSBO;
