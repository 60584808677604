
import {TextBox} from '@aktek/f4kit';
import {XFieldNumber} from '@aktek/types';

import {DISPLAY_CURRENCY_TYPES} from '@/constants/FieldsDisplay.c';

import useIntegerValidation from './hooks/useIntegerValidation';
import {IntegerClasses} from './styles/Integer.styles';

export type TIntegerCellEditProps = {
    value: number | null | undefined | string;
    numberType?: XFieldNumber['numberType'];
    currencyType?: XFieldNumber['currencyType'];
    onChange?: (value: number | null | undefined) => void;
    allowDecimal?: boolean;
    maximum?: number;
    minimum?: number;
    isRequired?: boolean;
    isDisabled?: boolean;
    showThousandsSeparator?: boolean;
};

const IntegerCellEdit = ({
    value,
    numberType,
    currencyType,
    onChange,
    allowDecimal,
    maximum,
    minimum,
    isDisabled,
}: TIntegerCellEditProps) => {
    const classes = IntegerClasses(false);
    const isPercentageType = numberType == 'percentage';
    const rightPlaceholder = isPercentageType ? '%' : '';
    const _value = (value || '') + '';

    return (
        <div className="flex items-center gap-2">
            <TextBox isGhost autoFocus isNumeric
                isDisabled={isDisabled}
                type={'text'}
                allowDecimal={allowDecimal}
                inputClassName="!bg-neutral-100"
                value={_value}
                onChange={(value) => {
                    onChange(Number(value));
                }}
                leftPlaceholder={DISPLAY_CURRENCY_TYPES[currencyType] ?? ''}
                rightPlaceholder={rightPlaceholder}
                className={classes.textbox}
                max={maximum}
                min={minimum}
            />
        </div>
    );
};

export default IntegerCellEdit;
