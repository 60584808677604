import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';
import {XCompanyTypes, XUserTypes} from '@aktek/types';

import {TColumn} from '@/components/Table/Types/Table.t';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {strings} from '@/localization/i18n';

export const USER_HISTORY_COLUMNS : TColumn[] = [
    {
        key: 'unixDate',
        header: strings('date_and_time'),
        type: 'datetime',
        isSortable: true,
    },
    {
        key: 'action',
        header: strings('action'),
        type: 'string',
        isSortable: true,
        renderCell({row}) {
            return strings(row?.original?.action);
        },
    },
    {
        key: 'initiator',
        header: strings('initiator'),
        type: 'string',
        isSortable: true,
    },
];

export const GetUserTypes: () => Record<TDropdownValue, TDropdownOption> = () => {
    const {currentCompany, currentUser} = useUserAuth();
    const isCompany = currentCompany.companyType == XCompanyTypes.Company;
    const isOPS = currentCompany.companyType == XCompanyTypes.OPS;

    const isRegularUser = currentUser.userType == XUserTypes.Regular;
    const isAccountManager = currentUser.userType == XUserTypes.AccountManager;
    const isSuperAdmin = currentUser.userType == XUserTypes.SuperAdmin;

    if (isCompany || isRegularUser || isAccountManager) {
        return {
            'REGULAR': strings('regular'),
        };
    }

    if (isOPS && isSuperAdmin) {
        return {
            'REGULAR': strings('regular'),
            'ACCOUNTMANAGER': strings('accountmanager'),
            'AGENCYMANAGER': strings('agencymanager'),
            'SUPERADMIN': strings('superadmin'),
        };
    }

    return {
        'REGULAR': strings('regular'),
        'ACCOUNTMANAGER': strings('accountmanager'),
        'AGENCYMANAGER': strings('agencymanager'),
    };
};

