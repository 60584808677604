import {FilesSelectorList} from '@aktek/f4kit';

import {TMediaFile} from '@/types/Media';

type TFilesSelectorModalProps = {
    files: TMediaFile[];
    onDeleteFile: (fileId: string) => void;
    onDeleteAll: () => void;
    onUploadMore: () => void;
    onSearch: (term: string) => void;
}

const FilesSelectorListPopup = (props: TFilesSelectorModalProps) => {
    const {files, onDeleteFile, onDeleteAll, onUploadMore, onSearch} = props;

    return (
        <div className="bg-white rounded-lg shadow-lg border border-neutral-200 w-[500px]">
            <div className="p-5">
                <FilesSelectorList
                    files={files.map((file) => ({
                        thumbnailUrl: file.url,
                        name: file.name,
                        id: file.url,
                    }))}
                    title="Project Images"
                    onDeleteAll={onDeleteAll}
                    onDeleteFile={onDeleteFile}
                    onUploadMore={onUploadMore}
                    onSearch={onSearch}
                />
            </div>
        </div>
    );
};

export default FilesSelectorListPopup;
