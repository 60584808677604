import {ContentDivider, Icon, Label} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {faChevronDown, faChevronRight, faGripDotsVertical, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {memo, ReactNode, useEffect, useRef, useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';

import AskForMenu from '@/asks/AskForMenu';
import F4Textbox from '@/components/F4Elements/F4TextBox';

import GetSectionActionsContextMenuEntries from '../helpers/GetSectionActionsContextMenuEntries.fn';
import GetSectionContextMenuEntries from '../helpers/GetSectionContextMenuEntries.fn';
import {TSBOActions, TSection} from '../types/SBOEditor.t';

const SECTION_DRAG_TYPE = 'section';
const FIELD_DRAG_TYPE = 'field';

type TSBOEditorSectionProps ={
    children?: ReactNode
    section: TSection
    index: number;
    sboActions: TSBOActions;
    currentSBO: XSBO;
    moveSection: (dragIndex: number, hoverIndex: number) => void;
}

const SBOEditorSection = memo(function SBOEditorSection(props:TSBOEditorSectionProps) {
    const {section, sboActions, index, children, currentSBO, moveSection} = props;
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);
    // Track the pre-drag expansion state to restore it after dropping
    const [preDragExpansionState, setPreDragExpansionState] = useState(true);
    const dropRef = useRef<HTMLDivElement>(null);
    const dragRef = useRef<HTMLDivElement>(null);

    const [{isDragging}, drag, preview] = useDrag({
        type: SECTION_DRAG_TYPE,
        item: () => {
            // Save the current expansion state before dragging starts
            setPreDragExpansionState(isSectionExpanded);

            return {
                index,
                sectionId: section.id,
                type: SECTION_DRAG_TYPE,
                isExpanded: isSectionExpanded,
            };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            // Restore the original expansion state when drag ends
            if (monitor.didDrop()) {
                // Only restore if the drop was successful
                setTimeout(() => {
                    setIsSectionExpanded(preDragExpansionState);
                }, 50); // Small delay to ensure the UI updates properly
            } else {
                // If drag was cancelled, immediately restore
                setIsSectionExpanded(preDragExpansionState);
            }
        },
    });

    // Auto-collapse the section when it's being dragged
    useEffect(() => {
        if (isDragging) {
            setIsSectionExpanded(false);
        }
    }, [isDragging]);

    const [{isOver, canDrop}, drop] = useDrop({
        accept: [SECTION_DRAG_TYPE, FIELD_DRAG_TYPE],
        hover: (item: {index: number, sectionId: string, type: string, fieldId?: string, isExpanded?: boolean}, monitor) => {
            // Handle section dragging
            if (item.type === SECTION_DRAG_TYPE) {
                if (!dropRef.current) return;
                if (item.sectionId === section.id) return;

                const dragIndex = item.index;
                const hoverIndex = index;

                if (dragIndex === hoverIndex) return;

                // Get rectangle on screen
                const hoverBoundingRect = dropRef.current.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();

                if (!clientOffset) return;

                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                // Only perform the move when the mouse has crossed half of the item's height
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

                moveSection(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }

            // Handle field dragging - auto-expand section when a field is dragged over
            if (item.type === FIELD_DRAG_TYPE && !isSectionExpanded) {
                setIsSectionExpanded(true);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    // Remove default drag preview for smoother custom preview
    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, [preview]);

    // Apply refs
    drag(dragRef);
    drop(dropRef);

    // Expose section expansion functionality
    useEffect(() => {
        const sectionElement = document.getElementById(`section-${section.id}`);

        if (sectionElement) {
            sectionElement.expandSection = () => setIsSectionExpanded(true);
        }
    }, [section.id]);

    return (
        <div
            ref={dropRef}
            id={`section-${section.id}`}
            className={`
                relative transition-all duration-200 ease-in-out mb-4
                ${isDragging ? 'opacity-50' : 'opacity-100'}
                ${isOver && canDrop ? 'transform' : ''}
            `}
            style={{
                zIndex: isDragging ? 1000 : 1,
            }}
        >
            <div
                className={`
                    relative rounded-lg 
                    ${isOver && canDrop ? 'before:absolute before:inset-0 before:border-b-4 before:border-primary-200 before:-m-[2px]' : ''}
                    ${isDragging ? 'bg-neutral-100' : 'bg-neutral-50'}
                `}
            >
                {/* ********** Section Header ********** */}
                <div className="flex items-center -ml-3 group/section" onContextMenu={(event)=>{
                    AskForMenu(event, GetSectionContextMenuEntries(currentSBO, section, sboActions));
                }}>
                    <div ref={dragRef} className="cursor-move">
                        <Icon
                            icon={faGripDotsVertical}
                            color="neutral-600"
                            className={`invisible group-hover/section:!visible w-4 h-4 pr-2 ${!section.name && '!mb-3'}`}
                            onClick={(event)=>{
                                AskForMenu(event, GetSectionContextMenuEntries(currentSBO, section, sboActions));
                            }}
                        />
                    </div>
                    <ContentDivider
                        backgroundColor={isDragging ? 'neutral-100' : 'neutral-50'}
                        className={`${!section.name && 'mb-6'}`}
                        middleChild={
                            <F4Textbox isGhost required
                                name={`sectionsMap.${section.id}.name`}
                                id={`section-${section.id}`}
                                inputClassName="text-center !p-0"
                                placeholder={`Section ${index + 1}`}
                                className={`text-neutral-600 flex-1 max-w-full placeholder-neutral-300 hover:bg-neutral-100 rounded-lg`}
                                wrapper={{className: 'text-center h-11 gap-0'}}/>}
                        leftChild={
                            <div className="flex items-center bg-neutral-50 rounded-lg absolute left-4 hover:bg-neutral-100 p-3 -mt-5 !cursor-pointer"
                                onClick={()=>setIsSectionExpanded(!isSectionExpanded)}>
                                <Icon
                                    color="neutral-600"
                                    className="font-semibold"
                                    icon={isSectionExpanded ? faChevronDown : faChevronRight}
                                />
                            </div>}
                    />
                </div>

                <div className={`flex flex-col gap-2 bg-neutral-50 rounded-lg `}>

                    {/* ********** Fields ********** */}
                    <div id={`section-content-${section.id}`} className={`${!isSectionExpanded ? 'hidden' : 'block'} ${isDragging ? 'p-1' : ''}`}>
                        {children}
                    </div>

                    {/* ********** Add Section or Field ********** */}
                    <div className="flex items-center gap-2 mt-1 ml-4 mb-2  p-2 w-fit rounded-lg px-3 hover:bg-neutral-100 cursor-pointer"
                        onClick={(event)=> AskForMenu(event, GetSectionActionsContextMenuEntries(section, sboActions) )}>
                        <Icon icon={faPlus} color="neutral-600" className="w-3 h-3 cursor-pointer"/>
                        <Label text="Click to insert block" color="neutral-400" className="cursor-pointer"/>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SBOEditorSection;
