import {FileExtension} from '@aktek/f4kit';
import {useCallback} from 'react';

import AskForModal from '@/asks/AskForModal';
import {TMediaFile} from '@/types/Media';

import FileSelectModal from '../Components/FileSelectModal';

type TUseFileAddProps = {
    isEditMode: boolean;
    mediaFiles: TMediaFile[];
    minimum?: number;
    maximum?: number;
    extensions?: string[];
    fileSelectRef: React.RefObject<{clickToSelectFiles: () => void}>;
};

const SHOW_MODAL_KEY = 'fileSelectModal';

export const useFileAdd = ({
    isEditMode,
    mediaFiles,
    minimum,
    maximum,
    extensions,
    fileSelectRef,
}: TUseFileAddProps) => {
    return useCallback(() => {
        if (!isEditMode) return;

        if (!ls.showPopup(SHOW_MODAL_KEY)) {
            fileSelectRef.current?.clickToSelectFiles();

            return;
        }

        AskForModal(FileSelectModal, {
            existingFilesArray: mediaFiles,
            minimum,
            maximum,
            extensions: extensions as (keyof typeof FileExtension)[],
            onProceed: (doNotShowAgain) => {
                if (doNotShowAgain) {
                    ls.doNotShowThisPopupAgain(SHOW_MODAL_KEY);
                }

                fileSelectRef.current?.clickToSelectFiles();
            },
        });
    }, [isEditMode, mediaFiles, minimum, maximum, extensions, fileSelectRef]);
};
