
import {toast} from '@aktek/f4kit';

import F4TableSpreadsheet, {useF4TableSpreadsheetRef} from '@/components/F4TableSpreadsheet/F4TableSpreadsheet';
import {strings} from '@/localization/i18n';

import useF4CurrentTab from '../Home/hooks/useF4CurrentTab';

type TDataTableViewTabState = {
    sboId: string;
};

const BulkRecordEditor = ({}) => {
    const currentTab = useF4CurrentTab<TDataTableViewTabState>();
    const {sboId} = currentTab.getTabState();

    if (!sboId) {
        currentTab.close();

        return null;
    }

    const f4TableSpreadSheetRef = useF4TableSpreadsheetRef();
    const tab = useF4CurrentTab<TDataTableViewTabState>();

    return (
        <div className="bg-neutral-50 h-full p-4">
            <F4TableSpreadsheet
                ref={f4TableSpreadSheetRef}
                key={sboId +'-'+ 'creation'}
                sboId={sboId}
                mode={'creation'}
                filter={null}
                onCancel={() => {
                    tab.close();
                }}
                onDelete={() => {
                }}
                onSave={() => {
                    tab.close();
                }}
                onCreate={() => {
                    toast.success(strings('created_successfully'));
                    tab.close();
                }}
            />
        </div>
    );
};

export default BulkRecordEditor;
