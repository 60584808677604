import {validateConnectedRefField} from './validations.async/validateConnectedRefField.async';
import {validateMetaField} from './validations.async/validateMetaField.async';
import {validateRefField} from './validations.async/validateRefField.async';
import {validateRefListField} from './validations.async/validateRefListField.async';

const validateCell = async (cell) => {
    const {type, value, _id, field, parentValue, affecterValue, affecterColumn} = cell;
    if (type == 'ref') return validateRefField({value, _id, field});

    if (type == 'connectedRef') return validateConnectedRefField({value, _id, field, parentValue, affecterValue, affecterColumn});

    if (type == 'refList') return validateRefListField({value, _id, field});

    if (type == 'meta') return validateMetaField({value, _id, field});

    return {isValid: true, finalValue: value};
};

export default validateCell;
