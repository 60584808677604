import {TUpdateObject} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetMoveSectionUpdateObject(data: XSBO, dragSectionId: string, hoverIndex: number) {
    // Find the current index of the dragged section
    const currentIndex = data.sections.findIndex((sectionId) =>{
        return sectionId === dragSectionId;
    });
    if (currentIndex === -1) return;

    const newSections = [...data.sections];

    newSections.splice(currentIndex, 1);
    newSections.splice(hoverIndex, 0, dragSectionId);

    const updateObject: TUpdateObject<XSBO> = {
        [`sections`]: newSections,
    };

    return updateObject;
}
