
import {ScrollToErrorField} from '@/components/F4CreatableForm/F4CreatableForm';

export const OpenFieldContainerAndScrollToError = (validation, sboData) => {
    if (!validation.isFormValid && validation.validations) {
        const firstErrorKey = Object.keys(validation.validations)[0];

        const fieldId = firstErrorKey.split('.')[1];

        if (sboData && sboData.fieldsMap && sboData.fieldsMap[fieldId]) {
            const field = sboData.fieldsMap[fieldId];
            const sectionId = field.parentSection;

            if (sectionId) {
                const sectionElement = document.getElementById(`section-${sectionId}`);

                if (sectionElement) {
                    sectionElement.expandSection();
                }
            }
        }

        const fieldElement = document.getElementById(`field-${fieldId}`);

        if (fieldElement) {
            fieldElement.expandField?.();

            fieldElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }

        ScrollToErrorField();
    }
};
