import {AvatarLabelGroup, Button, ContentDivider, Header, TabsManager, THeaderValue} from '@aktek/f4kit';
import {faBell, faCircleCheck, faDisplayChartUp, faLifeRing, faRightFromBracket, faSatellite, faSearch, faUser} from '@fortawesome/pro-regular-svg-icons';
import compact from 'lodash/compact';
import {useCallback, useMemo} from 'react';

import AskForModal from '@/asks/AskForModal';
import AKTEKLogo from '@/assets/images/aktek_logo.png';
import Env from '@/config/env';
import {usePermissions} from '@/context/Permissions/usePermissions';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {useSignUserOut} from '@/context/UserContext/Hooks/useSignUserOut';
import {useUserContext} from '@/context/UserContext/UserContext.Provider';
import {strings} from '@/localization/i18n';
import {useNavigateToLogin, useNavigateToRoot} from '@/routing/navigations';

import SwitchCompanyModal from './components/SwitchCompanyModal';

function AppHeader() {
    const {permissions, helperPermissions} = usePermissions();
    const userContext = useUserContext();

    const canAccessAdmin = helperPermissions.canAccessAdmin;

    const canAccessAnalysis = helperPermissions.canAccessAnalysis;
    const canAccessAKTEKiQ = helperPermissions.canAccessAKTEKiQ;

    const {theme} = useGlobalTheme();

    const {userCompanies, currentUser, currentCompany} = userContext.userAuth;
    const {companyName} = currentCompany || {};
    const profilePicURL = currentUser?.profilePicURL;

    const canAccessCompanySwitcher = helperPermissions.canAccessCompanySwitcher;

    const signOut = useSignUserOut();

    const navigateToRoot = useNavigateToRoot();
    const navigateToLogin = useNavigateToLogin();

    const toggleCompanySwitcherModal = useCallback(() => {
        if (currentUser && userCompanies) {
            AskForModal(SwitchCompanyModal);
        }
    }, [currentUser, userCompanies]);

    const getHeader = useCallback(
        ({onLogo, onLogout, profilePicURL}) => ({
            leftItems: compact([
                {
                    type: 'image',
                    src: theme?.images?.logo ? Env.getFileURL(theme.images.logo) : AKTEKLogo,
                    onClick: onLogo,
                    label: 'Logo',
                    className: theme?.images?.logo ? '!p-0' : '',
                },

                canAccessAnalysis && {
                    type: 'button',
                    buttonProps: {iconSize: 'sm', icon: faDisplayChartUp},
                    label: strings('analysis'),
                    onClick: () => TabsManager.focusOrOpenNewTab('analysis', strings('analysis')),
                },

                canAccessAKTEKiQ && {
                    type: 'button',
                    buttonProps: {iconSize: 'sm', icon: faSatellite},
                    label: 'AKTEK iQ',
                    onClick: () => TabsManager.focusOrOpenNewTab('aktekIQ', strings('aktek_iq'), {}),

                },
                canAccessAdmin && {
                    type: 'button',
                    buttonProps: {iconSize: 'sm', icon: faUser},
                    label: 'Admin',
                    onClick: ()=> TabsManager.focusOrOpenNewTab('navigationViewer', strings('admin'), {navKey: 'admin'}),
                },
            ]),

            rightItems: compact([
                {
                    type: 'button',
                    label: companyName || '',
                    className: `bg-neutral-100  ${!canAccessCompanySwitcher && 'cursor-default hover:!text-neutral-700 !text-neutral-700'} `,
                    buttonProps: {isDisabled: !canAccessCompanySwitcher},
                    onClick: canAccessCompanySwitcher && toggleCompanySwitcherModal,
                },
                {type: 'icon', icon: faSearch, label: 'Search', className: 'h-8 w-8', buttonProps: {iconSize: 'md'}},
                {type: 'icon', icon: faBell, label: 'Notifications', className: 'h-8 w-8', buttonProps: {iconSize: 'md'}},
                {
                    type: 'avatar',
                    src: profilePicURL,
                    label: 'Avatar',
                    size: 'xl',
                    subMenu: [
                        {
                            type: 'custom',
                            component: (
                                <AvatarLabelGroup
                                    avatar={{
                                        img: {src: profilePicURL, alt: 'Profile Picture'},
                                        avatarClassName: 'border-none',
                                    }}
                                    label={{
                                        text: currentUser?.name || '',
                                    }}
                                    subLabel={{
                                        text: currentUser?.email || '',
                                    }}
                                    size="xs"
                                    isCheckable={false}
                                />
                            ),
                        },
                        {
                            type: 'custom',
                            component: <ContentDivider className="py-0 -my-6" />,
                        },
                        {
                            type: 'button',
                            size: 'xs',
                            icon: faUser,
                            label: strings('profile'),
                        },
                        {
                            type: 'button',
                            size: 'xs',
                            icon: faLifeRing,
                            label: strings('helpdesk'),
                        },
                        {
                            type: 'button',
                            size: 'xs',
                            icon: faCircleCheck,
                            label: strings('service_status'),
                        },
                        {
                            type: 'custom',
                            component: <ContentDivider className="py-0 -my-6" />,
                        },
                        {
                            type: 'custom',
                            component: <Button
                                label={strings('logout')}
                                icon={faRightFromBracket}
                                onClick={onLogout}
                                variant="white"
                                className="!border-none !w-full"
                                isGhost
                                justifyContent="start"
                                textColor="error-600"
                            />,
                        },
                    ],
                },
            ]),
        }),
        [currentUser, companyName, profilePicURL, permissions?.admin, theme?.images, userContext],
    );

    const header = useMemo(
        () =>
            getHeader({
                onLogout: () => {
                    signOut();
                    navigateToLogin(true);
                },
                onLogo: navigateToRoot,
                profilePicURL: profilePicURL && Env.getFileURL(profilePicURL),
            }),
        [profilePicURL, permissions, currentCompany, theme?.images, userContext],
    );

    return <Header value={header as THeaderValue} size="md" />;
}

export default AppHeader;
