import {Button, Checkbox, FileExtension, Modal, TModalController, TModalProps} from '@aktek/f4kit';
import {forwardRef, useState} from 'react';

import {strings} from '@/localization/i18n';
import {TMediaFile} from '@/types/Media';

type TFileSelectModalProps = Pick<TModalProps, 'onClose'> & {
    onProceed: (showAgain: boolean) => void;
    existingFilesArray?: TMediaFile[];
    minimum?: number;
    maximum?: number;
    extensions?: (keyof typeof FileExtension)[];
}

const FileSelectModal = (props: TFileSelectModalProps, ref: (instance: TModalController) => void) => {
    const {onProceed, existingFilesArray = [], minimum = 0, maximum, extensions, onClose} = props;
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const remainingFiles = maximum ? maximum - (existingFilesArray?.length || 0) : undefined;

    const max = (Number(maximum) || '') + '';
    const min = (Number(minimum) || '') + '';

    return (
        <Modal
            ref={ref}
            size="md"
            title={strings('select_files' )}
            className="overflow-hidden !rounded-3xl h-2/6 !w-2/5"
            headerClassName="border-b border-b-neutral-100 px-5 pt-2 pb-1 text-xl"
        >
            <div className="p-5 flex flex-col gap-4">
                {remainingFiles > 0 && (
                    <>
                        <p className="text-neutral-600">
                            {min && max && strings('files_selection_range_info', min, max)}
                            {min && !max && strings('files_selection_range_info', min)}
                            {!min && max && strings('files_selection_range_info', max)}
                        </p>
                        {!!extensions?.length && ( <p className="text-neutral-500 text-sm"> {strings('accepted_formats' )}: {extensions?.join?.(', ')} </p>)}
                    </>
                )}
                {!remainingFiles && (
                    <>
                        <p className="text-error-600">
                            {strings('maximum_files_reached', max)}
                        </p>
                        <p className="text-neutral-500 text-sm">
                            {strings('files_exceeding_maximum_ignored')}
                        </p>
                    </>
                )}
            </div>
            <div className="border-t border-t-neutral-100 p-4 flex flex-col gap-4">
                <div className="flex items-center">
                    <Checkbox
                        value={doNotShowAgain}
                        onChange={(checked) => setDoNotShowAgain(checked)}
                        label={strings('do_not_show_again' )}
                        size="sm"
                    />
                </div>
                <div className="flex justify-between items-center gap-4">
                    <Button
                        label={strings('cancel' )}
                        variant="white"
                        className="flex-1"
                        onClick={onClose}
                    />
                    <Button
                        label={strings('proceed' )}
                        className="flex-1"
                        onClick={() => {
                            onProceed?.(doNotShowAgain);
                            onClose?.();
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default forwardRef(FileSelectModal);
