import {TRecordField} from '@/app/DataTable/types/Record.Types';
import {strings} from '@/localization/i18n';

const GetIntegerFieldName = (field: TRecordField) => {
    const {minimum, maximum, name} = field;

    if (!minimum && !maximum) return name;

    const maxPart = maximum ? `${strings('max')} ${maximum}` : '';
    const minPart = minimum ? `${strings('min')} ${minimum}` : '';
    const separator = maximum && minimum ? ', ' : '';

    const constraints = [minPart, maxPart]
        .filter(Boolean)
        .join(separator);

    return constraints ? `${name} (${constraints})` : name;
};

export default GetIntegerFieldName;
