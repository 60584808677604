import {TModalRef} from '@aktek/f4kit';
import {forwardRef, useCallback, useMemo, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {strings} from '@/localization/i18n';
import {TFolder} from '@/types/Folder.t';

import {FOLDERS_AND_DATA} from '../constants/constantIds.c';
import useFilteredItems from '../hooks/useFilteredItems';
import FolderModalsContent from './components/Folder.Modals.Content';

type TFolderEditMoveModal = TF4CreatableModalProps & {
    folder?: TFolder
}

const FolderEditMoveModal = (props: TFolderEditMoveModal, ref: TModalRef) => {
    const {viewState, folder, ...rest}= props;
    const [searchValue, setSearchValue] = useState('');
    const {theme} = useGlobalTheme();
    const defaultColorValue = theme?.colors?.neutral ?? '#7B89A0';

    const customColor = folder?.customColor || defaultColorValue;

    const [info, setInfo] = useState({
        folderName: folder?.label,
        parentId: folder?.parentId,
        icon: folder?.customIcon,
        color: customColor,
    });
    const {folders: items, editFolder} = useGlobalFilesAndFolders();

    const updateInfoState = (key, value) => setInfo((state) => ({...state, [key]: value}));

    const data = useFilteredItems(items, searchValue, true, 'folder', folder.id, folder.parentId);

    const finalInitialValue = useMemo(() => {
        const defaultParentId = folder?.parentId ? folder?.parentId : FOLDERS_AND_DATA;

        const initialValue = {
            ...folder,
            customColor: info.color,
            customIcon: info.icon,
            parentId: {[defaultParentId]: true},
        };

        return initialValue;
    }, [folder]);

    const finalImageColorSelectorValue = useMemo(() => {
        return {
            customColor: info.color == defaultColorValue ? null : info.color,
            customIcon: info.icon,
        };
    }, [info]);

    const handleEditFolder = useCallback((f4FormInfo) => {
        const {customColor, customIcon, id, label} = f4FormInfo;
        const finalValue = {
            id,
            label,
            customColor,
            customIcon,
            parentId: info.parentId,
        };

        return editFolder(finalValue);
    }, [info.parentId]);

    return (
        <F4CreatableModal
            ref={ref}
            viewState={viewState}
            title={strings('folder')}
            formKey="folder"
            f4FormProps={{initialValue: finalInitialValue}}
            hasSidebar={false}
            storeFunction={handleEditFolder}
            editSuccessMessage={strings('edit_folder_success')}
            errorMessage={strings('edit_folder_failed')}
            {...rest} >

            <FolderModalsContent
                info={info}
                data={data}
                searchValue={searchValue}
                updateInfoState={updateInfoState}
                setSearchValue={setSearchValue}
                finalInitialValue={finalImageColorSelectorValue}
            />

        </F4CreatableModal>

    );
};

export default forwardRef(FolderEditMoveModal);
