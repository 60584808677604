import {Button, Label, OverflowContainer, Popover, TButtonProps} from '@aktek/f4kit';
import cn from 'classnames';
import {useMemo} from 'react';

import AskForMenu from '@/asks/AskForMenu';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import {RibbonKnownActions} from '../Constants/RibbonKnownActions.c';
import {GetActionsClasses} from '../Styles/GetClasses';
import {TRibbonActionProps, TTableRibbonAction} from '../Types/Ribbon.t';
import ActionButton from './ActionButton';

const defaultButtonProps: Partial<TButtonProps> = {
    isOutlined: false,
    isGhost: false,
    variant: 'white',
    className: '!h-9 shadow-sm !border !border-neutral-300 !rounded-lg',
};

const TableRibbon = ({state, ribbonProps={hasSearch: true}, title, actions = [], onActionClick, searchState, searchBarProps}: TRibbonActionProps) => {
    const classes = GetActionsClasses({});

    const items = useMemo(() => {
        const leftActions: TTableRibbonAction[] = [];
        const rightActions: TTableRibbonAction[] = [];
        actions?.forEach((action) => {
            if (!action) return;
            const finalAction: TTableRibbonAction = typeof action == 'string' ? RibbonKnownActions[action] : action;

            if (!finalAction) return;

            if (finalAction.isVisible === false) return;

            if (finalAction.placement == 'right') {
                rightActions.push(finalAction);
            } else {
                leftActions.push(finalAction);
            }
        });
        const Search = ribbonProps?.hasSearch
            ? <SearchBox
                key="search"
                placeholder={strings('search')}
                isGhost={false}
                className={cn('max-w-48', searchBarProps?.className)}
                onSearch={searchState.setQuery}
                debounceTime={400}
                size="sm"
                hasBottomBorder={false}
                textBoxInputClassName="!border !border-neutral-300 !rounded-lg !text-neutral-500 shadow-sm h-9"
                {...searchBarProps}
            />
            : null;

        return [
            <Label text={ribbonProps.title} key={title} size="xl" color="neutral-900" fontWeight="semibold" />,
            ...leftActions.map((action, index) => {
                const {contextMenuEntries} = action;

                const onClickCb = (e: React.MouseEvent) => {
                    if (contextMenuEntries && !action.rightClickMode) {
                        const _contextMenuEntries = contextMenuEntries.map((entry)=> {
                            return {
                                ...entry,
                                onClick: (e) => {
                                    onActionClick(entry.key, e, state);
                                },
                            };
                        });
                        const buttonRect = (e.currentTarget as HTMLElement).getBoundingClientRect();
                        AskForMenu(e, _contextMenuEntries, {
                            x: buttonRect.left,
                            y: buttonRect.bottom,
                        });
                    } else {
                        onActionClick?.(action.key, e, state);
                    }
                };

                return <ActionButton
                    defaultButtonProps={defaultButtonProps}
                    onClick={onClickCb}
                    key={action.key + '-' + index}
                    action={action} />;
            }),
            <span id="right-split-dix" className={classes.middleSpan} key="right-split-div" />,

            Search,

            ...rightActions.map((action, index) => {
                const onClickCb = (e: React.MouseEvent) => {
                    const {contextMenuEntries} = action;

                    if (contextMenuEntries) {
                        contextMenuEntries.map((entry)=> {
                            entry.onClick = (e) => {
                                onActionClick(entry.key, e, state);
                            };
                        });
                        const buttonRect = (e.currentTarget as HTMLElement).getBoundingClientRect();
                        AskForMenu(e, contextMenuEntries, {
                            x: buttonRect.left,
                            y: buttonRect.bottom,
                        });
                    } else {
                        onActionClick?.(action.key, e, state);
                    }
                };

                return <ActionButton
                    defaultButtonProps={defaultButtonProps}
                    key={action.key + index + 'right'}
                    onClick={onClickCb}
                    action={action} />;
            }),
        ];
    }, [actions]);

    return (
        <OverflowContainer
            className={classes.overflow}
            isProtectedFn={() => false}
            items={items}
            renderOverflow={(elements) => (
                <Popover className={classes.popover} content={
                    <div className={classes.overflowContentContainer}>
                        {elements}
                    </div>}>
                    <Button className={classes.overflowContentContainer} label="..." />
                </Popover>
            )}
        />
    );
};

export default TableRibbon;
