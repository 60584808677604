import {F4Form} from '@aktek/f4form';
import {LoadingIndicator} from '@aktek/f4kit';
import _isEmpty from 'lodash/isEmpty';
import {useMemo} from 'react';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';

import RecordBody from './components/Record.Body';
import RecordFooter from './components/Record.Footer';
import RecordHeader from './components/Record.Header';
import RecordTags from './components/Record.Tags';
import useRecordEditor from './hooks/useRecordEditor';

 type TRecordEditorTab = {
        props: {
            sboId: string
        },
        onSave: () => void,
        layout: boolean,
        mode: 'edit' | 'create' | 'view',
        _id: string,
        canEdit: boolean
    };

const RecordEditor = () => {
    const currentTab = useF4CurrentTab<TRecordEditorTab>();
    const {props, onSave, layout, mode, _id: morphId, canEdit} = currentTab.getTabState();
    const {
        loading,
        sbo,
        handleCancel,
        handleSave,
        handleSaveAndFillAnother,
        initialValue,
        editableFieldsIDs,
        handleSwitchToBulkEntry,
        handleEdit,
    } = useRecordEditor(props.sboId, mode, morphId, onSave, currentTab);
    const {sections, fieldsMap, hasRecordTags, sectionsMap, name, description, areRecordTagsRequired} = sbo;
    const isViewMode = mode === 'view';

    const hasSections = useMemo(() => {
        return sections?.every((sectionId) => sectionsMap?.hasOwnProperty(sectionId));
    }, [sections, sectionsMap]);

    if (loading && _isEmpty(sbo)) {
        return <div className="bg-neutral-50 w-full h-full overflow-y-auto flex items-center justify-center">
            <LoadingIndicator size="xl" />
        </div>;
    }

    return (
        <div className="bg-neutral-50 w-full h-full overflow-y-auto">
            <F4Form
                formKey="single-record"
                revalidateFieldOnChange="all"
                className="h-full bg-neutral-50"
                initialValue={initialValue}
                isLoading={loading}
                onEditStatusChange={(status) => {
                    currentTab.updateConfig({hasUnsavedChanges: status});
                }}
            >
                <div className="px-32 pt-14 pb-24">
                    <RecordHeader
                        title={name as string}
                        hasRecordTags={hasRecordTags}
                        layout={layout}
                        onLayoutChange={(value) => currentTab.setTabState({layout: value as boolean})}
                        description={description}
                        morphId={morphId}
                        mode={mode} />

                    <RecordTags
                        hasRecordsTags={hasRecordTags}
                        isRequired={areRecordTagsRequired}
                        isViewMode={isViewMode} />

                    <RecordBody
                        sections={sections}
                        fieldsMap={fieldsMap}
                        hasSections={hasSections}
                        layout={layout}
                        isViewMode={isViewMode}
                        sectionsMap={sectionsMap}
                        editableFieldsIDs={editableFieldsIDs} />
                </div>
                <RecordFooter
                    onCancel={handleCancel}
                    onSave={handleSave}
                    onEdit={handleEdit}
                    canEdit={canEdit}
                    isViewMode={isViewMode}
                    onSaveAndFillAnother={handleSaveAndFillAnother}
                    onSwitchToBulkEntry={handleSwitchToBulkEntry}
                />
            </F4Form>
        </div>
    );
};

export default RecordEditor;
