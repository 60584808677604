import {LoadingIndicator} from '@aktek/f4kit';

import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';

import {TImageColorSelectorValue} from '../ImageOrColorSelector.Modal';
import {getImageOrColorSelectorClasses} from '../styles/ImageOrColorSelector.styles';
import ImageOrColorSelectorBodyContent from './ImageOrColorSelector.Body.Content';

// Improve type definitions
type SelectorOption = 'image' | 'color'
type SelectorType = 'report' | 'folder'

interface ImageOrColorSelectorBodyProps {
    finalValue?: TImageColorSelectorValue
    onChange?: (value: TImageColorSelectorValue) => void
    defaultValue?: string
    type?: SelectorType
    option: SelectorOption
    loading?: boolean
}

const ImageOrColorSelectorBody: React.FC<ImageOrColorSelectorBodyProps> = ({
    finalValue,
    onChange,
    defaultValue,
    type,
    option,
    loading,
}) => {
    const {theme} = useGlobalTheme();
    const defaultColorValue = defaultValue ?? theme?.colors?.neutral ?? '#7B89A0';
    const classes = getImageOrColorSelectorClasses();

    if (loading) {
        return (
            <div className={classes.bodyContainerLoading}>
                <LoadingIndicator size="xl" />
            </div>
        );
    }

    return (
        <div className={classes.bodyContainer}>
            <ImageOrColorSelectorBodyContent
                option={option}
                finalValue={finalValue}
                defaultColorValue={defaultColorValue}
                onChange={onChange}
                type={type}
            />
        </div>
    );
};

export default ImageOrColorSelectorBody;
