import {useRef} from 'react';

import {strings} from '@/localization/i18n';

import {TImageColorSelectorValue} from '../ImageOrColorSelector.Modal';
import ImageOrColorSelectorColor from './ImageOrColorSelector.Color';
import ImageOrColorSelectorImageUploader from './ImageOrColorSelector.ImageUploader';
import ImageOrColorSelectorWarning from './ImageOrColorSelector.Warning';

type SelectorOption = 'image' | 'color'
type SelectorType = 'report' | 'folder'

type TImageOrColorSelectorBodyContentProps = {
    option: SelectorOption
    finalValue?: TImageColorSelectorValue
    defaultColorValue: string
    onChange?: (value: TImageColorSelectorValue) => void
    type?: SelectorType
}

const ImageSelectorBodyContent = (props: TImageOrColorSelectorBodyContentProps) => {
    const {finalValue, onChange} = props;
    const hasColor = finalValue?.type === 'color' && finalValue?.value !== null;
    const hasColorRef = useRef(hasColor);

    return <div className="flex flex-col h-full items-center justify-between gap-4">

        <ImageOrColorSelectorWarning label={strings('custom_icon_override_warning')} show={hasColorRef?.current} />
        <ImageOrColorSelectorImageUploader
            label={strings('click_to_upload')}
            description={strings('click_to_upload_desc', '1')}
            value={finalValue?.type === 'image' ? finalValue.value : null}
            hasWarning={hasColorRef?.current}
            onChange={(image: string) => {
                if (image) {
                    onChange?.({type: 'image', value: image});
                }
            }}
        />
    </div>;
};

const ColorSelectorBodyContent = (props: TImageOrColorSelectorBodyContentProps) => {
    const {finalValue, defaultColorValue, type, onChange} = props;
    const hasImage = finalValue?.type === 'image' && finalValue?.value !== null;
    const hasImageRef = useRef(hasImage);

    return (
        <ImageOrColorSelectorColor
            hasImage={hasImageRef?.current}
            value={finalValue?.type === 'color' && finalValue.value || defaultColorValue}
            onChange={(color: string) => {
                onChange?.({type: 'color', value: color});
            }}
            type={type}
        />
    );
};

const ImageOrColorSelectorBodyContent = (props: TImageOrColorSelectorBodyContentProps) => {
    if (props.option === 'image') {
        return <ImageSelectorBodyContent {...props} />;
    }

    return <ColorSelectorBodyContent {...props} />;
};

export default ImageOrColorSelectorBodyContent;
