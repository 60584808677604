import {faCircleArrowUpRight, faFolder} from '@fortawesome/pro-regular-svg-icons';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';
import {FileTypes} from '@/types/FileTypes.t';

import {FOLDERS_AND_DATA, SHORTCUTS} from '../constants/constantIds.c';
import {TExplorerUIState} from '../hooks/useExplorerUI';
import {GetFoldersRightPanelClasses} from '../styles/FoldersRightPanel.styles';
import EmptyComponent from './EmptyComponent';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';
import {FoldersRightPanelContent} from './FoldersRightPanel.Content';
import {FoldersRightPanelHeader} from './FoldersRightPanel.Header';

const FoldersRightPanel = ({explorerUIState}:{explorerUIState: TExplorerUIState}) => {
    const {isLoading} = useGlobalFilesAndFolders();
    const {selectedFolder} = explorerUIState;

    if (!selectedFolder?.children?.length) {
        const isFolder = selectedFolder?.id == FOLDERS_AND_DATA || selectedFolder?.type == FileTypes.folder;
        const isShortcut = selectedFolder?.type == FileTypes.shortcut || selectedFolder?.id == SHORTCUTS;
        const label = isShortcut ? 'this_section_is_empty' : 'no_folders_yet';
        const description = isShortcut ? strings('no_shortcut_desc') : strings('no_folders_desc');
        const icon = isFolder ? faFolder : isShortcut ? faCircleArrowUpRight : undefined;
        const isRepositories = selectedFolder?.id === 'repositories';

        const showBreadcrumb = explorerUIState.rightPanelView === 'folders' && !isShortcut && !isRepositories;
        const rightPanelViewLabel = isShortcut ? strings('shortcuts') : strings('repository');
        const showRightPanelLabel = (explorerUIState.rightPanelView === 'recent' || isShortcut || isRepositories);

        return <EmptyComponent
            explorerUIState={explorerUIState}
            icon={icon}
            label={strings(label)}
            showBreadcrumb={showBreadcrumb}
            rightPanelViewLabel={showRightPanelLabel ? rightPanelViewLabel : false}
            description={strings(description)}
            parentId={selectedFolder?.id}
            hasCreateButton={!isShortcut} />;
    }

    const classes = GetFoldersRightPanelClasses();

    return (
        <div className={classes.container}>
            <FoldersRightPanelHeader explorerUIState={explorerUIState} key="FoldersRightPanelHeader" />
            <FoldersRightPanelContent explorerUIState={explorerUIState} key="FoldersRightPanelContent" />

            <ExplorerLoadingComponent condition={isLoading} />
        </div>
    );
};

export default FoldersRightPanel;
