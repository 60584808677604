import {TValidationResult} from '../../types/Validation.types';

const strings = {
    not_valid: 'Not valid',
};

function isNumberValue(value: unknown): value is number {
    return typeof value === 'number' && !isNaN(value);
}

export const isValidNumber = (
    value: unknown,
    minimum?: number,
    maximum?: number,
): TValidationResult => {
    let numValue: number;

    if (typeof value === 'string') {
        numValue = parseFloat(value);
    } else if (isNumberValue(value)) {
        numValue = value;
    } else {
        return {isValid: false, message: strings.not_valid};
    }

    if (!isNumberValue(numValue)) {
        return {isValid: false, message: strings.not_valid};
    }

    if (minimum !== undefined && numValue < minimum) {
        return {isValid: false, message: strings.not_valid};
    }

    if (maximum !== undefined && numValue > maximum) {
        return {isValid: false, message: strings.not_valid};
    }

    return {isValid: true};
};
