import {toast, useModalRef} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {useState} from 'react';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

const useImageOrColorSelector = (info, entity, finalValue, onChange, onConfirm, ref) => {
    const modalRef = useModalRef(ref);
    const {refresh} = useGlobalFilesAndFolders();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);

        if (info.type == 'report') {
            const res = await SBOService.storeSBO({
                ...entity,
                customIcon: finalValue.type === 'image' ? finalValue.value : '',
                customColor: finalValue.type === 'color' ? finalValue.value : '',
            });

            if (!res.isSuccessful()) {
                toast.error(strings('failed_to_save_entity'));
                setIsLoading(false);

                return;
            }

            await refresh?.();
        } else {
            onConfirm?.(finalValue);
        }

        onChange?.(finalValue);
        modalRef.current.close();
        setIsLoading(false);
    };

    const onCancel = () => modalRef.current.close();

    return {handleConfirm, modalRef, onCancel, isConfirmButtonLoading: isLoading};
};

export default useImageOrColorSelector;
