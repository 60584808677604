import {F4FormElementDecorator} from '@aktek/f4form';
import {DateTime} from '@aktek/f4kit';
import {TDateTimePickerProps, TPickerInputProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/DateTimePicker/Types/DateTimePickerTypes';

const DateTimeWrapper = (props) => {
    const handleChange = (timestamp: number) => {
        if (timestamp?.['isValid'] == false) {
            props.onChange?.(false);

            return;
        }

        props.onChange?.(timestamp);
    };

    return <DateTime {...props as TPickerInputProps} onChange={handleChange} />;
};

const F4DateTime = F4FormElementDecorator<TDateTimePickerProps>(DateTimeWrapper);

export default F4DateTime;
