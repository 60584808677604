export const GetFormNumericString = ([key, value]) => {
    if (typeof value !== 'string') return [key, value?.trim()];

    // Remove trailing dot or comma if present
    let processedValue = value?.trim();

    if (value === '-' || value === '+') {
        processedValue = '';
    }

    if (processedValue.endsWith('.') || processedValue.endsWith(',')) {
        processedValue = processedValue.slice(0, -1);
    }

    const numericValue = processedValue.replace(',', '.').replace('+', '');

    return [key, isNaN(Number(numericValue)) ? value?.trim() : numericValue];
};
