import {TF4ThemeColors} from '@aktek/f3theme';
import {TContextMenuEntry} from '@aktek/f4kit';
import {faT} from '@fortawesome/free-solid-svg-icons';
import {fa00, faAlbumCollection, faCalendar, faCalendarClock, faClock, faEarthAmericas, faFileLines, faFillDrip, faImage, faOutdent, faSpiderWeb, faSquare3, faToggleOn, faVideo, IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {TField, TSBOActions, TSection} from '../types/SBOEditor.t';

const commonFieldProps: Partial<TField> = {
    showDescription: false,

};

export const fieldTypesDictionary: Record<string, Partial<TField> & {color: TF4ThemeColors, icon: IconDefinition}> = {
    string: {
        type: 'string',
        label: 'Text',
        icon: faT,
        color: 'success-600',
        ...commonFieldProps,
    },
    boolean: {
        type: 'boolean',
        label: 'Yes/No',
        icon: faToggleOn,
        color: 'semantic2-600',
        ...commonFieldProps,
    },
    closedlist: {
        type: 'closedlist',
        label: 'Dropdown',
        icon: faToggleOn,
        color: 'semantic3-600',
        ...commonFieldProps,
    },
    color: {
        type: 'color',
        label: 'Color',
        icon: faFillDrip,
        color: 'semantic1-600',
        ...commonFieldProps,
    },
    date: {
        type: 'date',
        label: 'Date',
        icon: faCalendar,
        color: 'neutral-600',
        ...commonFieldProps,
    },
    time: {
        type: 'time',
        label: 'Time',
        icon: faClock,
        color: 'error-600',
        ...commonFieldProps,
    },
    dateTime: {
        type: 'dateTime',
        label: 'Date & Time',
        icon: faCalendarClock,
        color: 'primary-600',
        ...commonFieldProps,
    },
    gps: {
        type: 'gps',
        label: 'GPS',
        icon: faEarthAmericas,
        color: 'warning-600',
        ...commonFieldProps,
    },

    // number
    number: {
        type: 'number',
    },
    float: {
        type: 'float',
        label: 'Decimal',
        icon: fa00,
        color: 'error-600',
        showThousandsSeparator: true,
        numberType: 'regular',
        ...commonFieldProps,
    },
    integer: {
        type: 'integer',
        label: 'Integer',
        icon: faSquare3,
        color: 'semantic3-600',
        showThousandsSeparator: true,
        numberType: 'regular',
        ...commonFieldProps,
    },

    // uploads
    uploads: {
        type: 'uploads',
    },
    mediaImage: {
        type: 'mediaImage',
        label: 'Image',
        icon: faImage,
        color: 'neutral-600',
        ...commonFieldProps,
    },
    mediaVideo: {
        type: 'mediaVideo',
        label: 'Video',
        icon: faVideo,
        color: 'semantic3-600',
        ...commonFieldProps,
    },
    mediaAlbum: {
        type: 'mediaAlbum',
        label: 'Album',
        icon: faAlbumCollection,
        color: 'warning-600',
        ...commonFieldProps,
    },
    uploadlink: {
        type: 'uploadlink',
        label: 'Generic File',
        icon: faFileLines,
        color: 'semantic1-600',
        ...commonFieldProps,
    },
    georepo: {
        type: 'georepo',
        label: 'Shape File',
        icon: faSpiderWeb,
        color: 'warning-600',
        ...commonFieldProps,
    },

    // cross tables links
    crossTablesLinks: {
        type: 'crossTablesLinks',
    },
};

export default function GetSectionActionsContextMenuEntries(section: TSection, sboActions: TSBOActions): TContextMenuEntry[] {
    const result: TContextMenuEntry[] = [];
    const {id: sectionId} = section;
    result.push({
        key: 'Section',
        size: 'sm',
        label: 'Section',
        labelProps: {color: 'neutral-500'},
    });

    result.push({
        key: 'newSection',
        icon: faOutdent,
        label: 'New section',
        size: 'sm',
        labelProps: {fontWeight: 'medium', color: 'neutral-900'},
        iconProps: {
            size: 'sm',
        },
        className: 'hover:!bg-neutral-100 mb-2 rounded-md',
        onClick: () => sboActions.addSection({id: sectionId}),
    });

    result.push({key: 'divider'});

    result.push({
        key: 'basic',
        size: 'sm',
        label: 'Basic',
        labelProps: {color: 'neutral-500'},
        className: 'mt-2',

    });

    Object.entries(fieldTypesDictionary).forEach(([type, field]) => {
        if (type === 'uploads' || type === 'number' || type === 'crossTablesLinks') {
            result.push({key: 'divider', className: 'mt-2'});

            result.push({
                key: type,
                size: 'sm',
                label: strings(type),
                labelProps: {color: 'neutral-500'},
                className: 'mt-2',

            });

            return;
        }

        result.push({
            key: type,
            label: field.label,
            icon: field.icon,
            labelProps: {fontWeight: 'medium', color: 'neutral-900'},
            size: 'sm',
            iconProps: {color: field.color},
            className: 'hover:!bg-neutral-100 rounded-md',
            onClick: () => sboActions.addField(field, sectionId),
        });
    });

    return result;
}
