
export const getThousandSeparatedValue = (value: number | string): string => {
    if (!value) return '';

    const valueString = value.toString();
    const decimalIndex = valueString.indexOf('.');

    const decimalPlaces = valueString.length - decimalIndex - 1;

    return value.toLocaleString('en-US', {minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces});
};
