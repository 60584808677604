import {SBOService} from '@aktek/morph-frontend-sdk';
import {XSBO} from '@aktek/types';
import {faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {Row} from '@tanstack/react-table';
import {useCallback} from 'react';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import useTableRef from '@/components/Table/Hooks/useTableRef';
import {TRowActionsBuilder} from '@/components/Table/Types/Table.t';
import {usePermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';
import {TPermission} from '@/types/Permissions.t';

import GetSBOColumns from './SBO.columns';

export type TGetRowUsersActionsArgs = {
    reportStructure: TPermission,

}

export default function DataStructureTable() {
    const tableRef = useTableRef();
    const {helperPermissions} = usePermissions();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getSBORowActionsFn = useCallback((row: Row<XSBO>): TRowActionsBuilder => {
        return [
            {
                key: 'edit',
                label: strings('edit'),
                isVisible: helperPermissions?.canEditDataStructure,
                icon: faPen,
                showOnRow: true,
            },
            {
                key: 'delete',
                label: strings('delete'),
                isVisible: helperPermissions?.canDeleteDataStructure,
                icon: faTrash,
                showOnRow: true,
            },
        ];
    }, [helperPermissions]);

    return (
        <F4ViewableComponent<XSBO>
            eventId="sbos"
            tableUpdate={true}

            name="data structure"
            tableRef={tableRef}

            createBehavior="tab"
            createTab={{tabKey: 'sboEditor', title: strings('new_data_structure')}}
            tableColumns={GetSBOColumns()}
            rowActions={getSBORowActionsFn}
            canCreate={helperPermissions?.canCreateDataStructure}
            getAllData={SBOService.getUsableSBOs}
            deleteItem={SBOService.deleteSBO}
            deleteSuccessMessage={strings('data_structure_deleted_successfully')}
            emptyProps={{addLabel: strings('new_data_structure')}}
        />
    );
}
