import {Icon, Label, NSwitch} from '@aktek/f4kit';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {LAYOUT_OPTIONS} from '../constants/Record.LayoutOptions.c';
import {GetSingleRecordClasses} from '../styles/Record.Editor.styles';

type TRecordHeaderProps = {
    layout: boolean
    onLayoutChange: (value: string | boolean) => void
    title: string
    description: string
    hasRecordTags: boolean
    morphId: string
    mode: 'edit' | 'create' | 'view'
}

const RecordHeader = (props: TRecordHeaderProps) => {
    const {layout, onLayoutChange, title, description, hasRecordTags, morphId, mode} = props;
    const classes = GetSingleRecordClasses(layout, hasRecordTags);

    return (
        <div className={classes.headerContainer}>
            <div className="flex flex-row gap-3 items-center justify-start">
                <Label
                    text={title}
                    size="4xl"
                    color="neutral-900"
                    fontWeight="bold"
                />

                {description && (
                    <Icon
                        tooltip={description}
                        tooltipProps={{showDelay: 500}}
                        icon={faInfoCircle}
                        color="neutral-600"
                        className="cursor-pointer w-5 h-5 pt-1.5"
                    />
                )}

                <Label
                    text={(mode == 'create' || mode == undefined) ? strings('new') : morphId ?? ''}
                    size="xs"
                    color="neutral-700"
                    fontWeight="medium"
                    className="px-3 py-1 rounded-2xl bg-neutral-100"
                />
            </div>
            <NSwitch
                options={LAYOUT_OPTIONS}
                value={layout}
                defaultValue={false}
                size="lg"
                onChange={onLayoutChange}
                className="!bg-neutral-50 !rounded-lg !border !border-neutral-100"
                buttonProps={{
                    className: '!p-2 !rounded-md !w-[2.8rem] !min-w-[0.2rem] !h-[2.6rem]', // override default styles
                    size: 'lg',
                    isGhost: true,
                }}
            />

        </div>
    );
};

export default RecordHeader;
