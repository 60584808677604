import {TSpreadsheetTypes} from '@aktek/f4kit';
import {XField, XFieldAutomatic, XFieldFloat, XFieldInteger, XFieldMediaAlbum, XFieldMediaGeneric, XFieldMediaImage, XFieldMediaVideo, XFieldMeta, XFieldRef, XFieldRefAnnex, XFieldRefList, XFieldRefListAnnex, XFieldTypes} from '@aktek/types';

// Automatic
import AutomaticCellEdit from '@/components/Fields/Automatic/Automatic.Cell.Edit';
import AutomaticCellView from '@/components/Fields/Automatic/Automatic.Cell.View';
// Boolean
import BooleanCellEdit from '@/components/Fields/Boolean/Boolean.Cell.Edit';
import BooleanCellView from '@/components/Fields/Boolean/Boolean.Cell.View';
// ClosedList
import ClosedListCellEdit from '@/components/Fields/ClosedList/ClosedList.Cell.Edit';
import ClosedListCellView from '@/components/Fields/ClosedList/ClosedList.Cell.View';
// Color
import ColorCellEdit from '@/components/Fields/Color/Color.Cell.Edit';
import ColorCellView from '@/components/Fields/Color/Color.Cell.View';
// Date
import DateCellEdit from '@/components/Fields/Datetime.Date/Date.Cell.Edit';
import DateCellView from '@/components/Fields/Datetime.Date/Date.Cell.View';
// Time
import TimeCellEdit from '@/components/Fields/Datetime.Time/Time.Cell.Edit';
import TimeCellView from '@/components/Fields/Datetime.Time/Time.Cell.View';
// Datetime
import DatetimeCellEdit from '@/components/Fields/Datetime/Cell/Datetime.Cell.Edit';
import DatetimeCellView from '@/components/Fields/Datetime/Cell/Datetime.Cell.View';
// Decimal
import DecimalCellEdit from '@/components/Fields/Decimal/Decimal.Cell.Edit';
import DecimalCellView from '@/components/Fields/Decimal/Decimal.Cell.View';
// Formula
import FormulaCellEdit from '@/components/Fields/Formula/Formula.Cell.Edit';
import FormulaCellView from '@/components/Fields/Formula/Formula.Cell.View';
// Gps
import GpsCellEdit from '@/components/Fields/Gps/Gps.Cell.Edit';
import GpsCellView from '@/components/Fields/Gps/Gps.Cell.View';
// Import components for all field types
// Integer
import IntegerCellEdit from '@/components/Fields/Integer/Integer.Cell.Edit';
import IntegerCellView from '@/components/Fields/Integer/Integer.Cell.View';
// MediaAlbum
import MediaAlbumCellEdit from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.Edit';
import MediaAlbumCellView from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.View';
// MediaGeneric
import MediaGenericCellEdit from '@/components/Fields/MediaGeneric/MediaGeneric.Cell.Edit';
import MediaGenericCellView from '@/components/Fields/MediaGeneric/MediaGeneric.Cell.View';
// MediaImage
import MediaImageCellEdit from '@/components/Fields/MediaImage/MediaImage.Cell.Edit';
import MediaImageCellView from '@/components/Fields/MediaImage/MediaImage.Cell.View';
// MediaVideo
import MediaVideoCellEdit from '@/components/Fields/MediaVideo/MediaVideo.Cell.Edit';
import MediaVideoCellView from '@/components/Fields/MediaVideo/MediaVideo.Cell.View';
// Meta
import MetaCellEdit from '@/components/Fields/Meta/Meta.Cell.Edit';
import MetaCellView from '@/components/Fields/Meta/Meta.Cell.View';
// RefAnnex
import RefAnnexCellEdit from '@/components/Fields/Ref.Annex/RefAnnex.Cell.Edit';
import RefAnnexCellView from '@/components/Fields/Ref.Annex/RefAnnex.Cell.View';
// ConnectedRef
import ConnectedRefCellEdit from '@/components/Fields/Ref.ConnectedRef/ConnectedRef.Cell.Edit';
import ConnectedRefCellView from '@/components/Fields/Ref.ConnectedRef/ConnectedRef.Cell.View';
// RefList
import RefListCellEdit from '@/components/Fields/Ref.List/RefList.Cell.Edit';
import RefListCellView from '@/components/Fields/Ref.List/RefList.Cell.View';
// RefListAnnex
import RefListAnnexCellEdit from '@/components/Fields/Ref.ListAnnex/RefListAnnex.Cell.Edit';
import RefListAnnexCellView from '@/components/Fields/Ref.ListAnnex/RefListAnnex.Cell.View';
// Ref
import RefCellEdit from '@/components/Fields/Ref/Ref.Cell.Edit';
import RefCellView from '@/components/Fields/Ref/Ref.Cell.View';
// String
import StringCellEdit from '@/components/Fields/String/String.Cell.Edit';
import StringCellView from '@/components/Fields/String/String.Cell.View';
// Tags
import TagsCellEdit from '@/components/Fields/Tags/Tags.Cell.Edit';
import TagsCellView from '@/components/Fields/Tags/Tags.Cell.View';
import {TMediaFile} from '@/types/Media';

const renderCell: TSpreadsheetTypes['cellRenderer'] = (props) => {
    const {type, isEditing, onChange, value} = props;
    const _field: XField = (props?.column?.originalField || {}) as XField;

    switch (type as XFieldTypes) {
        case 'string':
            if (isEditing) {
                return <StringCellEdit
                    onChange={onChange}
                    value={value}
                />;
            }

            return <StringCellView
                value={value}
            />;

        case 'integer':
            const field = _field as XFieldInteger;

            if (isEditing) {
                return <IntegerCellEdit
                    onChange={onChange}
                    value={value as number}
                    numberType={field?.numberType}
                    currencyType={field?.currencyType}
                    maximum={field?.maximum}
                    minimum={field?.minimum}
                    isRequired={field?.isRequired}
                />;
            }

            return <IntegerCellView
                value={value as number}
                showThousandsSeparator={field?.showThousandsSeparator}
                numberType={field?.numberType}
                currencyType={field?.currencyType}
            />;

        case 'float':
            const floatField = _field as XFieldFloat;

            if (isEditing) {
                return <DecimalCellEdit
                    onChange={onChange}
                    value={value as number}
                    numberType={floatField?.numberType}
                    currencyType={floatField?.currencyType}
                    maximum={floatField?.maximum}
                    minimum={floatField?.minimum}
                    isRequired={floatField?.isRequired}
                />;
            }

            return <DecimalCellView
                value={value as number}
                showThousandsSeparator={floatField?.showThousandsSeparator}
                numberType={floatField?.numberType}
                currencyType={floatField?.currencyType}
            />;

            // reference fields ------------------------------------------------------------------------------------
        case 'ref':
            const refField = _field as XFieldRef;

            if (isEditing) {
                return <RefCellEdit
                    onChange={onChange}
                    value={value}
                    sboId={refField.sboId}
                    fieldId={refField.fieldId}
                />;
            }

            return <RefCellView
                value={value as string}
                sboId={refField.sboId}
                fieldId={refField.fieldId}
            />;

        case 'refAnnex':
            const refAnnexField = _field as XFieldRefAnnex;

            if (isEditing) {
                return <RefAnnexCellEdit
                    sboId={refAnnexField.sboId}
                    fieldId={refField.fieldId}
                    value={value}
                />;
            }

            return <RefAnnexCellView
                sboId={refField.sboId}
                fieldId={refField.fieldId}
                value={value}
            />;

        case 'refList':
            const refListField = _field as XFieldRefList;

            if (isEditing) {
                return <RefListCellEdit
                    values={value?.map?.((refValue) => refValue?._id) || []}
                    onChange={onChange}
                    sboId={refListField.sboId}
                    fieldId={refListField.fieldId}
                />;
            }

            return <RefListCellView
                values={value?.map?.((refValue) => refValue._id) as string[]}
                fieldId={refListField.fieldId}
                sboId={refListField.sboId}
            />;

        case 'refListAnnex':
            const refListAnnexField = _field as XFieldRefListAnnex;

            if (isEditing) {
                return <RefListAnnexCellEdit
                    fieldId={refListAnnexField.fieldId}
                    sboId={refListAnnexField.sboId}
                    value={value}
                />;
            }

            return <RefListAnnexCellView
                fieldId={refListAnnexField.fieldId}
                sboId={refListAnnexField.sboId}
                value={value}
            />;

        case 'connectedRef':
            const connectedRef = _field as XFieldConnectedRef;

            if ( isEditing) {
                return <ConnectedRefCellEdit
                    sboId={connectedRef.sboId}
                    fieldId={connectedRef.fieldId}
                    onChange={onChange}
                    value={value}
                />;
            }

            return <ConnectedRefCellView
                affecter={connectedRef?.affecter}
                sboId={connectedRef.sboId}
                fieldId={connectedRef.fieldId}
                value={value}
            />;

            // ----------------------------------------------------------------------------------------------------
        case 'color':
            if ( isEditing) return <ColorCellEdit onChange={onChange} value={value} />;

            return <ColorCellView value={value} isSquare={false} />;

        case 'closedList':
            const options = props.column.field.options;
            const selectedLabel = options[value]?.label;

            if ( isEditing) {
                return <ClosedListCellEdit
                    onChange={onChange}
                    value={value}
                    options={options}
                />;
            }

            return <ClosedListCellView
                value={selectedLabel}
            />;

        case 'boolean':
            if ( isEditing) return <BooleanCellEdit onChange={onChange} value={value} />;

            return <BooleanCellView value={value} />;

        case 'gps':
            if ( isEditing) {
                return <GpsCellEdit
                    onChange={onChange}
                    lat={value?.lat}
                    lng={value?.lng}
                />;
            }

            return <GpsCellView
                lat={value?.lat}
                lng={value?.lng}
            />;

            // Media

        case 'mediaImage': {
            const mediaImageField = _field as XFieldMediaImage;

            if (isEditing) {
                return <MediaImageCellEdit
                    files={value as TMediaFile[]}
                    onChange={onChange}
                    maximum={mediaImageField?.maximum}
                    minimum={mediaImageField?.minimum}
                />;
            }

            return <MediaImageCellView
                files={value as TMediaFile[]}
            />;
        }

        case 'mediaVideo': {
            const mediaVideoField = _field as XFieldMediaVideo;

            if (isEditing) {
                return <MediaVideoCellEdit
                    files={value as TMediaFile[]}
                    onChange={onChange}
                    maximum={mediaVideoField?.maximum}
                    minimum={mediaVideoField?.minimum}
                />;
            }

            return <MediaVideoCellView
                files={value as TMediaFile[]}
            />;
        }

        case 'mediaAlbum': {
            const mediaAlbumField = _field as XFieldMediaAlbum;

            if (isEditing) {
                return <MediaAlbumCellEdit
                    onChange={onChange}
                    files={value as TMediaFile[]}
                    maximum={mediaAlbumField?.maximum}
                    minimum={mediaAlbumField?.minimum}
                />;
            }

            return <MediaAlbumCellView
                files={value as TMediaFile[]}
            />;
        }

        case 'mediaGeneric': {
            const mediaGenericField = _field as XFieldMediaGeneric;

            if (isEditing) {
                return <MediaGenericCellEdit
                    onChange={onChange}
                    files={value as TMediaFile[]}
                    maximum={mediaGenericField?.maximum}
                    minimum={mediaGenericField?.minimum}
                />;
            }

            return <MediaGenericCellView files={value as TMediaFile[]} />;
        }

        case 'date':
            return isEditing ? <DateCellEdit onChange={onChange} value={value} />
                : <DateCellView timestamp={value} />;

        case 'time':
            return isEditing ? <TimeCellEdit onChange={onChange} value={value} />
                : <TimeCellView value={value} />;

        case 'datetime':
            return isEditing ? <DatetimeCellEdit onChange={onChange} value={value} />
                : <DatetimeCellView value={value} />;

        case 'meta':
            const metaField = _field as XFieldMeta;

            if (isEditing) {
                return <MetaCellEdit
                    onChange={onChange}
                    value={value}
                    dynamicField={metaField.dynamicField}
                    isMulti={metaField.isMulti}
                />;
            }

            return <MetaCellView
                dynamicField={metaField.dynamicField}
                isMulti={metaField.isMulti}
                value={value}
            />;

        case 'automatic': {
            const automaticField = _field as XFieldAutomatic;

            if (isEditing) {
                return <AutomaticCellEdit
                    value={value}
                    dynamicField={automaticField.dynamicField}
                />;
            }

            return <AutomaticCellView
                value={value}
                dynamicField={automaticField.dynamicField}
            />;
        }

        case 'formula': {
            if (isEditing) {
                return <FormulaCellEdit
                    onChange={onChange}
                    value={value}
                />;
            }

            return <FormulaCellView value={value} />;
        }

        case 'tags': {
            if (isEditing) {
                return <TagsCellEdit onChange={onChange} value={value} />;
            }

            return <TagsCellView value={value} />;
        }

        default:
            console.log('....not supported field type:', {value});

            return null;

    }
};

export default (props) => {
    const classNamesByCellType = {
        'mediaImage': MediaAlbumCellEdit?.classnames,
        'mediaVideo': MediaVideoCellEdit?.classnames,
        'mediaAlbum': MediaAlbumCellEdit?.classnames,
        'mediaGeneric': MediaGenericCellEdit?.classnames,
    };

    return {
        view: renderCell(props),
        classNames: classNamesByCellType[props.type],
    };
};

