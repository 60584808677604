import {TabsManager, toast} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {faTable} from '@fortawesome/pro-regular-svg-icons';

import {AddToRecentlyUsedSBOs} from '@/app/Admin/Data/SBO/helpers/RecentlyUsedSBOs.fn.';
import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForFolder from '@/asks/AskForFolder';
import AskForModal from '@/asks/AskForModal';
import ImageOrColorSelectorModal, {TImageColorSelectorProps} from '@/components/Modals/ImageOrColorSelector/ImageOrColorSelector.Modal';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {CreateURLWithParams} from '@/helpers/CreateURLWithParams.fn';
import {strings} from '@/localization/i18n';

import {GetFolderContextMenu, GetReportsContextMenu, GetShortcutContextMenu} from '../constants/getExplorerTreeContextMenu.c';
import FolderEditModal from '../modals/Folders.Edit.Modal';

export const GetContextMenu = ({
    item,
    foldersPermissions,
    reportStructure,
    currentCompany,
    onSave,
    deleteFolder,
    openDataTable,
    moveDataTableLocation,
    deleteShortcut,
}) => {
    const {type} = item;

    if (type == 'folder') {
        return GetFolderContextMenu({
            copyLinkToFolder: () => onCopyToClipboard(item, currentCompany),
            openEditModal: () => onEditFolder(item, onSave),
            openDeleteModal: () => onDeleteFolder(item, onSave, deleteFolder),
            foldersPermissions,
        });
    }

    if (type == 'report') {
        return GetReportsContextMenu({
            openReport: ()=> onReportOpen(item, openDataTable),
            moveDataTable: () => moveDataTable(item, moveDataTableLocation),
            editDataStructure: () => editDataStructure(item),
            folders: foldersPermissions,
            reportStructure: reportStructure,
            editIcon: () => editIcon(item),
        });
    }

    if (type == 'shortcut') {
        return GetShortcutContextMenu({
            openDeleteModal: () => onDeleteShortcut(item, onSave, deleteShortcut),
            openShortcut: ()=> TabsManager.focusOrOpenNewTab(item.component, item.component, {}),
            folders: foldersPermissions,
        });
    }

    return null;
};

const editIcon = async (entity) => {
    AskForModal<TImageColorSelectorProps>(ImageOrColorSelectorModal, {info: {type: 'report', id: entity.id}});
};

// Folder context menu functions
const onDeleteFolder = (item, onDelete, deleteFolder) => {
    const {id, label} = item;
    const deleteSentence = strings('specific_delete_sentence', strings('_folder'), strings('_folder'));

    AskForConfirmation(`Delete ${label}`, deleteSentence, {
        variant: 'error',
        icon: faTrash,
        confirmButtonProps: {label: strings('delete')},
        onConfirm: async () => {
            const res = await deleteFolder(id);

            if (res.isSuccessful()) {
                toast.success('Folder deleted');
                onDelete();
            }
        }});
};

const onEditFolder = (item, onSave) => {
    AskForModal(
        FolderEditModal,
        {viewState: {mode: 'edit', _id: item.id}, onSave, folder: item},
    );
};

const onCopyToClipboard = (item, currentCompany) => {
    CopyToClipboard(CreateURLWithParams({param1: item.id, param2: currentCompany?._id}));
};

// Report context menu functions
const onReportOpen = (item, openDataTable) => {
    const {label, id, type} = item;

    openDataTable({label, id, type});
};

const moveDataTable = async (item, moveDataTableLocation) => {
    const folderResults = await AskForFolder({folder: item});
    moveDataTableLocation(folderResults as object);
};

const editDataStructure = async (item) => {
    TabsManager.openNewTab('sboEditor', item.label, {sboId: item.id});
    const tab = {
        key: item.id,
        label: item.label,
        icon: faTable,
    };
    AddToRecentlyUsedSBOs(tab);
};

// Shortcut context menu functions
const onDeleteShortcut = (item, onDelete, deleteShortcut) => {
    const {id, label} = item;
    const deleteSentence = strings('specific_delete_sentence', 'shortcut', 'shortcut');

    AskForConfirmation(`Delete ${label}`, deleteSentence, {
        variant: 'error',
        icon: faTrash,
        confirmButtonProps: {label: strings('delete')},
        onConfirm: async () => {
            const res = await deleteShortcut(id);

            if (res.isSuccessful()) {
                toast.success('Shortcut deleted');
                onDelete();
            }
        }});
};
