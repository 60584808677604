import {FileTypeExtensions} from '@aktek/f4kit';

import FilesSwiperUpload from '@/components/FilesSwiperUpload/FilesSwiperUpload';

const MediaVideoCellEdit = ({files = [], onChange, maximum, minimum}) => {
    return <FilesSwiperUpload isEditMode autoFocus
        fileCategory={'video'}
        maximum={maximum}
        minimum={minimum}
        extensions={FileTypeExtensions.video}
        mediaFiles={files}
        onChange={(files) => onChange(files)}
        onNewFiles={(mediaFiles) => {
            onChange([...files, ...mediaFiles]);
        }}
    />;
};

export default MediaVideoCellEdit;
