import {TPermissions} from '@/types/Permissions.t';

export const ComputeHelperPermissions = (permissions: TPermissions) => {
    const currentUser = ls.user;
    const currentCompany = ls.currentCompany;
    const currentUserCompanies = ls.userCompanies;

    // Constants for company switcher logic
    const hasMultipleCompanies = !(currentUserCompanies?.length === 1 && currentUserCompanies[0]?._id === currentCompany?._id);
    const isOperationalOrAgencyCompany = currentCompany?.companyType === 'OPS' || currentCompany?.companyType === 'AGENCY';
    const isPrivilegedUser = ['SUPERADMIN', 'AGENCYMANAGER', 'ACCOUNTMANAGER'].includes(currentUser?.userType);
    const isNotPrimaryCompany = currentCompany?._id !== ls?.primaryCompany?._id;
    const hasAssociatedCompanies = currentUserCompanies?.length > 0;

    if (!permissions || Object.keys(permissions.admin || {}).length === 0) {
        return {
            canAccessCompanySwitcher: hasMultipleCompanies && (
                (isOperationalOrAgencyCompany && isPrivilegedUser)
                || (isNotPrimaryCompany && hasAssociatedCompanies)
            ) || false,
        };
    }

    const {admin, app} = permissions || {};
    const {
        onlineTrafficAnalysis,
        ampDeviceInfo,
        ampArea,
        ampDevice,
        ampMonitor,
        fireData,
        media,
        iqIncidents,
    } = app || {};

    return {
        canEditSettings: admin.companySettings?.can_edit || false,
        canAccessSettings: admin.companySettings?.can_view || admin.companySettings?.can_create || admin.companySettings?.can_edit || false,
        canAccessAdmin: app.admin?.can_access || false,
        canAccessAnalysis: app?.dashboards?.can_view || app?.dashboards?.can_create,

        canAccessAKTEKiQ: [
            onlineTrafficAnalysis,
            ampDeviceInfo,
            ampArea,
            ampDevice,
            ampMonitor,
            fireData,
            media,
            iqIncidents,
        ].some((permission) => permission?.can_access),

        canCreateTags: admin.tags?.can_create || false,
        canDeleteTags: admin.tags?.can_delete || false,
        canEditTags: admin.tags?.can_edit || false,
        canAccessTags: admin.tags?.can_delete || admin.tags?.can_create || admin.tags?.can_edit || false,

        canAccessCompanySettings: [
            admin.companySettings?.can_view,
            admin.companySettings?.can_create,
            admin.companySettings?.can_edit,
            admin.tags?.can_delete,
            admin.tags?.can_create,
            admin.tags?.can_edit,
        ].some(Boolean),

        canAccessRoles: [admin.roles?.can_view, admin.roles?.can_create, admin.roles?.can_edit].some(Boolean),
        canAccessUsers: [admin.users?.can_view, admin.users?.can_create, admin.users?.can_edit].some(Boolean),
        canAccessGroups: [admin.groups?.can_view, admin.groups?.can_create, admin.groups?.can_edit].some(Boolean),

        canAccessTAC: [
            admin.tagBasedAccessControl?.can_view,
            admin.tagBasedAccessControl?.can_create,
            admin.tagBasedAccessControl?.can_edit,
        ].some(Boolean),

        canAccessAccessControl: [
            admin.groups?.can_view,
            admin.users?.can_view,
            admin.roles?.can_view,
            admin.tagBasedAccessControl?.can_view,
        ].some(Boolean),

        canAccessSecurity: [
            admin.groups?.can_view,
            admin.users?.can_view,
            admin.roles?.can_view,
            admin.tagBasedAccessControl?.can_view,
        ].some(Boolean),

        canAccessCompanySwitcher: hasMultipleCompanies && (
            (isOperationalOrAgencyCompany && isPrivilegedUser)
            || (isNotPrimaryCompany && hasAssociatedCompanies)
        ) || false,

        canCreateCompanies: admin.companies?.can_create || false,
        canEditCompanies: admin.companies?.can_edit || false,
        canViewCompanies: admin.companies?.can_view || false,
        canAccessCompanies: [
            admin.companies?.can_create,
            admin.companies?.can_edit,
            admin.companies?.can_view,
        ].some(Boolean),

        canAccessDataStructure: admin.reportStructure?.can_view || admin.reportStructure?.can_create || false,
        canAccessConditionalColoring: admin.conditionalColoring?.can_view || admin.conditionalColoring?.can_edit || false,
        canAccessTranslation: admin.reportStructure?.can_view && admin.translation?.can_view || false,
        canCreateDataStructure: admin.reportStructure?.can_create || false,
        canEditDataStructure: admin.reportStructure?.can_edit || false,
        canDeleteDataStructure: admin.reportStructure?.can_delete || false,
        canAccessAutomation: app.automation?.can_view || false,

        canAccessData: [
            admin.reportStructure?.can_view,
            admin.conditionalColoring?.can_view,
            admin.translation?.can_view,
        ].some(Boolean),

        canAccessAgency: (
            currentUser?.userType !== 'REGULAR'
            && (admin.companies?.can_view || admin.companies?.can_create)
            && (currentCompany?.companyType === 'OPS' || currentCompany?.companyType === 'AGENCY')
        ) || false,

        canAccessLicense: currentCompany?.companyType === 'OPS' && currentUser?.userType === 'SUPERADMIN' || false,

        canAccessAgencySection: (
            currentCompany?.companyType === 'OPS' && currentUser?.userType === 'SUPERADMIN'
        ) || (
            currentUser?.userType !== 'REGULAR'
            && (admin.companies?.can_view || admin.companies?.can_create)
            && (currentCompany?.companyType === 'OPS' || currentCompany?.companyType === 'AGENCY')
        ) || false,
    };
};
