export const reshape = (value: any) => {
    if (typeof value == 'string') {
        return value.trim();
    }

    return value;
};

export const reshapeArray = (value: any) => {
    if (typeof value == 'string') {
        return value.split(',').map((val) => val.trim()).join(',');
    }

    return value;
};
