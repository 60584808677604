import {TabsManager, toast} from '@aktek/f4kit';
import {FoldersService, RecentlyUsedService, SBOService} from '@aktek/morph-frontend-sdk';
import {useState} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {strings} from '@/localization/i18n';

import {getFilesData} from '../app/Explorer/utils/getFilesData.fn';

type TState = {
        recentFolders: Array<unknown>
        allFolders: Array<unknown>
}

export type TRecentFolders = {
    _id?: string
    component?: string
    props?: {
        type?: string
    }
    name?: string
}

export type TFilesAndFoldersState = {
        state: TState
        GetAllFolders: () => void
        folders: Array<unknown>
        refresh: () => void
        deleteFolder: (item: unknown) => Promise<void>
        deleteShortcut: (item: unknown) => Promise<void>
        storeFolder: (value: unknown) => Promise<{ isSuccessful: () => boolean; getData: () => unknown}>
        editFolder: (value: unknown) => Promise<{ isSuccessful: () => boolean; getData: () => unknown}>
        recentlyUsed: Array<TRecentFolders>
        isLoading: boolean
        setLinkToFolder: (id: string) => void
        openDataTable: ({label, type, id}: { label: string, type: string, id: string}) => Promise<void>
        moveDataTableLocation: (value: object) => void
        onReportDoubleClick: (item, onClick) => void
        linkToFolder: string
}

const useFilesAndFoldersState: () => TFilesAndFoldersState = () => {
    const [state, setState] = useState<TState>({
        recentFolders: [],
        allFolders: [],
    });

    const [linkToFolder, _setLinkToFolder] = useState();

    const setLinkToFolder = (id) => _setLinkToFolder(id);

    const updateState= <T>(key, value: T | void) => setState((state) => ({...state, [key]: value}));

    const folders = state.allFolders;
    const recentlyUsed = state.recentFolders as TRecentFolders[];
    const [isLoading, setIsLoading] = useState(false);

    const refresh = () => {
        GetAllFolders();
        GetRecentTabs();
    };

    const GetRecentTabs = async () => {
        setIsLoading(true);
        const res = await RecentlyUsedService.getRecentTabs();
        const data = res.getData();
        const success = res.isSuccessful?.();
        success && updateState<unknown[]>('recentFolders', data?.['tabs']);
        setIsLoading(false);
    };

    const GetAllFolders = async () => {
        setIsLoading(true);
        const res = await FoldersService.getAllFolders();
        const success = res.isSuccessful?.();
        success && updateState<unknown[]>('allFolders', getFilesData(res?.getData()));
        setIsLoading(false);
    };

    const deleteFolder = async (id) => {
        setIsLoading(true);
        const res = await FoldersService.deleteFolder(id);
        await refresh();
        const isDeleted = res.isSuccessful?.();

        setIsLoading(false);

        if (!isDeleted) return toast.error?.(strings('delete_folder_failed'));
    };

    const deleteShortcut = async (id) => {
        setIsLoading(true);
        const res = await FoldersService.deleteShortcut(id);
        await refresh();
        const isDeleted = res.isSuccessful?.();

        setIsLoading(false);

        if (!isDeleted) return toast.error?.(strings('shortcut_delete_fail'));
    };

    const storeFolder = async (value) => {
        const itemChecked = value?.parentId || FOLDERS_AND_DATA;
        const selectedFolderId = itemChecked == FOLDERS_AND_DATA ? null : itemChecked;
        const assets = {
            image: value.customIcon ? value.customIcon : null,
            color: value.customColor ? value.customColor : null,
        };

        const res = await FoldersService.storeFolder(value.label, selectedFolderId, assets.image, assets.color);
        const success = res.isSuccessful?.();

        return {
            isSuccessful: () => success,
            getData: () => res.getData(),
        };
    };

    const editFolder = async (value) => {
        const selectedFolderId = value.parentId == FOLDERS_AND_DATA ? null : value.parentId;

        const [moveResponse, editResponse] = await Promise.all([
            FoldersService.moveFolder(value.id, selectedFolderId),
            FoldersService.editFolder(value.id, value.label, value?.customIcon || null, value?.customColor || null),
        ]);

        const success = moveResponse.isSuccessful?.() && editResponse.isSuccessful?.();

        return {
            isSuccessful: () => success,
            getData: () => moveResponse.getData(),
        };
    };

    const openDataTable = async ({label, type, id}) => {
        TabsManager.focusOrOpenNewTab('dataTable', label, {label, sboId: id}, {tabKey: 'dataTable-' + id});
        await RecentlyUsedService.updateRecentTabs(label, label, {open: 'dataTable', type: type, sboId: id});
        await refresh();
    };

    const moveDataTableLocation = async (value) => {
        setIsLoading(true);
        const sboByIdResponse = await SBOService.getCompleteSBOById(value.id);
        const success = sboByIdResponse.isSuccessful();

        if (!success) {
            toast.error(strings('failed_to_move_data_table'));
            setIsLoading(false);

            return {
                isSuccessful: () => success,
                getData: () => sboByIdResponse.getData(),
            };
        }

        const manipulatedData = {
            ...sboByIdResponse.getData() as object,
            folder: value.parentId,
        };

        const storeResponse = await SBOService.storeSBO(manipulatedData);

        if (!storeResponse.isSuccessful()) toast.error(strings('failed_to_move_data_table'));

        if (storeResponse.isSuccessful()) {
            toast.success(strings('data_table_moved_successfully'));
            await refresh?.();
        }

        setIsLoading(false);

        return {
            isSuccessful: () => storeResponse.isSuccessful(),
            getData: () => storeResponse.getData(),
        };
    };

    const onReportDoubleClick = async (item, onClick) => {
        const {type, label, id} = item;

        if (type == 'report') {
            openDataTable({label, id, type});

            return;
        }

        if (type=='shortcut') return TabsManager.focusOrOpenNewTab(item.component, item.component, {selectedTab: item?.props?.selectedTab});

        onClick?.(item);
    };

    return {
        GetAllFolders,
        folders,
        refresh,
        deleteFolder,
        deleteShortcut,
        recentlyUsed,
        isLoading,
        setLinkToFolder,
        openDataTable,
        storeFolder,
        editFolder,
        moveDataTableLocation,
        onReportDoubleClick,
        state,
        linkToFolder,
    };
};

export default useFilesAndFoldersState;
