import {useCallback} from 'react';

import ValidateIntegerNumberValue from '../helpers/Integer.ValidateNumberValue.fn';

const useIntegerValidation = (
    maximum?: number,
    minimum?: number,
    allowDecimal?: boolean,
) => {
    return useCallback((value: number) => {
        return ValidateIntegerNumberValue(value, maximum, minimum, allowDecimal);
    }, [maximum, minimum, allowDecimal]);
};

export default useIntegerValidation;
