import {faColumns, faList} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

export const LAYOUT_OPTIONS = [
    {
        label: '',
        value: true,
        icon: faColumns,
        tooltip: strings('display_2_columns'),
        tooltipProps: {
            showDelay: 600,
        },
    },
    {
        label: '',
        value: false,
        icon: faList,
        tooltip: strings('display_1_column'),
        tooltipProps: {
            showDelay: 600,
        },
    },
];
