import {TShortcutMap, useCurrentTab, useShortcuts} from '@aktek/f4kit';
import {useEffect} from 'react';

export default function useTabShortcuts(shortcuts: TShortcutMap) {
    const {activate, deactivate} = useShortcuts(shortcuts);

    const tabContext = useCurrentTab({onActiveChange: (nodeId, isActive)=>{
        if (isActive) {
            activate();
        } else {
            deactivate();
        }
    }});

    useEffect(()=>{
        if (tabContext.getConfig().isActive) {
            activate();
        } else {
            deactivate();
        }
    }, []);

    return tabContext;
}
