import {SBOService} from '@aktek/morph-frontend-sdk';
import {XSBO} from '@aktek/types';

const GetCompleteSBO = async ({id, onResponse, onLoad}) => {
    onLoad(true);
    const res = await SBOService.getCompleteSBOById(id);
    onResponse(res.getData() as XSBO);
    onLoad(false);
};

export default GetCompleteSBO;
