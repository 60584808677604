import {useF4FormContext} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {XFieldNumber, XSBO} from '@aktek/types';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';

import {TFieldType, validateMax, validateMin} from '../../helpers/ValidateMinMax.fn';
// import ValidateInputPasting from '../../helpers/Validation/ValidateInputPasting.fn';
import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';
import SBOFieldCommon from './SBO.Field.Common';

const NUMBER_TYPES = {
    regular: {label: 'Regular', value: 'regular'},
    currency: {label: 'Currency', value: 'currency'},
    percentage: {label: 'Percentage', value: 'percentage'},
};

const CURRENCY_TYPES = {
    '$': 'USD ($)',
    '£': 'GBP (£)',
    '€': 'EURO (€)',
    'A$': 'AUD (A$)',
    'CAD$': 'CAD ($)',
    'COP$': 'COP ($)',
    '¥': 'YEN (¥)',
    '₣': 'CHF (₣)',
    'L': 'HNL (L)',
    'R$': 'BRL (R$)',
};

// export const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>, allowDecimal:boolean, allowNegative: boolean) => {
//     // Allow: backspace, delete, tab, escape, enter
//     if ([8, 46, 9, 27, 13].includes(e.keyCode)) return;

//     // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
//     if ([65, 67, 86, 88].includes(e.keyCode) && (e.ctrlKey || e.metaKey)) return;

//     // Allow: home, end, left, right
//     if ([35, 36, 37, 39].includes(e.keyCode)) return;

//     // Allow: plus or minus sign at start only
//     if (allowNegative && (e.key === '-' || e.key === '+') && e.currentTarget.selectionStart === 0) return;

//     // Allow: decimal point or comma (only one) for decimal type only
//     if (allowDecimal && (e.key === '.' || e.key === ',')) {
//         const value = e.currentTarget.value;
//         // Check if the value already contains either a dot or a comma
//         if (!value.includes('.') && !value.includes(',')) return;
//     }

//     // Block any other input that isn't a number
//     if (!/^\d$/.test(e.key)) {
//         e.preventDefault();
//     }
// };

// Type definition to handle field type issues
type NumericField = {
    type: TFieldType;
    numberType: XFieldNumber['numberType'];
    minimum?: number | string;
    maximum?: number | string;
};

export default function SBOFieldIntegerDecimal(props: TSBOEditorFieldProps) {
    const {field, isExpanded, onExpandChange} = props;
    const f4FormContext = useF4FormContext();
    // const updatedField = (f4FormContext.getData() as XSBO)?.fieldsMap?.[field.id];

    return (
        <SBOFieldCommon {...props} isExpanded={isExpanded} onExpandChange={onExpandChange}>
            <div className="flex flex-col w-full bg-neutral-100 rounded-xl items-center gap-1" >

                {/* ********** Minimum ********** */}
                <div className="flex flex-col items-center justify-between gap-2 w-full">
                    <div className="flex items-center gap-2 w-full group/minimum">
                        <Label text="Minimum" className="!w-60 self-start mt-2" color="neutral-400"/>
                        <F4Textbox
                            name={`fieldsMap.${field.id}.minimum`}
                            isGhost
                            // onKeyDown={(e) => handleKeyDown(e, updatedField.type === 'float', true)}
                            isNumeric
                            inputClassName="!text-base font-medium"
                            className="text-neutral-600 group-hover/minimum:!bg-neutral-50 rounded-lg"
                            validate={(value) => {
                                if (!value) return {isValid: true};
                                const updatedField = (f4FormContext.getData() as XSBO)?.fieldsMap?.[field.id] as NumericField;

                                // No comma-to-dot conversion - we'll allow commas during validation
                                return validateMin(
                                    value,
                                    updatedField.maximum,
                                    updatedField.type,
                                    updatedField.numberType,
                                );
                            }}
                        />
                    </div>

                    {/* ********** Maximum ********** */}
                    <div className="flex items-center gap-2 w-full group/maximum ">
                        <Label text="Maximum" className="!w-60 self-start mt-2" color="neutral-400"/>
                        <F4Textbox
                            name={`fieldsMap.${field.id}.maximum`}
                            isGhost
                            inputClassName="!text-base font-medium"
                            className="text-neutral-600 group-hover/maximum:!bg-neutral-50 rounded-lg"
                            // onKeyDown={(e) => handleKeyDown(e, updatedField.type === 'float', true)}
                            isNumeric
                            validate={(value) => {
                                if (!value) return {isValid: true};
                                const updatedField = (f4FormContext.getData() as XSBO)?.fieldsMap?.[field.id] as NumericField;

                                // No comma-to-dot conversion - we'll allow commas during validation
                                return validateMax(
                                    updatedField.minimum,
                                    value,
                                    updatedField.type,
                                    updatedField.numberType,
                                );
                            }}
                        />
                    </div>

                    {/* ********** Number Type ********** */}
                    <div className="flex items-center gap-2 w-full group/numberType ">
                        <Label text="Type" className="!w-60" color="neutral-400"/>
                        <F4Dropdown
                            name={`fieldsMap.${field.id}.numberType`}
                            colSpan={12}
                            options={NUMBER_TYPES}
                            defaultValue={Object.keys(NUMBER_TYPES)[0]}
                            onChange={(value) => {
                                if (value !== 'currency') {
                                    f4FormContext.deleteValue(`fieldsMap.${field.id}.currencyType`, null);
                                }
                            }}
                            required
                            className="mt-1"
                            hideArrow
                            placeholder=""
                            dropdownClassname="border-0 !bg-neutral-100 ring-0 ring-neutral-50 group-hover/numberType:!bg-neutral-50"
                        />
                    </div>

                    {/* ********** Currency Type ********** */}
                    {((f4FormContext.getData() as XSBO)?.fieldsMap?.[field.id] as NumericField)?.numberType === 'currency'
                    && <div className="flex items-center gap-2 w-full group/currencyType ">
                        <Label text="Currency" className="!w-60" color="neutral-400"/>
                        <F4Dropdown
                            name={`fieldsMap.${field.id}.currencyType`}
                            defaultValue={Object.keys(CURRENCY_TYPES)[0]}
                            colSpan={12}
                            options={CURRENCY_TYPES}
                            required
                            // className="mt-1"
                            hideArrow
                            placeholder=""
                            dropdownClassname="border-0 !bg-neutral-100 ring-0 ring-neutral-50 group-hover/currencyType:!bg-neutral-50"
                        />
                    </div>}
                    <F4ToggleSwitch
                        name={`fieldsMap.${field.id}.showThousandsSeparator`}
                        description="Display thousands separator"
                        switchClassName="bg-neutral-200"
                        defaultValue={true}
                        size="sm"
                    />
                </div>
            </div>
        </SBOFieldCommon>
    );
}

