import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetToggleFieldTableVisibilityUpdateObject(data: XSBO, fieldId: string) {
    const updateObject: TUpdateObject<XSBO> = {};
    const fieldIndex = data.tableFields.indexOf(fieldId);

    // If field is already in tableFields, remove it
    if (fieldIndex !== -1) {
        updateObject[`tableFields.${fieldIndex}`] = UpdateActions.DELETE;
    } else {
        // Create a new array with all current table fields
        const newTableFields: string[] = [];

        // Loop through sections in order
        for (const sectionId of data.sections) {
            const section = data.sectionsMap[sectionId];

            // For each field in the section's order
            for (const currentFieldId of section.fields) {
                // If this field is in tableFields or is the field being toggled, add it
                if (data.tableFields.includes(currentFieldId) || currentFieldId === fieldId) {
                    newTableFields.push(currentFieldId);
                }
            }
        }

        updateObject['tableFields'] = newTableFields;
    }

    return updateObject;
}

