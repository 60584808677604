import {useMemo} from 'react';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';
import NavigationCategory from '@/components/Navigation/Navigation.Viewer.Category';
import NavigationPageContainer from '@/components/Navigation/Navigation.Viewer.PageContainer';
import NavigationSection from '@/components/Navigation/Navigation.Viewer.Section';
import {useUserContext} from '@/context/UserContext/UserContext.Provider';

import GetNavigationSections from './helpers/GetNavigationSections.fn';
import {TNavigationViewerProps} from './types/Navigation.t';

function NavigationViewer() {
    const currentTab = useF4CurrentTab<TNavigationViewerProps>();
    const navKey = currentTab.getTabState()?.navKey;
    const userContext = useUserContext();
    const sections = useMemo(() => GetNavigationSections(navKey), [navKey, userContext]);

    return (
        <NavigationPageContainer>
            {sections?.map((section) => section.isVisible && (
                <NavigationSection label={section.sectionTitle} key={section.sectionTitle}>
                    {section.categories?.map((category) => (
                        <NavigationCategory
                            key={category.tabKey}
                            {...category}
                        />
                    ))}
                </NavigationSection>
            ))}
        </NavigationPageContainer>
    );
}

export default NavigationViewer;
