import {F4Form, useF4FormRef} from '@aktek/f4form';
import {Button, Drawer, FitHeight, TabsManager, toast} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {XSBO, XTags} from '@aktek/types';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';
import AskForFolder from '@/asks/AskForFolder';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import UpdateSignalEmitter from '@/helpers/UpdateSignalEmitter';
import {strings} from '@/localization/i18n';

import {defaultSBO} from '../constants/SBO.DefaultSBO.c';
import GetNewFieldSelectedAndFocused from '../helpers/GetNewFieldSelectedAndFocused';
import {OpenFieldContainerAndScrollToError} from '../helpers/OpenFieldContainerAndScrollToError';
import {processNumericFieldValues} from '../helpers/ProcessNumericFieldValues.fn';
import {AddToRecentlyUsedSBOs, GetRecentlyUsedSBOs} from '../helpers/RecentlyUsedSBOs.fn.';
import GetAddFieldUpdateObject from '../helpers/UpdateObjects/GetAddFieldUpdateObject.fn';
import GetAddSectionUpdateObject from '../helpers/UpdateObjects/GetAddSectionUpdateObject.fn';
import GetDeleteFieldUpdateObject from '../helpers/UpdateObjects/GetDeleteFieldUpdateObject.fn';
import GetDeleteSectionUpdateObject from '../helpers/UpdateObjects/GetDeleteSectionUpdateObject.fn';
import GetMoveFieldUpdateObject from '../helpers/UpdateObjects/GetMoveFieldUpdateObject.fn';
import GetMoveSectionUpdateObject from '../helpers/UpdateObjects/GetMoveSectionUpdateObject.fn';
import GetToggleFieldTableVisibilityUpdateObject from '../helpers/UpdateObjects/GetToggleFieldTableVisibilityUpdateObject.fn';
import GetToggleTagFieldTableVisibilityUpdateObject from '../helpers/UpdateObjects/GetToggleTagFieldTableVisibilityUpdateObject.fn';
import {TField, TSBOActions, TSection} from '../types/SBOEditor.t';
import SBOEditorBody from './SBO.EditorBody';
import SBOEditorMain from './SBO.EditorMain';
import SBOEditorSidebar from './SBO.EditorSidebar';

export type TSBOEditorTabState = {
    sboId: string;
    onSave?: () => void
}

export default function SBOEditor() {
    const currentTab = useF4CurrentTab<TSBOEditorTabState, { isDrawerOpen: boolean, tmpData?: XSBO }>();
    const {sboId, onSave} = currentTab.getTabState();
    const sboFormRef = useF4FormRef<XSBO>();

    const {refresh: refreshFolders} = useGlobalFilesAndFolders();
    const recentUsedSBOs = GetRecentlyUsedSBOs();

    const [currentSBO, setCurrentSBO] = useState<XSBO>(/* currentTab.getConfig().tmpData ||*/ defaultSBO);
    const [expandedFieldIds, setExpandedFieldIds] = useState<Set<string>>(new Set());

    const mode = sboId ? 'edit' : 'create';

    useEffect(() => {
        sboId && fetchSBOById();
    }, [sboId]);

    const fetchSBOById = async () => {
        sboFormRef.current?.showLoader();
        const res = await SBOService.getCompleteSBOById(sboId);
        // for f4form to not return error that tags is null

        if (res.isSuccessful()) {
            const sbo = res.getData() as XSBO;
            sbo.tags ??= {tags: [], joined: ''};

            sboFormRef.current.reset(sbo);
            setCurrentSBO(sbo);
        }

        sboFormRef.current?.hideLoader();
    };

    const addSection = (section: TSection = {id: '', fields: []}) => {
        const data = sboFormRef.current.getData();
        const {updateObject, sectionId} = GetAddSectionUpdateObject(data, section);

        sboFormRef.current.updateData(updateObject);
        GetNewFieldSelectedAndFocused('section', sectionId);

        return {sectionId};
    };

    const deleteSection = (sectionId: string) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetDeleteSectionUpdateObject(data, sectionId);
        sboFormRef.current.updateData(updateObject);
    };

    const addField = (field: Partial<TField> = {}, sectionId: string) => {
        const data = sboFormRef.current.getData();

        const {updateObject, fieldId} = GetAddFieldUpdateObject(data, field, sectionId);

        sboFormRef.current.updateData(updateObject);
        GetNewFieldSelectedAndFocused('section', sectionId);
        GetNewFieldSelectedAndFocused('field', fieldId);
    };

    const deleteField = (sectionId: string, fieldId: string) => {
        const data = sboFormRef.current.getData();

        const updateObject = GetDeleteFieldUpdateObject(data, sectionId, fieldId);

        sboFormRef.current.updateData(updateObject);
    };

    const toggleFieldTableVisibility = (fieldId: string) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetToggleFieldTableVisibilityUpdateObject(data, fieldId);

        sboFormRef.current.updateData(updateObject);
    };

    const toggleTagFieldTableVisibility = () => {
        const data = sboFormRef.current.getData();
        const updateObject = GetToggleTagFieldTableVisibilityUpdateObject(data);

        sboFormRef.current.updateData(updateObject);
    };

    const moveField = (fieldId: string, fromSectionId: string, toSectionId: string, toIndex?: number) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetMoveFieldUpdateObject(data, fieldId, fromSectionId, toSectionId, toIndex);

        if (updateObject === null) return;
        sboFormRef.current.updateData(updateObject);
    };

    const handleSave = async () => {
        let validRequest = true;
        const validation = sboFormRef.current.validate();
        const data = sboFormRef.current.getData() as XSBO;

        if (Object.keys(data.fieldsMap).length === 0) {
            validRequest = false;
            toast.error(strings('data_structure_must_have_at_least_one_field'));

            return;
        }

        OpenFieldContainerAndScrollToError(validation, data);

        if (validRequest === true && validation.isFormValid) {
            const tags = {
                tags: (data.tags as XTags)?.tags,
                joined: (data.tags as XTags)?.tags?.join(','),
            };
            data.tags = tags;

            // Process numeric fields to handle special cases
            if (data.fieldsMap) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                Object.entries(data.fieldsMap).forEach(([_fieldId, field]) => {
                    processNumericFieldValues(field);
                });
            }

            // creating a new data structure
            let folderResults;

            if (mode == 'create') {
                folderResults = await AskForFolder({folder: null});

                setCurrentSBO({...currentSBO, folder: folderResults?.parentId});
            }

            sboFormRef.current?.showLoader();

            const finalResults = {
                ...data,
                folder: folderResults?.parentId || data?.folder || null,
            };

            const createSBO = await SBOService.storeSBO(finalResults);
            const SBO = createSBO.getData() as XSBO;

            if (createSBO.isSuccessful()) {
                recentUsedSBOs.map((item, index) => {
                    if (item.key === sboId) {
                        const tab = {
                            key: SBO._id,
                            // @ts-expect-error - We know this is correct based on the original code
                            label: typeof SBO.name !== 'string' ? SBO.name.def : SBO.name,
                            icon: faDatabase,
                        };

                        return recentUsedSBOs[index] = tab;
                    }
                });
                ls.recentlyUsedSBO = recentUsedSBOs;

                setCurrentSBO(data);
                toast.success(strings('data_structure_is_saved_successfully'));
                // @ts-expect-error - We know this is correct based on the original code
                const newName = typeof SBO.name !== 'string' ? SBO.name.def : SBO.name;

                const myTab = {
                    key: SBO._id,
                    label: newName,
                    icon: faDatabase,
                };
                AddToRecentlyUsedSBOs(myTab);
                sboFormRef.current?.hideLoader();

                onSave?.();
                UpdateSignalEmitter.emit('sbos');

                if (mode === 'edit') {
                // loop over all the tabs and check if the sboId is the same as the current sboId
                    TabsManager.model.visitNodes((node)=> {
                        const jsonNode = node.toJson();

                        if (jsonNode.type === 'tab' && 'config' in jsonNode) {
                            if (jsonNode.config?.tabState?.sboId === sboId) {
                                TabsManager.updateTabConfig(node.getId(), {...jsonNode.config, title: newName, tabState: {...jsonNode.config?.tabState, title: newName}});
                            }
                        }
                    });
                }

                // update the tab config
                currentTab.updateConfig({hasUnsavedChanges: false, title: data.name as string}, false);
                currentTab.setTabState({sboId: SBO._id, onSave});

                if (mode == 'create') refreshFolders?.();
            }
        } else {
            toast.error(strings('mandatory_fields_are_missing_or_some_configurations_are_incorrect'));
        }

        sboFormRef.current?.hideLoader();
    };

    const handleSidebarChange = React.useCallback(() => {
        if (currentTab.getConfig().hasUnsavedChanges) {
            currentTab.updateConfig({hasUnsavedChanges: false}, false);
        }
    }, []);

    const moveSection = (dragSectionId: string, hoverIndex: number) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetMoveSectionUpdateObject(data, dragSectionId, hoverIndex);
        sboFormRef.current.updateData(updateObject);
    };

    const sboActions: TSBOActions = {
        addField,
        addSection,
        deleteField,
        deleteSection,
        toggleFieldTableVisibility,
        toggleTagFieldTableVisibility,
        moveSection,
        moveField,
    };

    return (
        <DndProvider backend={HTML5Backend} options={{
            scrollAngleRanges: [
                {start: 0, end: 30}, // straight up
                {start: 150, end: 210}, // straight down
                {start: 30, end: 150}, // diagonal up
                {start: 210, end: 330}, // diagonal down
            ],
        }}>
            <div className="flex h-full">
                <Drawer
                    sideWidth={96}
                    tooltipDelay={1000}
                    defaultOpen={currentTab.getConfig().isDrawerOpen ?? true}
                    onOpenChange={(e) => currentTab.updateConfig({isDrawerOpen: !!e}, false)}
                    buttonSize="sm"
                >
                    <SBOEditorSidebar
                        selectedSboId={sboId}
                        recentSBO={recentUsedSBOs}
                        onChange={handleSidebarChange}
                    />
                    <>
                        <div className="flex flex-col h-full w-full">
                            <FitHeight className="flex-1 w-full overflow-auto bg-neutral-50 p-3 pt-0 border-l border-neutral-200" data-rfd-scroll-container>
                                <F4Form
                                    ref={sboFormRef}
                                    revalidateFieldOnChange="all"
                                    className="h-full px-16 py-2 pt-6"
                                    formKey="sboEditor"
                                    initialValue={currentSBO || {}}
                                    onSetValue={(key, value) => {
                                        if (key == 'name') {
                                            if (!currentTab.getTabState().sboId) {
                                                currentTab.updateConfig({title: `[New] ${value as string}`}, false);
                                            }
                                        }
                                    }}
                                    onEditStatusChange={(status) => {
                                        if (currentTab.getConfig().hasUnsavedChanges != status) {
                                            currentTab.updateConfig({hasUnsavedChanges: status}, false);
                                        }
                                    }}>
                                    <SBOEditorMain sboId={currentSBO._id} />
                                    <SBOEditorBody
                                        sboActions={sboActions}
                                        expandedFieldIds={expandedFieldIds}
                                        setExpandedFieldIds={setExpandedFieldIds}
                                    />
                                </F4Form>
                            </FitHeight>
                            <div className="w-full bg-neutral-50 py-4 pr-4 flex gap-2 justify-end border-l border-neutral-200">
                                <Button isGhost label={strings('cancel')} textColor="neutral-600" onClick={() => currentTab.close()} />
                                <Button label={strings('save')} onClick={handleSave} />
                            </div>
                        </div>
                    </>
                </Drawer>
            </div>
        </DndProvider>
    );
}
