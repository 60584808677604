import {LoadingIndicator, Modal, TModalRef, useModalRef} from '@aktek/f4kit';
import {forwardRef, useCallback, useEffect, useState} from 'react';

import SearchBox from '@/components/SearchBox/SearchBox';
import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {EventKeys} from '@/helpers/Events';
import {strings} from '@/localization/i18n';

import {OnSwitchCompany} from '../helpers/Switch.OnSwitchCompany.fn';
import useKeydownEventListener from '../hooks/useKeydownEventListener';
import useSwitchCompaniesSearch, {isRootCompany} from '../hooks/useSwitchCompaniesSearch';
import {DisplayCompanies} from './Switch.DisplayCompanies';

// Add proper type for the component props
type SwitchCompanyModalProps = Record<string, never>; // empty props object

const SwitchCompanyModal = forwardRef<TModalRef, SwitchCompanyModalProps>(({}, ref) => {
    const modalRef = useModalRef(ref);

    // State and hooks
    const signIn = useSignUserIn();
    const userAuth = useUserAuth();
    const {currentCompany, userCompanies} = userAuth;
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Derived values
    const filteredCompaniesData = useSwitchCompaniesSearch(searchQuery, userAuth);
    const rootCompanyId = userCompanies?.find(isRootCompany)?._id;
    const isCompaniesDataEmpty = !filteredCompaniesData || filteredCompaniesData.length === 0;

    // Handlers
    const handleSwitchCompany = useCallback(
        (companyId: string) => OnSwitchCompany(companyId, setIsLoading, signIn),
        [signIn],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const firstCompanyId = filteredCompaniesData[0]?._id;
            const isNotCurrentCompany = firstCompanyId !== currentCompany?._id;

            if (EventKeys.isEnter(event) && isNotCurrentCompany && firstCompanyId) {
                handleSwitchCompany(firstCompanyId);
            }
        },
        [filteredCompaniesData, currentCompany, handleSwitchCompany],
    );

    // Effects
    useEffect(() => {
        if (userCompanies?.length === 0) {
            location.reload();
        }
    }, [userCompanies]);

    useKeydownEventListener(handleKeyDown, filteredCompaniesData);

    return (
        <Modal
            ref={modalRef}
            className="overflow-hidden relative h-full"
            title={strings('switch_companies')}
            headerClassName="pl-6 border-b border-b-neutral-100 mb-4 text-xl"
            onClose={() => document.removeEventListener('keydown', handleKeyDown)}
        >
            {/* Loading overlay */}
            {isLoading && (
                <div
                    className="absolute flex items-center w-full h-full p-4 justify-center elevation-5 !shadow-none"
                    style={{backgroundColor: 'rgba(0, 0, 0, 0.15)'}}
                >
                    <LoadingIndicator size="xl" />
                </div>
            )}

            {/* Search input */}
            <SearchBox
                onSearch={setSearchQuery}
                className="!bg-neutral-100 rounded-lg mx-6"
                textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                hasBottomBorder={false}
                placeholder={strings('search_companies')}
            />

            {/* Companies list */}
            <div className="h-4/6 overflow-y-auto mx-6 my-4 relative">
                {isCompaniesDataEmpty && (
                    <div className="px-2 py-4 text-center text-neutral-600">
                        {strings('no_results_found')}
                    </div>
                )}
                {!isCompaniesDataEmpty && (
                    <DisplayCompanies
                        companies={filteredCompaniesData}
                        currentCompany={currentCompany}
                        onSwitchCompany={handleSwitchCompany}
                        rootCompanyId={rootCompanyId}
                    />
                )}
            </div>
        </Modal>
    );
});

SwitchCompanyModal.displayName = 'SwitchCompanyModal';

export default SwitchCompanyModal;
