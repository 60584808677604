import {XFieldTypes} from '@aktek/types';
import {pickBy} from 'lodash';

import {type TFieldsMap, type TSupportedField} from './Types/BuildColumns.types';

const RequiredColumns: Array<XFieldTypes> = [
    'string',
    'integer',
    'float',
    'boolean',

    /** Ref */
    'ref',
    'refAnnex',
    'refList',
    'refListAnnex',
    'connectedRef',

    'color',
    'closedList',
    'gps',

    /** Media */
    'mediaImage',
    'mediaVideo',
    'mediaAlbum',
    'mediaGeneric',

    /**  Date fields */
    'date',
    'time',
    'datetime',

    /** subform */
    // 'multisubform',
    // 'subform',

    /** others... */
    'meta',
    'automatic',
    'stopwatch',
    'formula',
];

export default (fieldsMap: TFieldsMap): Record<string, TSupportedField> =>
    pickBy(fieldsMap, (field): field is TSupportedField =>
        !!RequiredColumns.includes(field?.type));
