import {Button, FilesSwiper, Modal, TFileCategory, TModalController} from '@aktek/f4kit';
import {faChevronLeft, faChevronRight, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {forwardRef} from 'react';

import AskForModal from '@/asks/AskForModal';
import {getResourceFileFromMediaFile} from '@/helpers/File';
import {TMediaFile} from '@/types/Media';

type THandleFileDoubleClickProps = {
    file: TMediaFile;
    mediaFiles: TMediaFile[];
    fileCategory: TFileCategory;
};

export const HandleFileDoubleClick = ({
    file,
    mediaFiles,
    fileCategory,
}: THandleFileDoubleClickProps): void => {
    AskForModal(FileFullScreenRef, {file, mediaFiles, fileCategory});
};

type TFileFullScreenProps = {
    file: TMediaFile;
    mediaFiles: TMediaFile[];
    fileCategory: TFileCategory;
}

const FileFullScreen = ({
    file,
    mediaFiles,
    fileCategory,
}: TFileFullScreenProps, ref: React.ForwardedRef<TModalController>) => {
    const fileIndex = mediaFiles.findIndex((mediaFile) => {
        if ('key' in file && 'key' in mediaFile) {
            return file.key === mediaFile.key;
        }

        if ('url' in file && 'url' in mediaFile) {
            return file.url === mediaFile.url;
        }

        return false;
    });

    const getFileName = (file: TMediaFile): string => {
        if ('name' in file) return file.name || '';

        if ('url' in file) {
            const urlParts = file.url?.split('/') || [];

            return urlParts[urlParts.length - 1] || '';
        }

        return '';
    };

    return (
        <div className="relative">
            <Modal
                hideHeader
                closeOnBackdropClick
                ref={ref}
                size="xl"
                title={getFileName(file)}
                className="overflow-hidden !rounded-3xl h-5/6 !w-3/5 bg-gray-50"
            >
                <div className="flex flex-col gap-4 p-6 lg:p-12">
                    <div className="text-xl font-semibold text-gray-900">
                        {getFileName(file)}
                    </div>

                    <div className="relative w-full flex items-center justify-center">
                        <FilesSwiper
                            autoplay={false}
                            swiperOptions={{
                                initialSlide: fileIndex,
                            }}
                            defaultFileCategory={fileCategory}
                            itemSideLength={400}
                            files={mediaFiles?.map?.(getResourceFileFromMediaFile)}
                        />

                        {/* Navigation Buttons */}
                        <Button
                            variant="white"
                            icon={faChevronLeft}
                            className="!absolute left-2 top-1/2 -translate-y-1/2 !shadow-sm"
                            size="sm"
                        />
                        <Button
                            variant="white"
                            icon={faChevronRight}
                            className="!absolute right-2 top-1/2 -translate-y-1/2 !shadow-sm"
                            size="sm"
                        />
                    </div>

                    {/* Close Button */}
                    <Button
                        variant="white"
                        icon={faXmark}
                        className="!absolute right-6 top-6 !shadow-sm"
                        size="sm"
                        onClick={() => {
                            if (ref && typeof ref === 'object' && 'close' in ref && typeof ref.close === 'function') {
                                ref.close();
                            }
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};

const FileFullScreenRef = forwardRef(FileFullScreen);

export default FileFullScreenRef;

