import {useF4FormContext} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

import {TSBOActions} from '../types/SBOEditor.t';
import SBOEditorRecordsTagsField from './SBO.EditorRecordsTagsField';
import SBOEditorSections from './SBO.EditorSections';

type TSBOEditorBodyProps = {
    sboActions: TSBOActions;
    expandedFieldIds: Set<string>;
    setExpandedFieldIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export default function SBOEditorBody(props:TSBOEditorBodyProps) {
    const {sboActions, expandedFieldIds, setExpandedFieldIds} = props;
    const {getData} = useF4FormContext<XSBO>();
    const currentSBO = getData();

    return (
        <div className="pb-4">
            <SBOEditorRecordsTagsField currentSBO={currentSBO} toggleTagFieldTableVisibility={sboActions.toggleTagFieldTableVisibility}/>

            <div className="group">
                <SBOEditorSections
                    currentSBO={currentSBO}
                    sboActions={sboActions}
                    expandedFieldIds={expandedFieldIds}
                    setExpandedFieldIds={setExpandedFieldIds}
                />
            </div>
        </div>
    );
}
