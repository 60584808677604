import {faCalendar, faCalendarClock, faClock} from '@fortawesome/pro-regular-svg-icons';

import {TRecordField} from '@/app/DataTable/types/Record.Types';
import F4DateTime from '@/components/F4Elements/F4DateTime';
import {strings} from '@/localization/i18n';

import FieldBase from '../components/Field.Base';
import {DatetimeClasses} from './styles/Datetime.styles';

type TDateTimeRecordEdit = {
    field: TRecordField;
    onChange?: (data) => void;
    value?:string;
    layout:boolean;
    isViewMode:boolean;
}

const DateTimeRecordEdit = (props: TDateTimeRecordEdit) => {
    const {field, onChange, layout, isViewMode} = props;
    const {name, id, showDescription, description, isRequired, type} = field;
    console.log('🚀 ~ DateTimeRecordEdit ~ field:', field);

    const dateTimeIcons = {
        time: {
            icon: faClock,
            color: 'error-600',
        },
        date: {
            icon: faCalendar,
            color: 'neutral-600',
        },
        dateTime: {
            icon: faCalendarClock,
            color: 'primary-600',
        },
    };

    const classes = DatetimeClasses(layout);

    return <div className={classes.container}>
        <FieldBase
            name={name}
            isRequired={isRequired}
            description={description}
            showDescription={showDescription}
            icon={dateTimeIcons[type].icon}
            iconColor={dateTimeIcons[type].color}
            classes={classes}
        />
        <div className={classes.textboxContainer}>

            <F4DateTime
                name={id}
                onChange={onChange}
                mode={type === 'dateTime' ? 'datetime': type as 'date' | 'time' | 'datetime'}
                placeholder="YYYY-MM-DD HH:MM:SS"
                textBoxProps={{
                    isGhost: true,
                }}
                isDisabled={isViewMode}
                required={isRequired}
                showFormatError={false}
                validate={(value) => {
                    if (value === false) {
                        return {
                            isValid: false,
                            messages: [strings('date_format_error', 'YYYY-MM-DD HH:MM:SS')],
                        };
                    }

                    return {
                        isValid: true,
                        messages: [],
                    };
                }}
                inputClassName={classes.inputClassName}
                popoverProps={{
                    wrapperClassName: '!w-full',
                    className: '!w-fit',
                }}
            />
        </div>
    </div>;
};

export default DateTimeRecordEdit;
