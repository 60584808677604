import {Label, TContextMenuEntry, TreeView} from '@aktek/f4kit';
import _isEqual from 'lodash/isEqual';
import {useCallback, useEffect} from 'react';

import AskForMenu from '@/asks/AskForMenu';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {FindFolderById} from '@/helpers/FindFolderById.fn';
import {strings} from '@/localization/i18n';

import {EMPTY_EXPLORER_DATA} from '../constants/Explorer.EmptyData.c';
import {GetContextMenu} from '../helpers/Folder.GetContextMenu.fn';
import OnNodeClick from '../helpers/Folder.OnNodeClick.fn';
import {TExplorerUIState} from '../hooks/useExplorerUI';
import {getTreeItems} from '../utils/getTreeItems.fn';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

type TExplorerTree = {searchValue:string, explorerUIState: TExplorerUIState}

export default function ExplorerTree(props:TExplorerTree) {
    const {searchValue, explorerUIState} = props;

    const {
        switchToFoldersView,
        switchToRecentView,
        setSelectedFolder,
        selectedFolder,
    } = explorerUIState;

    const {
        folders: items,
        isLoading,
        refresh,
        deleteFolder,
        deleteShortcut,
        recentlyUsed,
        openDataTable,
        moveDataTableLocation,
    } = useGlobalFilesAndFolders();

    const {foldersPermissions, reportStructure} = useAdminPermissions();

    const {currentCompany} = useUserAuth();

    const data = getTreeItems(items, searchValue, recentlyUsed);
    const hasData = data && data.length > 0;

    // this useEffect to make sure the right side data are set correctly after the search
    useEffect(() => {
        if (!selectedFolder || searchValue) return;
        const result = FindFolderById(data, selectedFolder?.id);
        if (_isEqual(result, selectedFolder)) return;
        setSelectedFolder(result);
    }, [searchValue, data]);

    const onNodeClick = useCallback(
        (node) =>
            OnNodeClick(
                node,
                switchToFoldersView,
                switchToRecentView,
                setSelectedFolder,
                openDataTable,
            ),
        [],
    );
    const onContextMenu = useCallback((node, event) => {
        AskForMenu(event, GetContextMenu({
            item: node,
            foldersPermissions,
            reportStructure,
            currentCompany,
            onSave: refresh,
            deleteFolder,
            openDataTable,
            moveDataTableLocation,
            deleteShortcut,
        }) as TContextMenuEntry[]);
    }, [
        foldersPermissions,
        reportStructure,
        currentCompany,
        refresh,
        deleteFolder,
        openDataTable,
        moveDataTableLocation,
        deleteShortcut,
    ]);

    return <div className="py-2 h-full w-full overflow-y-auto">
        {(hasData || !searchValue) && (
            <TreeView
                size="xs"
                data={hasData ? data : searchValue ? [] : EMPTY_EXPLORER_DATA}
                onNodeClick={onNodeClick}
                onContextMenu={onContextMenu}
                searchTerm={searchValue}
                nodeMoveTargetFilter={(e) => e.type === 'folder'}
                hasCheckboxes={false}
                value={selectedFolder?.id}
                labelProps={{
                    color: 'neutral-900',
                    fontWeight: 'normal',
                    className: '!overflow-hidden',
                }}
            />
        )}

        {!hasData && searchValue && !isLoading && (
            <Label
                text={strings('no_results_found')}
                size="sm"
                className="mt-5 ml-4 overflow-hidden"
                color="neutral-600"
            />
        )}

        <ExplorerLoadingComponent
            className="h-screen"
            condition={!data || isLoading}
        />
    </div>;
}
