import {Cell, Row} from '@tanstack/react-table';

import IntegerCellView from '@/components/Fields/Integer/Integer.Cell.View';

export const CellDecimal = (columnDef) => {
    const typeObject = columnDef.type;

    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = Number(row.getValue(cell.column.id));

            return <IntegerCellView
                value={value}
                showThousandsSeparator={typeObject.showThousandsSeparator}
                numberType={typeObject.subtype}
                currencyType={typeObject.currencyType}
            />;
        },

    };
};
