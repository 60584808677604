
import validateCell from './ValidateCell.async';

const validateBulkCell = async (cells) => {
    const result = await Promise.all(cells.map(async (cell) => await validateCell(cell)));

    return result;
};

export default validateBulkCell;
