import {Button} from '@aktek/f4kit';
import cn from 'classnames';

import {strings} from '@/localization/i18n';

import {TImageColorSelectorValue} from '../ImageOrColorSelector.Modal';
import {getImageOrColorSelectorClasses} from '../styles/ImageOrColorSelector.styles';

type TImageOrColorSelectorFooterProps = {
    onCancel: () => void,
    onConfirm: () => void,
    onResetToDefault?: () => void,
    finalValue?: TImageColorSelectorValue,
    isButtonDisabled?: boolean,
    option: 'image' | 'color',
    isConfirmButtonLoading?: boolean,
}

const ImageOrColorSelectorFooter = (props: TImageOrColorSelectorFooterProps) => {
    const {
        onCancel,
        onConfirm,
        onResetToDefault,
        finalValue,
        isButtonDisabled,
        option,
        isConfirmButtonLoading,
    } = props;
    const classes = getImageOrColorSelectorClasses();
    const canResetToDefault = finalValue?.value !== null && finalValue?.type == option;

    return (
        <div className={classes.footerContainer}>

            <Button
                variant="white"
                label={strings('reset_to_default')}
                isDisabled={!canResetToDefault}
                className={cn(`!border-none`, {'!text-neutral-300 hover:bg-transparent': !canResetToDefault})}
                onClick={onResetToDefault}
            />

            <div className={classes.buttonContainer}>
                <Button
                    label={strings('cancel')}
                    variant="white"
                    className="w-1/2 !border-none"
                    onClick={onCancel}
                />
                <Button
                    label={strings('confirm')}
                    className="w-1/2  shadow-sm"
                    isLoading={isConfirmButtonLoading}
                    onClick={onConfirm}
                    isDisabled={isButtonDisabled}
                />
            </div>
        </div>
    );
};

export default ImageOrColorSelectorFooter;
