import {F4FormElementDecorator, useF4FormContext} from '@aktek/f4form';
import {Button} from '@aktek/f4kit';
import {AvatarLabelGroup} from '@aktek/f4kit';
import {UserService} from '@aktek/helios-frontend-sdk';
import {XSDKResponsePromise} from '@aktek/types';
import cn from 'classnames';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import CreateUserModal from '@/app/Admin/AccessControl/Users/components/Users.CreateUserModal';
import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForCreationModal from '@/asks/AskForCreatableModal';
import SearchBox from '@/components/SearchBox/SearchBox';
import Env from '@/config/env';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {GetNameInitials} from '@/helpers/GetNameInitial.fn';
import {strings} from '@/localization/i18n';

type TUserSelectorProps = {
    value?: unknown,
    onChange?: (value) => void;
    className?: string;
    groupId?: string;
    getFunction?: (groupId: string) => XSDKResponsePromise<unknown>
    state?:unknown
    readOnly?:boolean
}

const UserSelector = ({value = [], onChange, className, getFunction, groupId,
    state: initialValue=[], readOnly = false} : TUserSelectorProps) => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState(null);
    const {users: usersPermissions} = useAdminPermissions();
    const f4FormContext = useF4FormContext();

    // Log when value changes
    useEffect(() => {
    }, [value]);

    useEffect(() => {
        if (!usersPermissions?.can_view) return;
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const res = await UserService.getAllUsers();

        if (res.isSuccessful()) {
            const data = res.getData() as object[];
            setUsers(data);
        }
    };

    // Helper function to extract user IDs from value (which can be array or object)
    const getUserIds = (val) => {
        if (!val) return [];
        if (Array.isArray(val)) return val;
        if (val && typeof val === 'object' && 'users' in val && Array.isArray(val.users)) return val.users;

        return [];
    };

    // Helper function to check if a user is in the current selection
    const isUserSelected = (userId, selectionValue = value) => {
        if (!userId) return false;
        const userIds = getUserIds(selectionValue);

        return userIds.includes(userId);
    };

    const filteredUsers = useMemo(() => {
        // Make sure we're working with the actual users array, not the IDs
        return _.chain(users)
            .filter((user) => {
                const userSearchTerm = user.name?.toLowerCase()?.includes(search);
                const userEmailSearchTerm = user.email?.toLowerCase()?.includes(search);

                return !search || userSearchTerm || userEmailSearchTerm;
            })
            // Don't sort selected users to the top during regular selection
            // Sort will only happen initially with the initialValue
            .sortBy((user) => !isUserSelected(user._id, initialValue))
            .value();
    }, [users, search, initialValue]);

    const resetUsers = () => {
        // If value is an object with users property, update it accordingly
        if (value && typeof value === 'object' && 'users' in value) {
            const initialUserIds = getUserIds(initialValue);
            onChange({
                ...value,
                users: initialUserIds,
            });
        } else {
            // Otherwise just reset to initial value
            onChange(initialValue);
        }
    };

    return (
        <div className={`${className}`}>
            <div className="flex items-center gap-2 py-4 cursor-pointer -mt-7
                justify-between w-full h-full sticky -top-4 elevation-5 !shadow-none bg-white" >
                <SearchBox
                    placeholder={strings('search_users')}
                    className="!bg-neutral-100 rounded-lg w-full -ml-1"
                    textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    hasBottomBorder={false}
                    value={search}
                    autoFocusOnOpen={false}
                    onSearch={(value) => setSearch(value.toLowerCase())}
                />

                {!readOnly && <div className="flex flex-row items-end justify-end gap-2  w-fit ">

                    {usersPermissions?.can_create && <Button
                        label={strings('add_user')}
                        textColor="neutral-700"
                        variant="white"
                        onClick={() => AskForCreationModal(CreateUserModal, {onSave: async (createdUser: any) => {
                            // Refresh the users list to include the newly created user
                            await fetchUsers();

                            if (!getFunction) return;

                            // Add a small delay to ensure backend has processed the new user
                            await new Promise((resolve) => setTimeout(resolve, 500));

                            // Get the updated list of selected users for the current role
                            const res = await getFunction(groupId);
                            let selectedUsers = res.getData();

                            // If the backend doesn't return the newly created user as selected,
                            // we need to manually add it to the selection
                            if (createdUser && createdUser._id) {
                                // Check if the user is already in the selection
                                const userIds = getUserIds(selectedUsers);

                                if (!userIds.includes(createdUser._id)) {
                                    // If value is an object with users property
                                    if (selectedUsers && typeof selectedUsers === 'object' && 'users' in selectedUsers) {
                                        selectedUsers = {
                                            ...selectedUsers,
                                            users: Array.isArray(selectedUsers.users)
                                                ? [...selectedUsers.users, createdUser._id]
                                                : [createdUser._id],
                                        };
                                    } else if (Array.isArray(selectedUsers)) {
                                        // If value is an array
                                        selectedUsers = [...selectedUsers, createdUser._id];
                                    } else {
                                        // If value is something else, create a new array
                                        selectedUsers = [createdUser._id];
                                    }
                                }
                            }

                            // Update the form value with the new selection
                            f4FormContext.setValue('users' as never, selectedUsers);
                        }, state: {mode: 'create', _id: null}})}
                    />}

                    <Button
                        label={strings('reset_users')}
                        textColor="neutral-700"
                        variant="white"
                        isDisabled={_.isEqual(getUserIds(initialValue).sort(), getUserIds(value).sort())}
                        className={`ml-1 !px-5 ${_.isEqual(getUserIds(initialValue).sort(), getUserIds(value).sort()) && '!bg-neutral-200'}`}
                        onClick={async () =>{
                            const isConfirmed = await AskForConfirmation(
                                strings('reset_users'),
                                strings('reset_users_confirmation') );

                            if (!isConfirmed) return;

                            resetUsers();
                        }}
                    />
                </div>}
            </div>

            <div className="overflow-y-auto pt-4" >
                {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => {
                        // Check if the user is selected
                        const isSelected = isUserSelected(user._id);
                        const avatarAltLabel = user.name && !user.profilePicURL ? GetNameInitials(user.name) : null;
                        const img = user.profilePicURL ? {
                            src: Env.getFileURL(user.profilePicURL),
                            alt: 'Profile Picture',
                        }: null;

                        return (
                            <div
                                onClick={() => {
                                    if (readOnly) {
                                        return;
                                    }

                                    // Get current user IDs
                                    const userIds = getUserIds(value);
                                    let mutableArray = [...userIds];

                                    if (isSelected) {
                                        // Remove user from selection
                                        mutableArray = mutableArray.filter((id) => id !== user._id);
                                    } else {
                                        // Add user to selection
                                        mutableArray.push(user._id);
                                    }

                                    // Update the value based on its type
                                    if (value && typeof value === 'object' && 'users' in value) {
                                        // If value is an object with users property
                                        onChange({
                                            ...value,
                                            users: mutableArray,
                                        });
                                    } else {
                                        // If value is an array or something else
                                        onChange(mutableArray);
                                    }
                                }}
                                key={user._id}
                                className={`flex flex-col items-center `}
                            >
                                <AvatarLabelGroup
                                    key={user._id}
                                    isDisabled={readOnly}
                                    size="sm"
                                    label={{text: user.name}}
                                    subLabel={{text: user.email}}
                                    avatar={{
                                        img: img,
                                        avatarAltLabel: avatarAltLabel,
                                        avatarClassName: cn(
                                            {
                                                'border-none': avatarAltLabel && !isSelected,
                                            },
                                        ),
                                    }}
                                    value={isSelected}
                                    className="mb-2"
                                />
                            </div>
                        );
                    })
                ) : <div className="px-2 py-4 text-center text-neutral-600">{strings('no_results_found')}</div>
                }
            </div>
        </div>
    );
};

const F4UserSelector = F4FormElementDecorator<TUserSelectorProps>(UserSelector);
export default F4UserSelector;
