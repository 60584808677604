type TValidationResult = {
    isValid: boolean;
    messages?: string[];
}
export type TFieldType = 'integer' | 'float';

const integerPattern = /^[+-]?\d+$|^[+-]$/;
const decimalPattern = /^[+-]?\d+[.,]?\d*$|^[+-]?\d+$|^[+-]$|^[.,]\d+$/;

// Helper function to clean up number values
const cleanNumberValue = (value: string | number): string => {
    if (typeof value !== 'string') return String(value);

    // Remove trailing decimal point
    return value.endsWith('.') ? value.slice(0, -1) : value;
};

export function validateMin(minimum?: number | string | null, maximum?: number | string | null, fieldType: TFieldType = 'integer', allowNegative: boolean = true): TValidationResult {
    // Special case: if it's just a '+' or '-' sign, consider it valid
    if (typeof minimum === 'string' && (minimum === '+' || minimum === '-')) {
        return {isValid: true};
    }

    // Convert strings to numbers (if they exist)
    const minNum = minimum !== undefined && minimum !== null && minimum !== ''
        ? Number(cleanNumberValue(minimum))
        : undefined;

    if (minNum !== undefined) {
        // Add negative number validation
        if (!allowNegative && minNum < 0) {
            return {
                isValid: false,
                messages: ['Negative numbers are not allowed'],
            };
        }

        // Integer Regular validation
        if (fieldType === 'integer') {
            if (minimum !== undefined && minimum !== null && minimum !== '') {
                // Check if the string contains a comma - we'll accept it for validation
                // (conversion to dots happens during save)
                let testValue = typeof minimum === 'string' ? minimum.replace(',', '.') : String(minimum);

                // For values starting with decimal point, prepend a 0 for validation but keep original for display
                if (testValue.startsWith('.')) {
                    testValue = '0' + testValue;
                }

                if (typeof minimum === 'string' && !integerPattern.test(testValue)) {
                    return {
                        isValid: false,
                        messages: ['Only integer number is accepted'],
                    };
                }
            }
        }

        // Decimal validation
        if (fieldType === 'float' ) {
            if (minNum !== null) {
                // Check if the string contains a comma - we'll accept it for validation
                // (conversion to dots happens during save)
                let testValue = typeof minimum === 'string' ? minimum.replace(',', '.') : String(minimum);

                // For values starting with decimal point, prepend a 0 for validation but keep original for display
                if (testValue.startsWith('.')) {
                    testValue = '0' + testValue;
                }

                if (typeof minimum === 'string' && !decimalPattern.test(testValue)) {
                    return {
                        isValid: false,
                        messages: ['Only decimal number is accepted'],
                    };
                }
            }
        }
    }

    return {isValid: true};
}

export function validateMax(minimum?: number | string | null, maximum?: number | string | null, fieldType: TFieldType = 'integer', allowNegative: boolean = true): TValidationResult {
    // Special case: if it's just a '+' or '-' sign, consider it valid
    if (typeof maximum === 'string' && (maximum === '+' || maximum === '-')) {
        return {isValid: true};
    }

    // Convert strings to numbers (if they exist)
    const minNum = minimum !== undefined && minimum !== null && minimum !== ''
        ? Number(cleanNumberValue(minimum))
        : undefined;
    const maxNum = maximum !== undefined && maximum !== null && maximum !== ''
        ? Number(cleanNumberValue(maximum))
        : undefined;

    // Add negative number validation for maxNum
    if (maxNum !== null && !allowNegative && maxNum < 0) {
        return {
            isValid: false,
            messages: ['Negative numbers are not allowed'],
        };
    }

    // Integer Regular validation
    if (fieldType === 'integer') {
        if (maximum !== undefined && maximum !== null && maximum !== '') {
            // Check if the string contains a comma - we'll accept it for validation
            // (conversion to dots happens during save)
            let testValue = typeof maximum === 'string' ? maximum.replace(',', '.') : String(maximum);

            // For values starting with decimal point, prepend a 0 for validation but keep original for display
            if (testValue.startsWith('.')) {
                testValue = '0' + testValue;
            }

            if (typeof maximum === 'string' && !integerPattern.test(testValue)) {
                return {
                    isValid: false,
                    messages: ['Only integer number is accepted'],
                };
            }
        }
    }

    // Decimal validation
    if (fieldType === 'float' ) {
        if (maxNum !== null) {
            // Check if the string contains a comma - we'll accept it for validation
            // (conversion to dots happens during save)
            let testValue = typeof maximum === 'string' ? maximum.replace(',', '.') : String(maximum);

            // For values starting with decimal point, prepend a 0 for validation but keep original for display
            if (testValue.startsWith('.')) {
                testValue = '0' + testValue;
            }

            if (typeof maximum === 'string' && !decimalPattern.test(testValue)) {
                return {
                    isValid: false,
                    messages: ['Only decimal number is accepted'],
                };
            }
        }
    }

    // Min/Max comparison (only if both values exist)
    if (minNum !== null && maxNum !== null && minNum > maxNum) {
        return {
            isValid: false,
            messages: ['Maximum should be higher than the minimum'],
        };
    }

    return {isValid: true};
}

