import {Breadcrumb, Button, Empty, Label, TBreadcrumbStep} from '@aktek/f4kit';
import {faPlus, faRefresh, IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import AskForCreationModal from '@/asks/AskForCreatableModal';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import {TExplorerUIState} from '../hooks/useExplorerUI';
import FolderCreateModal from '../modals/Folders.Create.Modal';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

type TEmptyComponentProps = {
        label:string,
         description: string,
         parentId?:string,
         icon?:IconDefinition,
         explorerUIState:TExplorerUIState,
         hasCreateButton?:boolean,
         showBreadcrumb?: boolean,
         rightPanelViewLabel?: string | boolean
        }

const EmptyComponent = (props: TEmptyComponentProps) => {
    const {
        label,
        description,
        parentId,
        icon,
        explorerUIState,
        hasCreateButton = true,
        showBreadcrumb = false,
        rightPanelViewLabel,
    } = props;
    const {folders: foldersPermissions} = useAdminPermissions();
    const {isLoading, refresh} = useGlobalFilesAndFolders();

    const {ancestors, setSelectedFolder} = explorerUIState;

    const ACTIONS_ELEMENTS = [
        hasCreateButton && foldersPermissions?.can_create && <Button
            key={1}
            variant="neutral"
            isGhost={false}
            isOutlined={true}
            isCircle={false}
            edgeIcons={{left: {icon: faPlus, color: 'neutral-600'}}}
            label={strings('new_folder')}
            onClick={() => AskForCreationModal(FolderCreateModal, {state: {mode: 'create', _id: null}, onSave: refresh, parentId})}
            size={'sm'} />,
        <Button
            key={2}
            variant="primary"
            isGhost={false}
            isOutlined={false}
            isCircle={false}
            label={strings('refresh')}
            onClick={refresh}
            size={'sm'}
            icon={faRefresh}
        />];

    return (
        <>
            {showBreadcrumb && (
                <div className="pl-[0.06rem]">

                    <Breadcrumb
                        className="!bg-neutral-50 !pt-[1.15rem] "
                        buttonProps={{className: '!pl-1.5'}}
                        separatorIconProps={{className: '!w-3.5 !h-3.5 pr-3'}}
                        size="sm"
                        onStepClick={setSelectedFolder}
                        path={ancestors as TBreadcrumbStep[]}
                    />
                </div>
            )}

            {rightPanelViewLabel && (
                <div className="!bg-neutral-50 p-4 pt-6 " >
                    <Label
                        text={rightPanelViewLabel as string}
                        color="primary-700"
                        size="sm"
                        fontWeight="semibold"
                        className="p-2"
                    />
                </div>
            )}

            <div className="flex justify-center bg-neutral-50 w-full h-full border-l border-neutral-200">
                <Empty
                    label={label}
                    size="lg"
                    icon={icon}
                    titleClassName="!text-base"
                    descriptionClassName="!text-sm"
                    className="max-w-lg mt-40 flex items-center justify-start"
                    description={description}
                    descriptionColor="neutral-600"
                    actionElements={_compact(ACTIONS_ELEMENTS)}
                />

                <ExplorerLoadingComponent condition={isLoading} />
            </div>
        </>
    );
};

export default EmptyComponent;

