import {Label} from '@aktek/f4kit';
import {useMemo} from 'react';

import TagsRecordEdit from '@/components/Fields/Tags/Tags.Record.Edit';
import {strings} from '@/localization/i18n';

type TRecordTagsProps = {
    hasRecordsTags: boolean;
    isRequired: boolean;
    isViewMode: boolean;
};

const RecordTags = (props: TRecordTagsProps) => {
    const {hasRecordsTags, isRequired, isViewMode} = props;

    if (!hasRecordsTags) return null;

    const name = useMemo(() => {
        if (isRequired) {
            return `${strings('tags')}*`;
        }

        return strings('tags');
    }, [isRequired]);

    return (
        <div className="flex flex-row gap-6 justify-between items-center my-6">

            <div className="flex flex-row gap-1 items-start w-1/4" >
                <Label text={name} fontWeight="medium" color="neutral-600" />
            </div>

            <div className="w-3/4">
                <TagsRecordEdit name="tags.tags" required={isRequired} isDisabled={isViewMode} />
            </div>
        </div>
    );
};

export default RecordTags;
