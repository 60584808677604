
import {LocalStorageKeys} from '@/config/constants';
import {TCompany} from '@/types/Company';
import {TCompanySettings} from '@/types/CompanySettings';
import {DrawerState} from '@/types/DrawerState';
import {TPermissions} from '@/types/Permissions.t';
import {PrimaryCompany} from '@/types/PrimaryCompany';
import {TUser} from '@/types/User.t';

import {clearCloudFrontCookies} from './Cookies';
import LocalStorageHelper from './LocalStorage.helper';

class LS {

    getUserData() {
        return {
            auth_token: this.token,
            currentUser: this.user,
            primaryCompany: this.primaryCompany,
            currentCompany: this.currentCompany,
            userCompanies: this.userCompanies || [],
            companySettings: this.companySettings,
        };
    }

    setExplorerSectionStatus(key, value) {
        const values = LocalStorageHelper.get(LocalStorageKeys.EXPLORER_SECTIONS_UI) as object || {};

        LocalStorageHelper.set(LocalStorageKeys.EXPLORER_SECTIONS_UI, {...values, [key]: value});
    }

    openExplorerSection(key) {
        this.setExplorerSectionStatus(key, true);
    }

    closeExplorerSection(key) {
        this.setExplorerSectionStatus(key, false);
    }

    toggleExplorerSection(key) {
        this.setExplorerSectionStatus(key, !this.isExplorerSectionsOpen(key));
    }

    doNotShowThisPopupAgain(key) {
        const values = LocalStorageHelper.get(LocalStorageKeys.DO_NOT_SHOW_THIS_AGAIN) as object || {};

        LocalStorageHelper.set(LocalStorageKeys.DO_NOT_SHOW_THIS_AGAIN, {...values, [key]: true});
    }

    showPopup(key) {
        const values = LocalStorageHelper.get(LocalStorageKeys.DO_NOT_SHOW_THIS_AGAIN) as object || {};

        return !values[key];
    }

    get theme() {
        return LocalStorageHelper.get(LocalStorageKeys.THEME) as string;
    }

    set theme(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.THEME, value);
    }

    get companyLogo() {
        return LocalStorageHelper.get(LocalStorageKeys.COMPANY_LOGO) as string;
    }

    set companyLogo(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.COMPANY_LOGO, value);
    }

    get loginSideImage() {
        return LocalStorageHelper.get(LocalStorageKeys.LOGIN_SIDE_IMAGE) as string;
    }

    set loginSideImage(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.LOGIN_SIDE_IMAGE, value);
    }

    get token() {
        return LocalStorageHelper.get(LocalStorageKeys.TOKEN) as string;
    }

    set token(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.TOKEN, value);
    }

    get currentCompany() {
        return LocalStorageHelper.get(LocalStorageKeys.CURRENT_COMPANY) as TCompany;
    }

    set currentCompany(value: TCompany) {
        LocalStorageHelper.set(LocalStorageKeys.CURRENT_COMPANY, value);
    }

    get companySettings() {
        return LocalStorageHelper.get(LocalStorageKeys.COMPANY_SETTINGS) as TCompanySettings;
    }

    set companySettings(value: TCompanySettings) {
        LocalStorageHelper.set(LocalStorageKeys.COMPANY_SETTINGS, value);
    }

    get userCompanies() {
        return LocalStorageHelper.get(LocalStorageKeys.USER_COMPANIES) as object[];
    }

    set userCompanies(value:object[]) {
        LocalStorageHelper.set(LocalStorageKeys.USER_COMPANIES, value);
    }

    get userPermission() {
        return LocalStorageHelper.get(LocalStorageKeys.USER_PERMISSION) as TPermissions;
    }

    set userPermission(value: TPermissions) {
        LocalStorageHelper.set(LocalStorageKeys.USER_PERMISSION, value);
    }

    get primaryCompany() {
        return LocalStorageHelper.get(LocalStorageKeys.PRIMARY_COMPANY) as PrimaryCompany;
    }

    set primaryCompany(value:PrimaryCompany) {
        LocalStorageHelper.set(LocalStorageKeys.PRIMARY_COMPANY, value);
    }

    get user() {
        return LocalStorageHelper.get(LocalStorageKeys.CURRENT_USER) as TUser;
    }

    set user(user: TUser) {
        LocalStorageHelper.set(LocalStorageKeys.CURRENT_USER, user);
    }

    get isLoggedIn() {
        // @TODO: perhaps we need to adjust this
        return !!this.token;
    }

    get tabsSchema() {
        return LocalStorageHelper.get(LocalStorageKeys.TAB_SCHEMA);
    }

    set tabsSchema(tabsSchema) {
        LocalStorageHelper.set(LocalStorageKeys.TAB_SCHEMA, tabsSchema);
    }

    get permissionSection() {
        return LocalStorageHelper.get(LocalStorageKeys.PERMISSION_SECTION) || null;
    }

    set permissionSection(value) {
        LocalStorageHelper.set(LocalStorageKeys.PERMISSION_SECTION, value);
    }

    get recentlyUsedSBO() {
        return LocalStorageHelper.get(LocalStorageKeys.RECENTLY_USED_SBO) || false;
    }

    set recentlyUsedSBO(value) {
        LocalStorageHelper.set(LocalStorageKeys.RECENTLY_USED_SBO, value);
    }

    get drawer() {
        return LocalStorageHelper.get(LocalStorageKeys.DRAWER) as DrawerState;
    }

    set drawer(value) {
        LocalStorageHelper.set(LocalStorageKeys.DRAWER, value);
    }

    isExplorerSectionsOpen(key) {
        const explorerStatus = LocalStorageHelper.get(LocalStorageKeys.EXPLORER_SECTIONS_UI);

        return explorerStatus?.[key];
    }

    signIn(data) {
        this.token = data.auth_token;
        this.user = data.currentUser;
        this.primaryCompany = data.primaryCompany;
        this.currentCompany = data.currentCompany;
        this.userCompanies = data.userCompanies || [];
        this.companySettings = data.companySettings;
        this.userPermission = null;
    }

    signOut() {
        const keysToClear = [
            LocalStorageKeys.TOKEN,
            LocalStorageKeys.TAB_SCHEMA,
            LocalStorageKeys.TABS_PROPS,
            LocalStorageKeys.GROUP_BACKGROUND,
            LocalStorageKeys.LANGUAGE,
            LocalStorageKeys.CURRENT_USER,
            LocalStorageKeys.PRIMARY_COMPANY,
            LocalStorageKeys.CURRENT_COMPANY,
            LocalStorageKeys.COMPANY_SETTINGS,
            LocalStorageKeys.USER_COMPANIES,
            LocalStorageKeys.USER_PERMISSION,
            LocalStorageKeys.RECENTLY_USED_SBO,

        ];
        // clearCloudFrontCookies();

        keysToClear.forEach((key) => LocalStorageHelper.remove(key));
    }

}

const ls = new LS();

export type lsType = typeof ls;

export default ls;
