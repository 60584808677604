import cn from 'classnames';

const container = (image, className, hasWarning) => cn(
    'flex flex-col flex-wrap justify-center items-center relative',
    ' gap-2 border rounded-xl border-neutral-200 w-full h-full',
    !image && 'py-6',
    className,
    hasWarning && '!max-h-64',
);
const uploadButton = (isLoading) => cn('rounded-full', isLoading && 'bg-primary-300', !isLoading && 'bg-primary-25');
const labelWrapper = 'flex flex-row gap-1';
const label = 'flex pointer-events-none';
const description = 'flex text-center pointer-events-none';
const imageContainer = (hasWarning) => cn('rounded-lg !w-full !h-full !object-cover ', hasWarning && '!max-h-64');
const fileInput = 'hidden';
const absoluteContainer = 'absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-500 rounded-lg cursor-pointer';
const absoluteContainerContent = 'flex flex-col flex-wrap justify-center items-center gap-2';

export const getImageOrColorSelectorImageUploaderClasses = (image, className?: string, isLoading?: boolean, hasWarning?: boolean) => ({
    container: container(image, className, hasWarning),
    uploadButton: uploadButton(isLoading),
    labelWrapper,
    label,
    description,
    imageContainer: imageContainer(hasWarning),
    fileInput,
    absoluteContainer,
    absoluteContainerContent,
    containerContent: absoluteContainerContent,
});

export default getImageOrColorSelectorImageUploaderClasses;
