import {TUpdateObject} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetMoveFieldUpdateObject(
    data: XSBO,
    fieldId: string,
    fromSectionId: string,
    toSectionId: string,
    toIndex?: number,
): TUpdateObject<XSBO> {
    const updateObject: TUpdateObject<XSBO> = {};

    // If moving within the same section
    if (fromSectionId === toSectionId) {
        const currentFields = [...data.sectionsMap[fromSectionId].fields];
        const fromIndex = currentFields.indexOf(fieldId);

        if (fromIndex === -1) return null;

        // Remove from current position and insert at new position
        currentFields.splice(fromIndex, 1);

        if (toIndex !== undefined) {
            currentFields.splice(toIndex, 0, fieldId);
        } else {
            currentFields.push(fieldId);
        }

        updateObject[`sectionsMap.${fromSectionId}.fields`] = currentFields;
    } else {
        // Moving between different sections
        const fromFields = [...data.sectionsMap[fromSectionId].fields];
        const toFields = [...data.sectionsMap[toSectionId].fields];

        // Remove from source section
        const fromIndex = fromFields.indexOf(fieldId);

        if (fromIndex !== -1) {
            fromFields.splice(fromIndex, 1);
            updateObject[`sectionsMap.${fromSectionId}.fields`] = fromFields;
        }

        // Add to target section
        if (toIndex !== undefined) {
            toFields.splice(toIndex, 0, fieldId);
        } else {
            toFields.push(fieldId);
        }

        updateObject[`sectionsMap.${toSectionId}.fields`] = toFields;
        updateObject[`fieldsMap.${fieldId}.parentSection`] = toSectionId;
    }

    // Always update table fields to maintain global order
    const newTableFields: string[] = [];
    const visibleFields = new Set(data.tableFields);

    // If the moved field was visible or is being moved to a section with visible fields,
    // we need to update the table fields
    if (visibleFields.has(fieldId)
        || data.sectionsMap[toSectionId].fields.some((f) => visibleFields.has(f))) {
        // Go through all sections in order
        for (const sectionId of data.sections) {
            const section = data.sectionsMap[sectionId];
            const sectionFields = sectionId === toSectionId
                ? updateObject[`sectionsMap.${sectionId}.fields`] || section.fields
                : sectionId === fromSectionId
                    ? updateObject[`sectionsMap.${sectionId}.fields`] || section.fields
                    : section.fields;

            // Add all visible fields from this section in their correct order
            for (const currentFieldId of sectionFields) {
                if (visibleFields.has(currentFieldId)) {
                    newTableFields.push(currentFieldId);
                }
            }
        }

        updateObject['tableFields'] = newTableFields;
    }

    return updateObject;
}
