import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

import {TField} from '../../types/SBOEditor.t';
import GenerateId from '../GenerateID.fn';

export default function GetAddFieldUpdateObject(data: XSBO, field: Partial<TField>, sectionId: string) {
    const fieldId = GenerateId();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {label, color, icon, ...fieldWithoutUnnecessaryProps} = field;
    const newField = {parentSection: sectionId, ...fieldWithoutUnnecessaryProps, id: fieldId};
    newField.name = field.id ? `${field.name || ''}` : '';

    const updateObject: TUpdateObject<XSBO> = {
        [`fieldsMap.${fieldId}`]: newField,
    };

    if (data.sectionsMap[sectionId].fields?.includes(field.id)) {
        const index = data.sectionsMap[sectionId].fields.indexOf(field.id);
        const newSectionFields = [
            ...data.sectionsMap[sectionId].fields.slice(0, index + 1),
            fieldId,
            ...data.sectionsMap[sectionId].fields.slice(index + 1),
        ];
        updateObject[`sectionsMap.${sectionId}.fields`] = newSectionFields;
    } else {
        updateObject[`sectionsMap.${sectionId}.fields`] = {[UpdateActions.APPEND]: fieldId};
    }

    if (data.tableFields?.includes(field.id)) {
        const index = data.tableFields.indexOf(field.id);
        const newTableFields = [
            ...data.tableFields.slice(0, index + 1),
            fieldId,
            ...data.tableFields.slice(index + 1),
        ];
        updateObject['tableFields'] = newTableFields;
    } else {
        const newTableFields: string[] = [];
        const existingFields = new Set(data.tableFields || []);

        const sections = data.sections || [];

        for (const currentSectionId of sections) {
            const section = data.sectionsMap[currentSectionId];
            const sectionFields = section?.fields || [];

            for (const currentFieldId of sectionFields) {
                if (existingFields.has(currentFieldId)) {
                    newTableFields.push(currentFieldId);
                }

                if (currentSectionId === sectionId && currentFieldId === sectionFields[sectionFields.length - 1]) {
                    newTableFields.push(fieldId);
                }
            }

            if (currentSectionId === sectionId && (!section?.fields || section.fields.length === 0)) {
                newTableFields.push(fieldId);
            }
        }

        updateObject['tableFields'] = newTableFields;
    }

    return {updateObject, fieldId};
}

