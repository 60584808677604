import {TSize} from '@aktek/f4kit';
import cn from 'classnames';

const modal = (className: string) => cn('overflow-hidden !rounded-3xl px-6 pb-6 pt-1 w-[30rem] bg-white', className);
const container = 'flex flex-col ';
const header = 'hidden';

const headerContainer = 'flex items-center justify-between w-full gap-4';
const bodyContainer = 'h-[20rem] cursor-pointer';
const bodyContainerLoading = 'h-[20rem] flex flex-col items-center justify-center';
const footerContainer = 'flex flex-row justify-between items-center mt-6 w-full gap-4';
const buttonContainer = 'flex flex-row gap-3';

const getChromeClasses = (size?:TSize) => cn(
    '!rounded !bg-white !border-0 !h-full',
    size === 'xs' && '!w-48',
    size === 'sm' && '!w-56',
    size === 'md' && '!w-64',
    size === 'lg' && '!w-72',
    size === 'xl' && '!w-72',
    size === '2xl' && '!w-80',
    size === '3xl' && '!w-88',
    size === '4xl' && '!w-[38rem]',
    '!shadow-none !cursor-pointer',
    '[&>:first-child]:hidden [&>*]:!rounded',
);

const buttonClassName = (isSelected: boolean) => cn('!ml-2 !text-sm self-start',
    !isSelected && '!text-neutral-500 hover:!bg-neutral-100',
    isSelected && 'bg-primary-25',
);

export const getImageOrColorSelectorClasses = (className?: string) => ({
    modal: modal(className),
    container,
    header,
    headerContainer,
    bodyContainer,
    bodyContainerLoading,
    footerContainer,
    buttonContainer,
    chrome: getChromeClasses,
    buttonClassName,
});

