import {useF4FormContext} from '@aktek/f4form';
import {TF4FormContextValue} from '@aktek/f4form';
import {Button} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

import {GetSingleRecordClasses} from '../styles/Record.Editor.styles';

type TRecordFooterProps = {
    onSave?: (context: TF4FormContextValue, closeTab?: boolean) => void;
    onSaveAndFillAnother?: (context: TF4FormContextValue) => void;
    onSwitchToBulkEntry?:() => void;
    onCancel?: () => void;
    isViewMode?: boolean;
    onEdit?: () => void;
    canEdit?: boolean;
}

const RecordFooter = (props: TRecordFooterProps) => {
    const f4FormContext = useF4FormContext();

    const {onSave, onSaveAndFillAnother, onCancel, onSwitchToBulkEntry, isViewMode, onEdit, canEdit} = props;
    const classes = GetSingleRecordClasses();

    if (isViewMode) {
        if (!canEdit) return null;

        return <div className={classes.footerContainer}>
            <Button
                size="sm"
                onClick={onEdit}
                className="capitalize ml-1"
                label={strings('edit')}/>
        </div>;
    }

    const handleCancelClick = () => onCancel();
    const handleSwitchToBulkEntry = () => onSwitchToBulkEntry();
    const handleSaveAndFillAnotherClick = () => onSaveAndFillAnother(f4FormContext);
    const handleSaveClick = () => onSave(f4FormContext, true);

    return (
        <div className={classes.footerContainer}>
            <Button
                isGhost
                size="sm"
                className="capitalize ml-1"
                onClick={handleCancelClick}
                textColor="neutral-600"
                label={strings('cancel')}
            />
            <Button
                size="sm"
                className="capitalize ml-1"
                textColor="neutral-700"
                variant="white"
                onClick={handleSwitchToBulkEntry}
                label={strings('switch_to_bulk_entry')}
            />
            <Button
                size="sm"
                className="capitalize ml-1"
                textColor="neutral-700"
                onClick={handleSaveAndFillAnotherClick}
                variant="white"
                label={strings('save_and_fill_another')}
            />
            <Button
                size="sm"
                onClick={handleSaveClick}
                className="capitalize ml-1"
                label={strings('save')}
            />
        </div>
    );
};

export default RecordFooter;
