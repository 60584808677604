import {F4FormElementDecorator} from '@aktek/f4form';
import {TextBox, TTextBoxProps} from '@aktek/f4kit';
import {TF4FormElementProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4FormElementProps.t';

import {TExtraF4FormElementProps} from '@/context/Global.Provider';
import {strings} from '@/localization/i18n';
 type TTextBoxCombinedProps = TTextBoxProps & TF4FormElementProps<TExtraF4FormElementProps> & {f4FormDivClassName?:string}

const F4Textbox = F4FormElementDecorator<TTextBoxCombinedProps>(TextBox, (value, props)=>{
    const messages = [];
    const strValue = String(value);
    const numValue = typeof value === 'number' ? value : Number(value);

    if (value && !isNaN(numValue)) {
        if (props.min !== undefined && numValue < props.min) {
            messages.push(`${strings('value_cannot_be_less_than')} ${props.min}`);
        }

        if (props.max !== undefined && numValue > props.max) {
            messages.push(`${strings('value_cannot_be_greater_than')} ${props.max}`);
        }
    }

    // checking if required and if empty and if empty but with spaces
    if (props.required && strValue.length !== 0) {
        const trimmedValue = strValue.trim();

        if (trimmedValue?.length === 0 ) {
            messages.push(strings('please_enter_a_valid_name_spaces_are_not_allowed'));
        }
    }

    // Regex to validate email: no leading/trailing special chars (._%+-) and no consecutive special chars (e.g., "abc@domain.com", "a.b-c@domain.co").
    const emailRegex = /^(?![._%+-])[a-zA-Z0-9._%+-]+(?<![._%+-])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (props.type == 'email' && value && !emailRegex.test(strValue)) messages.push(strings('invalid_email'));

    return {isValid: messages.length === 0, messages};
});

export default F4Textbox;

