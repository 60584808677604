import {TSpreadsheetTypes} from '@aktek/f4kit';
import {XSmartFilter} from '@aktek/types';
import {TSpreadsheetProps} from 'node_modules/@aktek/f4kit/dist/components/TierOne/Spreadsheet/Types/SpreadsheetProps.t';
import React from 'react';

import {FetchRows as fetchRows} from '../api/FetchRows';
import {TSorting} from '../F4TableSpreadsheet';
import {getKey} from '../helpers/utils';

export type TMorphProps = {
    sboId: string;
    mode: string;
    sorting: TSorting;
    userFilter: XSmartFilter;
    pageLength: number;
};

export type TMorphState = {
    dataByKey: Record<string, TSpreadsheetProps['defaultData']>;
    count: number;
    isDataLoading: boolean;
    isDataUpdating: boolean;
    isDataDeleting: boolean;
};

export type TMorphReturn = TMorphState & {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    search: string | null;
    setSearch: (search: string | null) => void;
    key: string;
    data: unknown[] | undefined;
    isEmpty: boolean;
};

const useFetchMorphs = (props: TMorphProps): TMorphReturn => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [search, setSearch] = React.useState(null);
    const [morphState, setMorphState] = React.useState<TMorphState>({
        dataByKey: {},
        count: 0,
        isDataLoading: false,
        isDataUpdating: false,
        isDataDeleting: false,
    });

    const fetchMorphs = async (sboId: string) => {
        if (props.mode === 'creation') return;
        const page = currentPage;
        if (!sboId) return;
        const key = getKey(props.userFilter, search, page);

        setMorphState((state) => ({
            ...state,
            isDataLoading: true,
            dataByKey: {...state.dataByKey, [key]: []},
        }));

        const {data, count} = await fetchRows(sboId, props.sorting, props.userFilter, page, props.pageLength, search);
        setMorphState((state) => ({
            ...state,
            dataByKey: {...state.dataByKey, [key]: data},
            count,
            isDataLoading: false,
        }));
    };

    React.useEffect(() => {
        fetchMorphs(props.sboId);
    }, [props.userFilter, currentPage, props.pageLength, search, props.sorting?.column, props.sorting?.order]);

    const key = getKey(props.userFilter, search, currentPage);
    const data = morphState.dataByKey[key];
    const isEmpty = !data?.length;

    return {
        ...morphState,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
        key,
        data,
        isEmpty,
    };
};

export default useFetchMorphs;
