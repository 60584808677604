import {MorphService} from '@aktek/morph-frontend-sdk';
import {XSDKResponse} from '@aktek/types';

import {OnDeleteCallback} from '../types/TApis';

export const DeleteRows = async ( sboId: string, rowIds: string[], onDelete?: OnDeleteCallback): Promise<boolean> => {
    if (!rowIds.length) return true;

    const isSuccess = await _bulkDeleteMorph(sboId, rowIds.map((id) => Number(id)));
    onDelete?.(isSuccess);

    return isSuccess;
};

const _bulkDeleteMorph = async (sboId: string, morphIds: number[]): Promise<boolean> => {
    const res = await MorphService.bulkDeleteMorph(morphIds, sboId) as XSDKResponse<unknown>;

    return res.isSuccessful();
};
