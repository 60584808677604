import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

import {TSection} from '../../types/SBOEditor.t';
import GenerateId from '../GenerateID.fn';

export default function GetAddSectionUpdateObject(data: XSBO, section: TSection) {
    const sectionId = GenerateId();
    const newSection = {...section, id: sectionId, fields: []};
    newSection.name = section.fields?.length > 0 || section.name ? `${section.name || ''}` : 'Section ' + (data.sections.length + 1);

    const updateObject: TUpdateObject<XSBO> = {
        [`sectionsMap.${sectionId}`]: newSection,
        ['sections']: {[UpdateActions.APPEND]: sectionId},
    };

    // Find the index of the section we want to insert after
    const currentSectionIndex = data.sections.indexOf(section.id);
    let newSections: string[];

    if (section.id) {
        // Create new sections array with the new section inserted after the current one
        newSections = [
            ...data.sections.slice(0, currentSectionIndex + 1),
            sectionId,
            ...data.sections.slice(currentSectionIndex + 1),
        ];
    } else {
        newSections = [...data.sections, sectionId];
    }

    // Update the sections array
    updateObject['sections'] = newSections;

    // Create a new array with all current table fields
    const newTableFields: string[] = [];

    section.fields?.forEach((fieldId) => {
        const field = data.fieldsMap[fieldId];
        if (!field) return;
        const newFieldId = GenerateId();
        const newField = {...field, parentSection: sectionId, id: newFieldId};

        newSection.fields.push(newFieldId);
        updateObject[`fieldsMap.${newFieldId}`] = newField;

        // If original field was visible in table, collect its new ID
        if (data.tableFields.includes(fieldId)) {
            console.log('fieldId', fieldId);
            console.log('newField.id', newField.id);
            newTableFields.push(newField.id);
        }
    });

    // If we have fields to add to the table
    if (newTableFields.length > 0) {
        // Add all the new fields in order
        updateObject['tableFields'] = {
            [UpdateActions.APPEND]: newTableFields,
        };
    }

    return {updateObject, sectionId};
}

