import {Dropdown} from '@aktek/f4kit';

const ClosedListCellEdit = ({value, onChange, options}) => {
    return <Dropdown isClearable openOnFocus
        tabIndex={-1}
        value={value}
        onChange={onChange}
        options={options}
    />;
};

export default ClosedListCellEdit;
