import {SBOService} from '@aktek/morph-frontend-sdk';

type TDataTablePermissions = {
    can_access: boolean;
    can_create: boolean;
    can_delete: boolean;
    can_edit: boolean;
    can_view: boolean;
}
type TGetColumns = {
    sboId: string
}

type TColumn = {
    key: 'string';
    header: 'string';
    type: 'string';
    isSortable: boolean;
}

type TGetDataTableColumns = ({sboId}:{sboId: string}) => Promise<{columns: TColumn[], permissions: TDataTablePermissions}>

export const GetDataTableColumns: TGetDataTableColumns = async ({sboId}: TGetColumns) => {
    const res = await SBOService.getSBOHeaders(sboId);
    const data = res.getData();

    if (!data) {
        return {columns: [], permissions: {
            can_access: false,
            can_create: false,
            can_delete: false,
            can_edit: false,
            can_view: false,
        }};
    }

    const items: TColumn[] = data?.headers || [];

    const columns = items.map((item) => {
        const {_id, title, type, ...rest} = item;
        const column: TColumn = {
            key: _id,
            header: title,
            type: type,
            isSortable: true,
            ...rest,
        };

        return column;
    });

    return {columns, permissions: data?.['permissions']};
};
