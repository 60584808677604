import {XField, XFieldMedia, XFieldNumber, XFieldRef} from '@aktek/types';

import {
    IColumn,
    IRow,
    TValidationResult,
    TValidator} from '../types/Validation.types';
import {isValidMedia} from './validations/isValidMedia';
import {isValidNumber} from './validations/isValidNumber';
import {isValidRef} from './validations/isValidRef';

const REQUIRED_VALIDATION_NUMBER_TYPES = new Set(['integer', 'float']);
const REQUIRED_VALIDATION_REF_TYPES = new Set(['ref', 'refList', 'connectedRef']);
const MEDIA_TYPES = new Set(['mediaImage', 'mediaVideo', 'mediaAlbum', 'mediaGeneric', 'uploadlink']);

export const validateCell = (field: XField): TValidator | null => {
    const isMedia = MEDIA_TYPES.has(field.type);

    let mediaLimitationsRequired = false;

    if (isMedia) {
        const _field = field as XFieldMedia;
        const hasLimits = _field.maximum !== undefined || _field.minimum !== undefined;
        mediaLimitationsRequired = hasLimits;
    }

    const isNumberField = REQUIRED_VALIDATION_NUMBER_TYPES.has(field.type);

    if (!field.isRequired && !isNumberField && !mediaLimitationsRequired) {
        // to need to add a validation for this
        return null;
    }

    const isRefField = REQUIRED_VALIDATION_REF_TYPES.has(field.type);

    return validateCellValue(mediaLimitationsRequired, field, isRefField, isNumberField);
};

const validateCellValue = (
    mediaLimitationsRequired: boolean,
    field: XField,
    isRefField: boolean,
    isNumberField: boolean,
) => (row: IRow, column: IColumn): TValidationResult => {
    const value = row[column.key];
    const isValueAvailable = !(value === '' || value === undefined || value === null);

    if (mediaLimitationsRequired) {
        return isValidMedia(value, field);
    }

    if (isRefField && field.isRequired) {
        const _field = field as XFieldRef;

        return isValidRef(value, _field.type);
    }

    if (!field.isRequired && !isValueAvailable) {
        return {isValid: true};
    }

    if (isNumberField && isValueAvailable) {
        const _field = field as XFieldNumber;

        return isValidNumber(value, _field.minimum, _field.maximum);
    }

    return {
        isValid: isValueAvailable,
        message: 'Field is required',
    };
};
