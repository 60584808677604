
import F4IOSelect from '@/components/F4Elements/F4IOSelect';

import {GetTagsClasses} from './styles/Tags.styles';

const TagsRecordEdit = ({name, onChange, isDisabled, ...rest}:{name: string, onChange?:(data) => void, isDisabled?:boolean, required?:boolean}) => {
    const classes = GetTagsClasses();

    return <F4IOSelect
        name={name}
        isMulti
        isDisabled={isDisabled}
        type="tags"
        onChange={(tags) => {
            onChange?.({
                joined: tags?.join?.('') || '',
                tags,
            });
        }}
        placeholder=""
        borderColor="neutral"
        hideArrow
        dropdownClassname={classes.dropdownClassname}
        {...rest}
    />;
};

export default TagsRecordEdit;
