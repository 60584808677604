import {TabNav} from '@aktek/f4kit';
import _ from 'lodash';

import {strings} from '@/localization/i18n';

const BLACK_LIST_SECTIONS = ['obac', 'resource'];

const PermissionSections = ({permissions, onChange, value}) => {
    let tabs = [];
    const defaultTabsArray = [
        {
            'key': 'admin',
            'label': 'Admin',
        },
        {
            'key': 'app',
            'label': 'App',
        },
        {
            'key': 'dashboard',
            'label': 'Dashboard',
        },
        {
            'key': 'report',
            'label': 'Data Table',
        },
    ];

    if (_.isEmpty(permissions)) {
        tabs = [...defaultTabsArray];
    }

    if (!_.isEmpty(permissions)) {
        Object.keys(permissions).forEach((section) => {
            if (BLACK_LIST_SECTIONS.includes(section)) return;
            tabs.push({key: section, label: strings(section) || ''});
        });
    }

    tabs.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <TabNav
            orientation="horizontal"
            tabs={tabs}
            buttonClassName="!ml-2"
            size="sm"
            value={value}
            defaultValue={value}
            onChange={onChange}
            className="overflow-x-visible !text-neutral-500 !font-semibold "
        />
    );
};

export default PermissionSections;
