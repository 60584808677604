import {Button, Modal, useModalRef} from '@aktek/f4kit';
import Atlas from '@aktek/ops-atlas';
import {forwardRef, useState} from 'react';

import {strings} from '@/localization/i18n';

import {GetMapPickerClasses} from '../styles/GetMapPickerClass.styles';

export const MapPickerComponent = ({initialLat, initialLng, onConfirm, onClose}, ref) => {
    const [coordinates, setCoordinates] = useState({
        lat: Number(initialLat) || 41.015137,
        lng: Number(initialLng) || 28.979530,
    });
    const modalRef = useModalRef(ref);
    const classes = GetMapPickerClasses();

    return (
        <div>
            <Modal ref={modalRef} hideHeader>
                <Atlas hasTimeline hideDrawing
                    width={'100%'}
                    height={500}
                    maxZoom={15}
                    strings={{}}
                    zoom={15}
                    center={[coordinates.lat, coordinates.lng]}
                    onMapClick={(latLng) => {
                        if (!latLng) return;
                        setCoordinates({...latLng});
                    }}
                />
                <div className={classes.footer}>
                    <Button
                        isGhost
                        size="sm"
                        className="capitalize ml-1"
                        onClick={() => {
                            onClose();
                        }}
                        textColor="neutral-600"
                        label={strings('cancel')}
                    />
                    <Button
                        size="sm"
                        onClick={() => {
                            onConfirm(coordinates);
                            onClose();
                        }}
                        className="capitalize ml-1"
                        label={strings('save')}
                    />
                </div>
            </Modal>
        </div>
    );
};

export const MapPicker = forwardRef(MapPickerComponent);
