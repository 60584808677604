import {TF4FormContextValue} from '@aktek/f4form';
import {TabsManager, toast} from '@aktek/f4kit';
import {MorphService, SBOService} from '@aktek/morph-frontend-sdk';
import {XSBO} from '@aktek/types';
import {faEye, faPen, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';

import {GetFormNumericString} from '@/components/Fields/Integer/helpers/Integer.GetFormNumericString.fn';
import UpdateSignalEmitter from '@/helpers/UpdateSignalEmitter';
import {strings} from '@/localization/i18n';

const useRecordEditor = (sboId: string, mode: 'edit' | 'create' | 'view', morphId: string, onSave: () => void, currentTab) => {
    const [loading, setLoading] = useState(false);
    const [sbo, setSBO] = useState<XSBO>({} as XSBO);
    const isReadOnlyMode = mode === 'edit' || mode === 'view';
    const [initialValue, setInitialValue] = useState<XSBO>({} as XSBO);
    const [editableFieldsIDs, setEditableFieldsIDs] = useState<string[]>(null);

    // updating the icon of the tab based on the mode
    useEffect(() => {
        if (mode === 'view') {
            currentTab.updateConfig({icon: faEye, title: `${strings('view')} ${sbo.name ?? ''} (${morphId ?? ''})`});
        }

        if (mode === 'edit') {
            currentTab.updateConfig({icon: faPen, title: `${strings('edit')} ${sbo.name ?? ''} (${morphId ?? ''})`});
        }

        if (mode === 'create') {
            currentTab.updateConfig({icon: faPlus, title: `${strings('add_to')} ${sbo.name ?? ''} `});
        }
    }, [mode, sbo.name]);

    useEffect(() => {
        if (isReadOnlyMode) {
            getViewingSBOById(sboId);

            return;
        }

        getCreationSBOById(sboId);
    }, []);

    const getCreationSBOById = async (id: string) => {
        setLoading(true);
        const res = await SBOService.getCreationSBOById(id);

        if (!res.isSuccessful()) {
            setLoading(false);
            currentTab.close();

            return;
        }

        setSBO(res.getData() as XSBO);
        setLoading(false);
    };

    const getViewingSBOById = async (id: string) => {
        setLoading(true);
        const [res, initialSBO] = await Promise.all([
            SBOService.getViewingSBOById(id),
            MorphService.getByID(id, morphId),

        ]);

        if (!res.isSuccessful() || !initialSBO.isSuccessful()) {
            setLoading(false);
            currentTab.close();

            return;
        }

        const {sbo: sboData, editableFieldsIDs} = res.getData() as {sbo: XSBO, editableFieldsIDs: string[]};

        setSBO(sboData);
        setEditableFieldsIDs(editableFieldsIDs);
        setInitialValue(initialSBO.getData() as XSBO);
        setLoading(false);
    };

    const handleSave = async (f4FormContext:TF4FormContextValue, closeTab?:boolean) => {
        if (!(await f4FormContext.validate()).isFormValid) {
            const errorField = document.getElementById('error-field');
            errorField?.scrollIntoView({behavior: 'smooth', block: 'center'});
            toast.error(strings('mandatory_fields_missing'));

            return;
        }

        setLoading(true);

        const data = f4FormContext.data;

        const excludedFields = ['designation', 'sboId', '_id', 'tags', 'version'];
        const finalData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
                if (excludedFields.includes(key)) return [key, value];

                const fieldType = sbo.fieldsMap?.[key]?.type;
                if (fieldType === 'string') return [key, typeof value === 'string' ? value.trim() : value];
                if (fieldType === 'integer' || fieldType === 'float') return GetFormNumericString([key, value]);

                return [key, value];
            }),
        );

        const finalResults = {
            ...finalData,
            designation: sbo?.designation || null,
            sboId,
        };
        const res = await MorphService.storeNewObject(finalResults);

        if (res.isSuccessful()) {
            toast.success(isReadOnlyMode ? strings('record_edited_successfully') : strings('new_record_added_successfully'));
            currentTab.updateConfig({hasUnsavedChanges: false});
            closeTab && currentTab.close();
            closeTab && TabsManager.focusOrOpenNewTab('dataTable', sbo.name as string, {sboId}, {tabKey: 'dataTable-' + sboId});

            setLoading(false);
            onSave?.();
            UpdateSignalEmitter.emit('data-table');

            return true;
        }

        setLoading(false);

        return false;
    };

    const handleSaveAndFillAnother = async (f4FormContext:TF4FormContextValue) => {
        if (await handleSave(f4FormContext)) {
            f4FormContext.reset({});
            if (!isReadOnlyMode) return;

            currentTab.setTabState({mode: 'create'});
        }
    };

    const handleCancel = () => currentTab.close();

    const handleSwitchToBulkEntry = () => {
        currentTab.close();
        TabsManager.openNewTab('BulkRecordEditor', strings('bulk_record_editor'), {sboId});
    };

    const handleEdit = () => {
        currentTab.setTabState({mode: 'edit'});
    };

    return {
        loading,
        sbo,
        handleCancel,
        handleSave,
        handleSaveAndFillAnother,
        initialValue,
        editableFieldsIDs,
        handleSwitchToBulkEntry,
        handleEdit,
    };
};

export default useRecordEditor;

