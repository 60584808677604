
import {TF4ThemeColors} from '@aktek/f3theme';
import {F4FormElementDecorator, useF4FormContext} from '@aktek/f4form';
import {Icon, Image} from '@aktek/f4kit';
import {faFolder} from '@fortawesome/pro-light-svg-icons';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import AskForModal from '@/asks/AskForModal';
import Env from '@/config/env';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {TFolder} from '@/types/Folder.t';

import ImageOrColorSelectorModal, {
    TImageColorSelectorProps,
    TImageColorSelectorValue,
    TImageColorSelectorValueType,
} from '../Modals/ImageOrColorSelector/ImageOrColorSelector.Modal';

type TExplorerNodeDesignProps = {
    defaultIcon?: IconDefinition,
    value?: TImageColorSelectorValue,
    availableTypes?: TImageColorSelectorValueType[];
    onChange?: (value: TImageColorSelectorValue) => void,
    background?: TF4ThemeColors,
    className?: string,
    initialValue?: {
        customColor?: string,
        customIcon?: string,
    },
}

function ExplorerNodeDesignProps(props: TExplorerNodeDesignProps) {
    const {
        defaultIcon = faFolder,
        availableTypes = ['image', 'color'],
        className,
        onChange,
        initialValue,
    } = props;
    const f4FormContext = useF4FormContext();

    const {theme} = useGlobalTheme();
    const defaultColorValue = theme?.colors?.neutral ?? '#7B89A0';

    const customColor = initialValue?.customColor || defaultColorValue;
    const customIcon = initialValue?.customIcon;

    const onConfirm = (value) => {
        if (value.type === 'color') {
            f4FormContext.setValue('customColor' as never, value.value);
            f4FormContext.setValue('customIcon' as never, null);
        } else {
            f4FormContext.setValue('customIcon' as never, value.value);
            f4FormContext.setValue('customColor' as never, null);
        }

        onChange?.(value);
    };

    const onClick = () => AskForModal<TImageColorSelectorProps>(
        ImageOrColorSelectorModal,
        {
            info: {type: 'folder', initialValue: initialValue as TFolder},
            availableTypes,
            onConfirm,
        },
    );

    if (customIcon) {
        return <div onClick={onClick} className="!h-[3.1rem] !w-[3.25rem] rounded-xl mt-4 ">
            <Image
                src={Env.getFileURL(customIcon)}
                alt="custom icon"
                className="rounded-xl !w-full !h-full !object-cover cursor-pointer"
            />
        </div>;
    }

    return <div
        style={{
            backgroundColor: `${customColor}33`, // 33 is hex for 20% opacity
        }}
        className="rounded-xl flex items-center justify-center w-fit h-fit mt-4">

        <div className="px-4 pt-3.5 pb-3">
            <Icon
                className={className}
                size="xl"
                icon={defaultIcon}
                color={customColor}
                onClick={onClick}
            />
        </div>
    </div>;
}

const F4ImageColorSelector = F4FormElementDecorator<TExplorerNodeDesignProps>(ExplorerNodeDesignProps);

export default F4ImageColorSelector;
