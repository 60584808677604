import {TModalRef} from '@aktek/f4kit';
import {forwardRef, useCallback, useMemo, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {strings} from '@/localization/i18n';

import useFilteredItems from '../hooks/useFilteredItems';
import FolderModalsContent from './components/Folder.Modals.Content';

type TFolderCreateModal = TF4CreatableModalProps & {
    parentId?: string
}

const FolderCreateModal = (props: TFolderCreateModal, ref: TModalRef) => {
    const {viewState, parentId, ...rest}= props;
    const {folders, storeFolder} = useGlobalFilesAndFolders();
    const [searchValue, setSearchValue] = useState('');
    const data = useFilteredItems(folders, searchValue, true, 'folder');
    const {theme} = useGlobalTheme();

    const defaultColorValue = theme?.colors?.neutral ?? '#7B89A0';

    const [info, setInfo] = useState({
        folderName: '',
        parentId: parentId,
        icon: '',
        color: defaultColorValue,
    });
    const updateInfoState = (key, value) => setInfo((state) => ({...state, [key]: value}));

    const finalInitialValue = useMemo(() => {
        const initialValue = {
            customColor: info.color == defaultColorValue ? null : info.color,
            customIcon: info.icon,
            parentId: {[parentId]: true},
        };

        return initialValue;
    }, [parentId]);

    const finalImageColorSelectorValue = useMemo(() => {
        return {
            customColor: info.color == defaultColorValue ? null : info.color,
            customIcon: info.icon,
        };
    }, [info]);

    const handleCreateFolder = useCallback((f4FormInfo) => {
        const {customColor, customIcon, label} = f4FormInfo;
        const finalValue = {
            label,
            customColor,
            customIcon,
            parentId: info.parentId,
        };

        return storeFolder(finalValue);
    }, [info.parentId]);

    return (
        <F4CreatableModal
            ref={ref}
            viewState={viewState}
            title={strings('folder')}
            formKey="folder"
            f4FormProps={{initialValue: finalInitialValue}}
            className="!w-1/2"
            hasSidebar={false}
            storeFunction={handleCreateFolder}
            createSuccessMessage={strings('folder_created_successfully')}
            errorMessage={strings('folder_created_failed')}
            {...rest}
        >
            <FolderModalsContent
                info={info}
                data={data}
                searchValue={searchValue}
                updateInfoState={updateInfoState}
                setSearchValue={setSearchValue}
                finalInitialValue={finalImageColorSelectorValue}
            />

        </F4CreatableModal>

    );
};

export default forwardRef(FolderCreateModal);
