import {DateTime} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const DateTimeCellEdit = ({value, onChange}) => {
    return <div className="flex items-center gap-1">

        <DateTime
            mode="date"
            onChange={onChange}
            placeholder={strings('select_date')}
            value={value}
            minDate={0}
            maxDate={0}
            dateFormat="DD/MM/YYYY"
        />

    </div>;
};

export default DateTimeCellEdit;
