export const toCSV = (value: any, field: any) => {
    switch (field.type) {
        case 'boolean':
            return value ? 'true': 'false';
        case 'string':
            return value?.toString?.() || '';
        case 'tags':
            return value.tags.join(',');
        default:
            return value + '';
    }
};
