import {FileSelectInput, FilesSwiper, TFile, useFileSelectRef} from '@aktek/f4kit';
import React, {useCallback, useMemo, useState} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import {getResourceFileFromMediaFile} from '@/helpers/File';
import {strings} from '@/localization/i18n';
import {TMediaFile} from '@/types/Media';

import FilesSelectorListPopup from './Components/FilesSelectorListPopup';
import {HandleFileDoubleClick} from './Helpers/FileSwiperFullScreenModal';
import {HandleFileContextMenu, HandleSwiperContextMenus} from './Helpers/HandleContextMenu';
import {useFileAdd} from './Hooks/useFileAdd';
import {useUploadSelectedFiles} from './Hooks/useUploadSelectedFiles';
import {FilesSwiperUploadProps} from './Types/FilesSwiperUploadProps.t';

const FilesSwiperUpload: React.FC<FilesSwiperUploadProps> = ({
    onNewFiles,
    onChange,
    extensions,
    mediaFiles,
    minimum,
    maximum,
    fileCategory,
    isEditMode,
    autoFocus,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const fixedMediaFiles = useMemo(() => fixMediaFiles(mediaFiles), [mediaFiles]);
    const fileSelectRef = useFileSelectRef();
    const {isUploading, uploadSelectedFiles} = useUploadSelectedFiles({onNewFiles});

    const removeFile = useCallback<(index: number) => void>((index) => {
        const filteredMediaFiles = fixedMediaFiles.filter((_, _index) => _index !== index);
        onChange(filteredMediaFiles);
    }, [fixedMediaFiles, onChange]);

    const onFileAdd = useFileAdd({
        isEditMode,
        mediaFiles: fixedMediaFiles,
        minimum,
        maximum,
        extensions,
        fileSelectRef,
    });
    const allowedNumberOfFiles = maximum ? maximum - fixedMediaFiles.length : undefined;

    const canAddFiles = isNaN(allowedNumberOfFiles) || allowedNumberOfFiles > 0;

    const handleClick = useCallback((event) => {
        if (!isEditMode) return;
        event.stopPropagation();

        const rect = event.currentTarget.getBoundingClientRect();
        const windowWidth = window.innerWidth;

        setIsModalVisible((isVisible) => {
            if (isVisible) return false;

            return rect.left < windowWidth / 2 ? 'left-0' : 'right-0';
        });
    }, [isEditMode]);

    const handleFileSelect = useCallback(async (files: TFile[]) => {
        if (files?.length) {
            await uploadSelectedFiles(files, allowedNumberOfFiles);
        }

        setIsModalVisible(false);
    }, [allowedNumberOfFiles, uploadSelectedFiles]);

    const files = fixedMediaFiles.map(getResourceFileFromMediaFile);

    return (
        <div className="px-4 relative h-full flex justify-center items-center"
            onClick={handleClick}
            onContextMenu={(event) => {
                HandleSwiperContextMenus(event, fileSelectRef.current?.clickToSelectFiles);
            }}
        >
            <div className="w-64 min-w-full relative"
                onClick={(event) => {
                    event.stopPropagation();
                    setIsModalVisible(false);
                }}>
                <FileSelectInput
                    autoFocus={autoFocus}
                    isMulti={true}
                    ref={fileSelectRef}
                    extensions={extensions}
                    onSelectFiles={handleFileSelect}
                />

                <FilesSwiper
                    defaultFileCategory={fileCategory}
                    itemSideLength={50}
                    onFileClick={(event, file) => { }}
                    onFileDoubleClick={
                        (event, file) => HandleFileDoubleClick({
                            file: file as TMediaFile,
                            mediaFiles: fixedMediaFiles,
                            fileCategory,
                        })
                    }
                    onFileContextMenu={(event, file, index) => {
                        HandleFileContextMenu(event, () => removeFile(index));
                    }}
                    onFileRemove={async (event, file, index) => {
                        removeFile(index);

                        return true;
                    }}
                    files={files}
                    onFileAdd={(canAddFiles) ? onFileAdd : undefined}
                    isLoading={isUploading}
                />
            </div>
            {isModalVisible && (
                <div className={`absolute top-full mt-2 z-[5] ${isModalVisible}`} onClick={(event) => event.stopPropagation()}>
                    <FilesSelectorListPopup
                        files={files}
                        onDeleteFile={(fileId) => removeFile(files.findIndex((file) => file.url === fileId))}
                        onDeleteAll={async () => {
                            const isConfirmed = await AskForConfirmation(
                                strings('delete_all_files'),
                                strings('delete_all_files_confirm'),
                            );

                            if (!isConfirmed) return;

                            onChange([]);

                            setIsModalVisible(false);
                        }}
                        onUploadMore={() => {
                            fileSelectRef.current?.clickToSelectFiles?.();
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default FilesSwiperUpload;

const fixMediaFiles = (mediaFiles: TMediaFile[]): TMediaFile[] => {
    if (typeof mediaFiles === 'string') {
        return [{
            key: mediaFiles,
        }];
    }

    return mediaFiles;
};
