import {TabsManager} from '@aktek/f4kit';
import {faVials} from '@fortawesome/pro-light-svg-icons';
import {faArrowRightFromArc, faBarChart, faBolt, faBrush, faBuilding, faBuildings, faChartLineUp,
    faClockRotateLeft, faCloudArrowDown,
    faDatabase, faEye, faFileLines, faFill, faFunction, faGear, faLanguage, faList, faLock, faMapPin, faPlus,
    faTag, faTrashCan, faTriangleExclamation, faUser, faUsers} from '@fortawesome/pro-regular-svg-icons';

import AgencyCreateModal from '@/app/Admin/Agency/components/Agency.Create.Modal';
import LicensingCreateModal from '@/app/Admin/Licensing/components/Licensing.Create.Modal';
import AskForModal from '@/asks/AskForModal';
import type {TNavigationSection} from '@/components/Navigation/types/Navigation.t';
import {usePermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

type TAdminNavigation = ()=> TNavigationSection[]

export const AdminNavigation : TAdminNavigation = () =>{
    const {helperPermissions, permissions} = usePermissions();

    return [
        {
            sectionTitle: strings('security'),
            categories: [
                {
                    tabKey: 'accessControl',
                    name: strings('access_control'),
                    icon: faLock,
                    color: '#039855',
                    pages: [
                        {label: strings('rolesAndPermissions'), tabKey: 'rolesAndPermissions',
                            icon: faEye, isVisible: helperPermissions?.canAccessRoles},

                        {label: strings('users'), tabKey: 'users',
                            icon: faUser, isVisible: helperPermissions?.canAccessUsers},

                        {label: strings('groups'), tabKey: 'groups',
                            icon: faUsers, isVisible: helperPermissions?.canAccessGroups},

                        {label: strings('tac'), tabKey: 'tac',
                            icon: faTag, isVisible: helperPermissions?.canAccessTAC},
                    ],
                    isVisible: helperPermissions?.canAccessSecurity,
                },
            ],
            isVisible: helperPermissions?.canAccessSecurity,
        },
        {
            sectionTitle: strings('data'),
            categories: [
                {
                    tabKey: 'dataStructure',
                    name: strings('data_structures'),
                    color: '#1570EF',
                    icon: faDatabase,

                    customBadges: [{label: strings('new_data_structure'), icon: faPlus, tabKey: 'sboEditor', componentKey: 'sboEditor',
                        onClick: () => TabsManager.openNewTab('sboEditor', 'New Data Structure', {}, {tabKey: 'sboEditor'}),
                        isVisible: helperPermissions?.canCreateDataStructure,
                    }],
                    pages: [
                        {label: strings('data_structures'), tabKey: 'dataStructure', icon: faDatabase},
                        {label: strings('conditional_coloring'), tabKey: 'conditional_coloring', icon: faFill},
                        {label: strings('translations'), tabKey: 'translations', icon: faLanguage},
                    ],
                    isVisible: helperPermissions?.canAccessDataStructure,
                },
                {
                    tabKey: 'automation',
                    name: strings('automation'),
                    color: '#1570EF',
                    icon: faBolt,
                    pages: [
                        {label: strings('apply_formula'), tabKey: 'applyFormula', icon: faFunction},
                        {label: strings('transform_ref_into_field'), tabKey: 'transformRefIntoField', icon: faArrowRightFromArc},
                        {label: strings('transform_field_into_ref'), tabKey: 'transformFieldIntoRef', icon: faArrowRightFromArc},
                        {label: strings('transform_field_into_multiref'), tabKey: 'transformFieldIntoMultiRef', icon: faArrowRightFromArc},
                        {label: strings('extract_domain_names'), tabKey: 'extractDomainNames', icon: faCloudArrowDown},
                        {label: strings('extract_record_of_interest'), tabKey: 'extractRecordOfInterest', icon: faCloudArrowDown},
                        {label: strings('update_record_of_interest'), tabKey: 'updateRecordOfInterest', icon: faCloudArrowDown},
                        {label: strings('reverse_geocode'), tabKey: 'reverseGeocode', icon: faMapPin},
                        {label: strings('progress'), tabKey: 'automationProgress', icon: faChartLineUp},
                    ],
                    isVisible: helperPermissions?.canAccessAutomation,
                },
            ],
            isVisible: helperPermissions?.canAccessData,
        },
        {
            sectionTitle: strings('my_company'),
            categories: [
                {
                    tabKey: 'companySettings',
                    name: strings('company_settings'),
                    color: '#6938EF',
                    pages: [
                        {label: strings('settings'), tabKey: 'settings', icon: faGear, isVisible: helperPermissions?.canAccessSettings},
                        {label: strings('appearance'), tabKey: 'appearance', icon: faBrush, isVisible: helperPermissions?.canAccessSettings},
                        {label: strings('tags'), tabKey: 'tags', icon: faTag, isVisible: helperPermissions?.canAccessTags},
                    ],
                    icon: faGear,
                    isVisible: helperPermissions?.canAccessCompanySettings,
                },
                {
                    tabKey: 'usageMetrics',
                    name: strings('usage_metrics'),
                    color: '#6938EF',
                    pages: [
                        {label: strings('monthly_usage'), tabKey: 'monthlyUsage', icon: faBarChart},
                        {label: strings('overall_usage'), tabKey: 'overallUsage', icon: faBarChart},
                    ],
                    icon: faBarChart,
                },
            ],
            isVisible: helperPermissions?.canAccessCompanySettings,
        },
        {
            sectionTitle: 'Agency',
            categories: [
                {
                    tabKey: 'agency',
                    name: strings('agency'),
                    icon: faBuilding,
                    color: '#BA24D5',
                    customBadges: [{label: strings('new_company'), icon: faPlus,
                        onClick: () => AskForModal(AgencyCreateModal, {viewState: {mode: 'create'}}),

                        isVisible: helperPermissions?.canCreateCompanies,
                    }],
                    pages: [
                        {tabKey: 'agency', label: strings('companies'), icon: faBuildings,
                            isVisible: helperPermissions?.canViewCompanies},

                    ],
                    isVisible: helperPermissions?.canAccessAgency,
                },
                {
                    tabKey: 'licensing',
                    name: strings('licensing'),
                    color: '#BA24D5',
                    icon: faFileLines,
                    customBadges: [{label: strings('new_license'), icon: faPlus,
                        onClick: () => AskForModal(LicensingCreateModal, {viewState: {mode: 'create'}}),
                    }],
                    pages: [
                        {tabKey: 'licensing', label: strings('licenses'), icon: faFileLines,
                            isVisible: helperPermissions?.canAccessLicense},

                    ],
                    isVisible: helperPermissions?.canAccessLicense,
                },
            ],
            isVisible: helperPermissions?.canAccessAgencySection,
        },
        {
            sectionTitle: 'Advanced',
            categories: [
                {
                    tabKey: 'advanced',
                    name: strings('advanced'),
                    color: '#088AB2',
                    pages: [
                        {tabKey: 'restoreFields', label: strings('restore_fields'), icon: faClockRotateLeft},
                        {tabKey: 'deleteAllData', label: strings('delete_all_data'), icon: faTrashCan},
                    ],
                    icon: faTriangleExclamation,
                },
                {
                    tabKey: 'iOJobs',
                    color: '#088AB2',
                    name: strings('io_jobs'),
                    pages: [{tabKey: 'iOJobs', label: strings('view_all_jobs'), icon: faList}],
                    icon: faList,
                },
            ],
            isVisible: permissions?.admin?.viewJobs?.can_view,
        },
        {
            sectionTitle: 'Playground',
            categories: [ // Don't remove, just add new pages as needed
                {
                    tabKey: 'Playground',
                    name: 'Playground',
                    icon: faVials,
                    color: '#039855',
                    pages: [
                        {label: 'IOSelect', tabKey: 'playgroundIOSelect',
                            icon: faVials},
                        {label: 'Shortcut', tabKey: 'shortcutPlayground',
                            icon: faVials},
                        {label: 'UpdateSignalEmitter', tabKey: 'updateSignalEmitterPlayground',
                            icon: faVials},
                    ],
                },
            ],
            isVisible: ls.user.email.includes('@aktek.io'),
        },
    ];
};
