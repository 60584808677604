import {Icon, TabsManager, TContextMenuEntryItem} from '@aktek/f4kit';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {faClone, faDatabase, faTurnRight} from '@fortawesome/pro-light-svg-icons';
import {TabNode} from 'flexlayout-react';
import {TTabConfig} from 'node_modules/@aktek/f4kit/dist/components/TierOne/TabsFlexLayout/Types/Types';
import React from 'react';

import {Tabs} from '@/app/Home/Tabs';
import AskForModal from '@/asks/AskForModal';
import Env from '@/config/env';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {strings} from '@/localization/i18n';
import {TIOTabConfig} from '@/types/CommonTypes';

import CreateShortcutModal, {TShortcutModalProps} from '../components/CreateShortcutModal';
import useTabShortcuts from '../hooks/useTabShortcuts';
const ComponentCache = new Map<string, React.ComponentType<any>>();

const getComponent = (node: TabNode, nodeData: TTabConfig) => {
    const id = node.getId();
    const {componentKey, options} = nodeData;
    const tabDefinition = Tabs[componentKey];
    const cacheKey = `${componentKey}-${options?.isPinned}`;
    const cachedComponent = ComponentCache.get(cacheKey);

    if (cachedComponent) return cachedComponent;

    const Component = tabDefinition?.component;

    if (!Component) return null;

    // Move hook usage outside of render function
    const TabComponent = () => {
        if (!options?.isPinned) {
            const tabContext = useTabShortcuts({
                'Alt+w': {
                    description: 'Close current tab',
                    action: () => {
                        TabsManager.closeTab(tabContext.node.getId());
                    },
                },
            });
        }

        return <Component />;
    };

    // Give the memoized component a display name for debugging
    TabComponent.displayName = `Tab_${componentKey}`;

    ComponentCache.set(cacheKey, TabComponent);

    return TabComponent;
};

const getDropDownOptions = (node: TabNode, nodeData: TTabConfig) : TContextMenuEntryItem[]=> {
    const shortcut = TabsManager.getShortcutData(node.getId());
    const {componentKey, title} = nodeData;
    const icon = Tabs[componentKey]?.icon;

    // const {refresh} = useGlobalFilesAndFolders();

    return [
        {
            key: 'createShortcut',
            label: strings('create_a_new_shortcut'),
            icon: faTurnRight,
            onClick: () => {
                AskForModal<TShortcutModalProps>(CreateShortcutModal, {
                    componentKey,
                    title,
                    // tabState: state,
                    // refresh,
                    icon,
                });
            },
            labelProps: {className: '!text-neutral-900 !font-medium'},
        },
        {
            key: 'copyDirectLink',
            label: strings('copy_direct_link'),
            icon: faClone,
            onClick: () => {
                if (!shortcut) return;
                const envURL = Env.baseURL;
                const url = `${envURL}/#/shortcut/${shortcut}`;
                CopyToClipboard(url);
            },
            labelProps: {className: '!text-neutral-900 !font-medium'},
        },
    ];
};

const getTitle= (nodeData: TTabConfig<TIOTabConfig>) => {
    const {title, hasUnsavedChanges} = nodeData;

    const finalTitle = title || Tabs[nodeData.componentKey]?.title || '';
    const maxLength = 20;
    const name = finalTitle?.length > maxLength ? finalTitle.slice(0, maxLength) + '...' : finalTitle;

    return <div className="flex flex-row gap-1 items-center">
        {nodeData.isActive && TabsManager.getTabSetCount() > 1
            ? <b> <div>{name}</div></b>
            : <div>{name}</div>}
        <div>{hasUnsavedChanges ? <Icon className="w-1.5 h-1.5 align-middle" icon={faCircle} color="neutral-400" size="xs" /> : ''}</div>
    </div>;
};

const getIcon= (node: TabNode, nodeData: TTabConfig<TIOTabConfig>) => <Icon icon={nodeData.icon || Tabs?.[nodeData.componentKey]?.icon || faDatabase} />;

export const TabFactory = {getComponent, getTitle, getIcon, getDropDownOptions};

