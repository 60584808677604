import {Button, Image, Label, MimeTypes} from '@aktek/f4kit';
import {faCloudArrowUp} from '@fortawesome/pro-regular-svg-icons';

import useImageOrColorSelectorImageUploader from '../hooks/useImageOrColorSelectorImageUploader';
import getImageOrColorSelectorImageUploaderClasses from '../styles/ImageOrColorSelectorImageUploader.styles';

type TImageOrColorSelectorImageUploaderProps = {
    title?:string;
    value: string;
    maxSizeMB: number;
    allowedTypes: Array<string>;
    onChange: (e: string) => void;
    setIsLoading?: (e: boolean) => void;
    isViewMode?: boolean;
    className?: string;
    label?: string;
    description?: string;
    hasWarning?: boolean;
}

// const DEFAULT_ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];
const DEFAULT_ALLOWED_IMAGE_TYPES = [MimeTypes.jpg, MimeTypes.png, MimeTypes.jpeg];

const TextContent = ({label, description, classes, isLoading, isAbsolute = false}) => {
    return <div className={isAbsolute ? classes.absoluteContainerContent : classes.containerContent}>
        <Button
            isCircle
            isGhost
            size="xl"
            icon={faCloudArrowUp}
            variant="primary"
            className={classes.uploadButton}
            isLoading={isLoading}
        />
        <div className={classes.labelWrapper}>
            <Label
                size="md"
                text={label}
                fontWeight="semibold"
                color={isAbsolute ? 'white' : 'primary-700'}
                className={classes.label} />

        </div>
        <Label
            size="xs"
            text={description}
            fontWeight="normal"
            color={isAbsolute ? 'white' : 'neutral-600'}
            className={classes.description} />
    </div>;
};

export const ImageOrColorSelectorImageUploader = (props: Partial<TImageOrColorSelectorImageUploaderProps>) => {
    const {
        onChange,
        value='',
        title,
        className,
        maxSizeMB = 1,
        allowedTypes = DEFAULT_ALLOWED_IMAGE_TYPES,
        label,
        description,
        hasWarning,
    } = props;

    const {
        imageObject,
        handleImageClick,
        handleOnChange,
        isLoading,
        fileInputRef,
    } = useImageOrColorSelectorImageUploader(title, value, onChange, allowedTypes, maxSizeMB);

    const classes = getImageOrColorSelectorImageUploaderClasses(imageObject, className, isLoading, hasWarning);

    return <>
        <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className={classes.fileInput}
            onChange={handleOnChange}
        />
        <div
            onClick={handleImageClick}
            className={classes.container}
        >
            {!imageObject && (
                <TextContent
                    label={label}
                    description={description}
                    classes={classes}
                    isLoading={isLoading}
                    isAbsolute={false}
                />
            )}
            {imageObject && (
                <>
                    <Image
                        alt={imageObject?.alt}
                        src={imageObject?.src}
                        className={classes.imageContainer}
                    />

                    <div
                        className={classes.absoluteContainer}
                        style={{
                            background: `linear-gradient(
                                0deg,
                                rgba(0, 0, 0, 0.4) 0%,
                                rgba(0, 0, 0, 0.4) 100%
                            )`,
                        }}
                    >
                        <TextContent
                            label={label}
                            description={description}
                            classes={classes}
                            isLoading={isLoading}
                            isAbsolute={true}
                        />
                    </div>
                </>
            )}
        </div>
    </>;
};

export default ImageOrColorSelectorImageUploader;
