import {Button, Label, TextBox} from '@aktek/f4kit';
import {useRef, useState} from 'react';

import UpdateSignalEmitter from '@/helpers/UpdateSignalEmitter';
import {useUpdateSignal} from '@/hooks/useUpdateSignal';

const ChildComponent = ({eventId, rerender}: { eventId: string, rerender: boolean }) => {
    const data = useUpdateSignal(eventId, {forceRender: rerender, onSignal: (data) => {
        console.log(`Data for Event ${eventId}: ${data}`);
    }});
    const renderCount = useRef(0);

    return <div className="flex flex-col gap-1 border border-solid border-neutral-300 rounded-md p-2">
        <Label text={`Data for Event ${eventId}: ${data}`} />
        Render Count: {renderCount.current++}
    </div>;
};

export default function PlaygroundIOSelect() {
    const [eventId, setEventId] = useState('A');

    return <>
        <TextBox label="Event ID" value={eventId} onChange={setEventId} />
        <Button label="Emit" onClick={() => {
            UpdateSignalEmitter.emit(eventId, Date.now());
        }} />

        <div>
            <b>Those children will rerender when the signal is emitted.</b>
            <ChildComponent eventId="A" rerender={true} />
            <ChildComponent eventId="B" rerender={true} />
            <ChildComponent eventId="C" rerender={true} />
        </div>
        <div>
            <b>Those children will not rerender when the signal is emitted but instead log to console.</b>
            <ChildComponent eventId="D" rerender={false} />
            <ChildComponent eventId="E" rerender={false} />
        </div>
    </>;
}
