import {F4FormDiv, F4FormStep} from '@aktek/f4form';

import F4TextBox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

export function RoleInfo() {
    return (
        <F4FormStep step={'roleInfo'}>
            <F4FormDiv className="flex flex-col gap-4">
                <F4TextBox
                    colSpan={12}
                    required
                    name="name"
                    size="sm"
                    className="!font-medium"
                    label={strings('role_name')}
                    autoFocus
                />

                <F4TextBox
                    colSpan={12}
                    name="description"
                    size="sm"
                    className="!font-medium"
                    label={strings('description')}
                />
            </F4FormDiv>
        </F4FormStep>
    );
}

export default RoleInfo;
