import {CellAutomatic} from './Components/Cell.Automatic';
import {CellBoolean} from './Components/Cell.Boolean';
import {CellCheckbox} from './Components/Cell.CheckBox';
import {CellClosedList} from './Components/Cell.ClosedList';
import {CellColor} from './Components/Cell.Color';
import {CellConnectedRef} from './Components/Cell.ConnectedRef';
import {CellCustom} from './Components/Cell.Custom';
import {CellDate} from './Components/Cell.Date';
import {CellDatetime} from './Components/Cell.Datetime';
import {CellDecimal} from './Components/Cell.Decimal';
import {CellFormula} from './Components/Cell.Formula';
import {CellGPS} from './Components/Cell.GPS';
import {CellInteger} from './Components/Cell.Integer';
import {CellMedia} from './Components/Cell.Media';
import {CellMediaAlbum} from './Components/Cell.MediaAlbum';
import {CellMediaGeneric} from './Components/Cell.MediaGeneric';
import {CellMediaImage} from './Components/Cell.MediaImage';
import {CellMediaVideo} from './Components/Cell.MediaVideo';
import {CellMeta} from './Components/Cell.Meta';
import {CellMultiSubform} from './Components/Cell.MutiSubform';
import {CellNumber} from './Components/Cell.Number';
import {CellRef} from './Components/Cell.Ref';
import {CellRefAnnex} from './Components/Cell.RefAnnex';
import {CellRefList} from './Components/Cell.RefList';
import {CellRefListAnnex} from './Components/Cell.RefListAnnex';
import {CellSpecialList} from './Components/Cell.SpecialList';
import {CellSpecialString} from './Components/Cell.SpecialString';
import {CellStopWatch} from './Components/Cell.StopWatch';
import {CellString} from './Components/Cell.String';
import {CellSubform} from './Components/Cell.Subform';
import {CellSwitch} from './Components/Cell.Switch';
import {CellTags} from './Components/Cell.Tags';
import {CellTime} from './Components/Cell.Time';

// TODO: check uploadlink vs mediaGeneric
export const CellTypeMapper = {
    // Used By Data Table
    number: CellNumber,
    string: CellString,
    specialString: CellSpecialString,
    specialList: CellSpecialList,
    uploadlink: CellMediaGeneric,
    mediaGeneric: CellMediaGeneric,
    mediaVideo: CellMediaVideo,
    mediaAlbum: CellMediaAlbum,
    mediaImage: CellMediaImage,
    color: CellColor,
    stopwatch: CellStopWatch, // @TODO: is this still supported
    tags: CellTags,
    // decimal: CellDecimal,
    float: CellDecimal,
    // @TODO: add type 'uploadlink';

    // Others
    checkbox: CellCheckbox,
    switch: CellSwitch,
    carousel: CellMedia,
    datetime: CellDatetime,
    date: CellDate,
    time: CellTime,
    custom: CellCustom,
    gps: CellGPS,
    integer: CellInteger,
    ref: CellRef,
    refList: CellRefList,
    refAnnex: CellRefAnnex,
    refListAnnex: CellRefListAnnex,
    connectedRef: CellConnectedRef,
    closedList: CellClosedList,
    boolean: CellBoolean,
    formula: CellFormula,
    meta: CellMeta,
    subform: CellSubform,
    multisubform: CellMultiSubform,
    automatic: CellAutomatic,
};
