
import {ToggleSwitch} from '@aktek/f4kit';

const BooleanCellEdit = ({value, onChange}) => {
    return <ToggleSwitch autoFocus
        tabIndex={-1}
        value={value}
        onChange={(value) => {
            console.log('....Boolean.Cell.Edit.tsx -> Line 10', {value});
            onChange(value);
        }}
    />;
};

export default BooleanCellEdit;
