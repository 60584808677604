import {F4Form, F4FormDiv, F4Nestor} from '@aktek/f4form';
import {LoadingWrapper} from '@aktek/f4kit';
import {useCallback, useEffect} from 'react';

import LoginSideImage from '@/assets/images/login_side_image.png';
import AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4ButtonColorPicker from '@/components/F4Elements/F4ButtonColorPicker';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4RadioButtonGroup from '@/components/F4Elements/F4RadioButtonGroup';
import {applyLoginLogo, applyLoginSideImage} from '@/helpers/applyLoginAssets.fn';
import XApplyTheme from '@/helpers/ApplyTheme.fn';
import {isEmptyOrAllNill} from '@/helpers/IsEmptyOrAllNill.fn';
import {strings} from '@/localization/i18n';

import SettingsFooter from '../../components/SettingsFooter';
import {FONT_SIZES, FONT_STYLES, THEME_COLORS} from './constants/Appearance.c';
import useAppearance from './hooks/useAppearance';

export default function Appearance() {
    const {isLoading, initialValue, handleSave, handleDiscard, handleReset} = useAppearance();

    const onChange = useCallback(
        (appearance)=> {
            const theme = {
                colors: appearance.colors,
                font: appearance.font,
            };

            if (!isEmptyOrAllNill(theme)) XApplyTheme(theme);
        }, []);

    const handleFileUpload = useCallback((files, appearanceF4FormContext) => {
        if (files.length === 0 || !(files[0] instanceof Blob)) return;

        appearanceF4FormContext.importData(files[0]);
    }, []);

    useEffect(()=>{
        if (!initialValue) return;
        applyLoginLogo(initialValue.images.logo);
        applyLoginSideImage(initialValue.images.background);
    }, [initialValue]);

    return (
        <div className="bg-neutral-50 w-full h-full overflow-y-auto">
            <F4Form
                formKey="appearance"
                onChange={onChange}
                initialValue={initialValue}
                className="h-full"
            >
                <>
                    <LoadingWrapper isLoading={isLoading} isFull className="w-full absolute mx-auto my-auto px-4 " size="lg">
                        <div className="flex flex-col gap-4  ">

                            <AvatarImageSelector
                                name="images.logo"
                                label={strings('company_logo')}
                                wrapper={{className: 'mt-4'}}
                                size="3xl"

                                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                                maxSizeMB={1}
                            />

                            <F4Nestor name="font">
                                <div className="flex flex-row gap-5 justify-between items-end my-4">

                                    <F4Dropdown
                                        name="fontStyle"
                                        required
                                        colSpan={4}
                                        label={strings('font')}
                                        renderOption={(option)=> {
                                            const fontKey = Object.keys(FONT_STYLES).find((key) => FONT_STYLES[key] === option);

                                            return <p style={{fontFamily: fontKey}}>{option.label}</p>;
                                        }}
                                        options={FONT_STYLES} />

                                    <F4RadioButtonGroup
                                        name="fontSize"
                                        required
                                        colSpan={8}
                                        options={FONT_SIZES} />
                                </div>
                            </F4Nestor>

                            <AvatarImageSelector
                                name="images.background"
                                label={strings('login_image')}
                                uploadLabel={strings('upload_image')}
                                replaceLabel={strings('replace_image')}
                                wrapper={{className: 'mt-4'}}
                                size="3xl"
                                className=" !h-44 !w-32 "

                                defaultImage={{src: LoginSideImage, alt: 'AKTEK'}}
                                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                                maxSizeMB={2}
                            />

                            <F4Nestor name="colors">
                                <F4FormDiv className="flex flex-wrap gap-12">
                                    {THEME_COLORS.map((color : {name : string, label : string, value : string}) => (
                                        <div className="w-fit" key={color.name}>
                                            <F4ButtonColorPicker
                                                size="3xl"
                                                name={color.name}
                                                required
                                                colSpan={2}

                                                label={strings(color.label)}
                                            />
                                        </div>
                                    ))}
                                </F4FormDiv>
                            </F4Nestor>
                        </div>
                    </LoadingWrapper>
                    <SettingsFooter
                        onSave={handleSave}
                        onDiscard={handleDiscard}
                        onFileUpload={handleFileUpload}
                        onReset={handleReset}
                    />
                </>
            </F4Form>
        </div>
    );
}

