import {LoadingIndicator} from '@aktek/f4kit';
import {CSSProperties} from 'react';

const UserContextLoader = ({isLoading, authToken}) => {
    const hasAuthToken = Boolean(authToken);
    const shouldShowLoader = isLoading && hasAuthToken;

    if (!shouldShowLoader) {
        return null;
    }

    const loaderStyles : CSSProperties = {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        textAlign: 'center',
        zIndex: 9999,
    };

    return (
        <div
            className="justify-center items-center w-full h-full flex bg-neutral-50"
            style={loaderStyles}
        >
            <LoadingIndicator size="xl" />
        </div>
    );
};

export default UserContextLoader;
