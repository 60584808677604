/* eslint-disable valid-jsdoc */
import {useCallback, useEffect, useState} from 'react';

import UpdateSignalEmitter from '../helpers/UpdateSignalEmitter';

type TUpdateSignalOptions<T> = {
  /**
   * If true, the component will force a re-render when the signal is emitted
   */
    forceRender?: boolean;
    onSignal?: (data: T) => void;
};

/**
 * The default behavior is to force a re-render when the signal is emitted. You can override that by passing options.forceRender = false
 * and instead relying on the onSignal callback to update the state.
 * @param eventId Event to subscribe to
 * @param options  Options object
 * @return The data from the signal or undefined if forceRender is false
 */
export function useUpdateSignal<T = void>(eventId: string, options: TUpdateSignalOptions<T> = {forceRender: true}): T | undefined {
    const {forceRender, onSignal} = options ?? {};

    const [data, setData] = forceRender ? useState<T | undefined>(undefined) : [null, null];

    const updateData = useCallback((newData?: T) => {
        if (forceRender) {
            setData(newData);
        }

        onSignal?.(newData);
    }, []);

    useEffect(() => {
        const unsubscribe = UpdateSignalEmitter.subscribe(eventId, updateData);

        return () => unsubscribe(); // Cleanup on unmount
    }, [eventId, updateData]);

    return forceRender ? data : undefined;
}
