import DateTimeRecordEdit from '@/components/Fields/Datetime/DateTime.Record.Edit';
import DecimalRecordEdit from '@/components/Fields/Decimal/Decimal.Record.Edit';
import IntegerRecordEdit from '@/components/Fields/Integer/Integer.Record.Edit';
import StringRecordEdit from '@/components/Fields/String/String.Record.Edit';

import {TRecordField} from '../../DataTable/types/Record.Types';

type TRecordFieldProps = {
    field: TRecordField;
    layout: boolean;
    isViewMode: boolean;
};

export default function RecordField(props: TRecordFieldProps) {
    const {field, layout, isViewMode} = props;

    if (!field) return null;

    const {type} = field;

    switch (type) {
        case ('string'): {
            return <StringRecordEdit field={field} layout={layout} isViewMode={isViewMode} />;
        }

        case ('integer'): {
            return <IntegerRecordEdit field={field} layout={layout} isViewMode={isViewMode} />;
        }

        case 'decimal':

        case 'float': {
            return <DecimalRecordEdit field={field} layout={layout} isDecimalField={true} isViewMode={isViewMode} />;
        }

        case 'date':
        case 'dateTime':

        case 'time': {
            return <DateTimeRecordEdit field={field} layout={layout} isViewMode={isViewMode} />;
        }

        default: {
            return <StringRecordEdit field={field} layout={layout} isViewMode={isViewMode} />;
        }
    }
}
