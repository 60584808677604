import {TFile, TFileResource, toast} from '@aktek/f4kit';
import {useCallback, useState} from 'react';

import {getMediaFileFromResourceFile} from '@/helpers/File';
import {uploadMultipleFilesToNewURLs} from '@/helpers/FileUpload';
import {strings} from '@/localization/i18n';
import {TStringKey} from '@/localization/types';
import {TMediaFile} from '@/types/Media';

type TUseUploadSelectedFilesProps = {
    onNewFiles: (files: TMediaFile[]) => void;
};

export const useUploadSelectedFiles = ({onNewFiles}: TUseUploadSelectedFilesProps) => {
    const [isUploading, setIsUploading] = useState(false);

    return {
        isUploading,
        uploadSelectedFiles: useCallback(async (selectedFiles: TFile[], allowedNumberOfFiles?: number) => {
            if (isUploading || !Array.isArray(selectedFiles)) return;

            const filesToUpload = allowedNumberOfFiles
                ? selectedFiles.slice(0, allowedNumberOfFiles) : selectedFiles;

            setIsUploading(true);

            const filesUploadResponse = await uploadMultipleFilesToNewURLs(filesToUpload);

            const newResourceFiles: TFileResource[] = filesUploadResponse
                .filter((fileResponse) => !fileResponse.error)
                .map((fileResponse) => fileResponse.resourceFile);

            const failedFilesCount = filesUploadResponse?.length - newResourceFiles?.length;

            if (failedFilesCount) {
                toast.error({
                    subject: strings('files_upload_failed' as TStringKey, failedFilesCount.toString()),
                });
            }

            if (newResourceFiles.length) {
                const newMediaFiles = newResourceFiles.map(getMediaFileFromResourceFile);
                onNewFiles(newMediaFiles);
            }

            setIsUploading(false);
        }, [isUploading, onNewFiles]),
    };
};
