import {TF4ThemeColors} from '@aktek/f3theme';
import {Icon, Label} from '@aktek/f4kit';
import {faInfoCircle, IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {useMemo} from 'react';

type TFieldBaseProp = {
    name:string;
    description:string;
    showDescription:boolean;
    icon:IconDefinition;
    iconColor:TF4ThemeColors;
    classes:Record<string, string>;
    isRequired:boolean;
}

const FieldBase = (props:TFieldBaseProp) => {
    const {description, showDescription, icon, iconColor, classes, isRequired} = props;

    const name = useMemo(() => {
        return isRequired
            ? <>{props.name}<span className="text-neutral-900">*</span></>
            : props.name;
    }, [props.name, isRequired]);

    return <div className={`${classes.text}-1/4`}>
        <div className="flex flex-row gap-2 items-start">

            <div className="flex flex-row gap-2 items-start justify-start">

                <Icon icon={icon} color={iconColor} className="!h-4 !w-4 mt-1" size="md"/>
                <div className="flex flex-row gap-1 items-start justify-start" >
                    <Label text={name as string} color="neutral-600" fontWeight="medium" />
                </div>

            </div>
            <div>

                {!showDescription && description && <Icon
                    tooltip={description}
                    tooltipProps={{showDelay: 500}}
                    size="xl"
                    className="cursor-pointer pt-1 text-center"
                    icon={faInfoCircle}
                    color="neutral-600" />}
            </div>
        </div>
        <div className="pl-6">

            {showDescription && description && <Label
                text={description} className="italic custom-wrap" color="neutral-400" />}
        </div>
    </div>;
};

export default FieldBase;
