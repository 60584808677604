
import {XSBO} from '@aktek/types';
import {forwardRef, useMemo} from 'react';

import {TRecordSection} from '../../DataTable/types/Record.Types';
import {GetSingleRecordClasses} from '../styles/Record.Editor.styles';
import RecordField from './Record.Field';
import RecordSectionHeader from './Record.Section.Header';

type TRecordSectionProps = {
    section: TRecordSection
    fieldsMap: XSBO['fieldsMap']
    layout: boolean
    isViewMode: boolean
    editableFieldsIDs: string[]
}

const RecordSection = (props: TRecordSectionProps, ref: React.RefObject<HTMLDivElement>) => {
    const {section, fieldsMap, layout, isViewMode, editableFieldsIDs} = props;
    const {fields} = section || {};

    const hasFields = useMemo(() => {
        if (!fields?.length) return false;

        return fields.some((fieldId) => fieldsMap?.hasOwnProperty(fieldId));
    }, [fields, fieldsMap]);

    const classes = GetSingleRecordClasses(layout);
    if (!hasFields) return null;

    return (
        <>
            <RecordSectionHeader name={section?.name} />
            <div className={classes.field}>
                {/* {!hasFields && (
                    <div className="flex flex-col gap-2 justify-center items-center">
                        <Label color="neutral-500" size="md" text={strings('no_fields_found')} />
                    </div>
                )} */}
                {hasFields && fields?.map((fieldId) => {
                    const isEditable = editableFieldsIDs ? editableFieldsIDs.includes(fieldId) : true;

                    return (
                        <div key={fieldId} ref={ref}>
                            <RecordField field={fieldsMap[fieldId]} layout={layout} isViewMode={isViewMode || !isEditable} />
                        </div>
                    );
                })}

            </div>
        </>
    );
};

export default forwardRef(RecordSection);
