type EventCallback = (data?: unknown) => void;

class SignalEmitter {

    private events: Map<string, Set<EventCallback>> = new Map();

    subscribe(eventId: string, callback: EventCallback) {
        if (!this.events.has(eventId)) {
            this.events.set(eventId, new Set());
        }

    this.events.get(eventId)!.add(callback);

    return () => this.unsubscribe(eventId, callback); // Return cleanup function
    }

    unsubscribe(eventId: string, callback: EventCallback) {
        if (this.events.has(eventId)) {
      this.events.get(eventId)!.delete(callback);

      if (this.events.get(eventId)!.size === 0) {
          this.events.delete(eventId);
      }
        }
    }

    emit(eventId: string, data?: unknown) {
        if (this.events.has(eventId)) {
            for (const callback of this.events.get(eventId)!) {
                callback(data);
            }
        }
    }

}
const UpdateSignalEmitter = new SignalEmitter();
export default UpdateSignalEmitter;
