import {XSBO} from '@aktek/types';
import {useRef} from 'react';
import {useDrop} from 'react-dnd';

import {TField, TSBOActions, TSection} from '../types/SBOEditor.t';
import SBOEditorField from './SBO.EditorField';

const FIELD_DRAG_TYPE = 'field';

type TSBOEditorSectionFieldsProps = {
    currentSBO: XSBO;
    sboActions: TSBOActions;
    section: TSection;
    expandedFieldIds: Set<string>;
    setExpandedFieldIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export default function SBOEditorSectionFields(props: TSBOEditorSectionFieldsProps) {
    const {section, currentSBO, sboActions, expandedFieldIds, setExpandedFieldIds} = props;
    const dropRef = useRef<HTMLDivElement>(null);

    const [{isOver, canDrop}, drop] = useDrop({
        accept: FIELD_DRAG_TYPE,
        hover: (item: {fieldId: string, sectionId: string, index: number, type: string}) => {
            const fromSectionId = item.sectionId;

            const toSectionId = section.id;

            // Don't do anything if hovering over the same section
            if (fromSectionId === toSectionId) return;

            // Move to the end of this section
            sboActions.moveField(item.fieldId, fromSectionId, toSectionId);

            // Update the dragged item's section
            item.sectionId = toSectionId;
            item.index = (section.fields || []).length;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    // Apply the drop ref
    drop(dropRef);

    const showDropIndicator = isOver && canDrop;

    return (
        <div
            ref={dropRef}
            className={`
                min-h-[20px]
                relative
                transition-all duration-200 ease-in-out
                ${showDropIndicator ? 'after:absolute after:inset-0 after:rounded-lg after:pointer-events-none' : ''}
                ${(!section.fields || section.fields.length === 0) ? 'p-1' : ''}
            `}
        >
            {section && section.fields
                ? section.fields.map((fieldId, index) => {
                    if (currentSBO?.fieldsMap[fieldId]) {
                        // Cast the field to TField type to ensure compatibility
                        const field = currentSBO?.fieldsMap[fieldId] as unknown as TField;

                        return <SBOEditorField
                            key={fieldId}
                            field={field}
                            index={index}
                            sectionId={section.id}
                            sboActions={sboActions}
                            tableFields={currentSBO.tableFields}
                            isExpanded={expandedFieldIds.has(fieldId)}
                            onExpandChange={(expanded) => {
                                setExpandedFieldIds((prev) => {
                                    const newSet = new Set(prev);

                                    if (expanded) {
                                        newSet.add(fieldId);
                                    } else {
                                        newSet.delete(fieldId);
                                    }

                                    return newSet;
                                });
                            }}
                        />;
                    }

                    return null;
                }).filter(Boolean)
                : null
            }
        </div>
    );
}
