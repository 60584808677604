import useFetchMorphs, {TMorphReturn} from './useFetchMorphs';
import useFetchSBO, {TSBOReturn} from './useFetchSBO';

export type TMorphSpreadsheetProps = {
    pageLength?: number;
    sorting: unknown;
    userFilter: unknown;
    sboId: string;
    mode: string;
    userFilter: unknown;
};

export type TMorphSpreadsheetReturn = TMorphReturn & TSBOReturn & {
    isPageLoading: boolean;
    pageLength: number;
};

const useMorphSpreadsheet = (props: TMorphSpreadsheetProps): TMorphSpreadsheetReturn => {
    const {pageLength = 25, sorting, sboId, mode, userFilter} = props;

    const sbo = useFetchSBO(sboId, mode);
    const morphs = useFetchMorphs({sboId, mode, sorting, userFilter, pageLength});

    const isPageLoading = [
        morphs.isDataUpdating,
        morphs.isDataDeleting,
        morphs.isDataLoading,
        sbo.isSBOLoading,
    ].some((status) => !!status);

    return {
        isPageLoading,
        ...morphs,
        ...sbo,
        pageLength,
    };
};

export default useMorphSpreadsheet;
