export const findScrollableElement = (element: HTMLElement | null): HTMLElement | null => {
    if (!element) return null;

    const style = window.getComputedStyle(element);
    const overflowY = style.getPropertyValue('overflow-y');

    if (overflowY === 'auto' || overflowY === 'scroll') {
        return element;
    }

    return findScrollableElement(element.parentElement);
};
