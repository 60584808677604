
import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';
import SBOFieldCommon from './SBO.Field.Common';
import SBOFieldGenericFile from './SBO.Field.GenericFile';
import SBOFieldImageVideoAlbum from './SBO.Field.ImageVideoAlbum';
import SBOFieldIntegerDecimal from './SBO.Field.IntgerDecimal';

export default function SBORenderField(props: TSBOEditorFieldProps) {
    switch (props.field?.type) {
        case 'string':
        case 'boolean':
        case 'date':
        case 'dateTime':
        case 'time':
        case 'gps':
        case 'color':
        case 'georepo':
            return <SBOFieldCommon {...props} />;

        case 'integer':
        case 'float':
            return <SBOFieldIntegerDecimal {...props} />;

        case 'uploadlink':
            return <SBOFieldGenericFile {...props} />;

        case 'mediaImage':
        case 'mediaVideo':
        case 'mediaAlbum':
            return <SBOFieldImageVideoAlbum {...props} />;

        default:
            return <SBOFieldCommon {...props} />;
    }
}

