import {BaseColorPicker, Icon} from '@aktek/f4kit';
import {faFolder, faTable} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {getImageOrColorSelectorClasses} from '../styles/ImageOrColorSelector.styles';
import ImageOrColorSelectorWarning from './ImageOrColorSelector.Warning';

type TImageOrColorSelectorColorProps = {
  value: string,
  onChange: (value: string) => void,
  type?: 'report' | 'folder',
  hasImage?: boolean,
}

const ImageOrColorSelectorColor = (props: TImageOrColorSelectorColorProps) => {
    const {value, onChange, type, hasImage} = props;

    const classes = getImageOrColorSelectorClasses();

    return (
        <div className="flex flex-col items-center gap-4">
            {!hasImage && <div className="flex px-3 py-2" />}
            <ImageOrColorSelectorWarning label={strings('colored_icon_override_warning')} show={hasImage} />
            <div className="flex flex-row justify-between items-start gap-2 w-full">
                <BaseColorPicker
                    className={classes.chrome('4xl')}
                    color={value}
                    onChange={(color)=> onChange(color.hex)}
                />

                <Icon
                    icon={type === 'report' ? faTable : faFolder}
                    size="2xl"
                    style={{
                        color: value,
                        backgroundColor: `${value}33`, // 33 is hex for 20% opacity
                    }}
                    className="rounded-xl p-7"
                />
            </div>
        </div>
    );
};

export default ImageOrColorSelectorColor;
