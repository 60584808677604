import {uuid} from 'short-uuid';

import ProcessedEnv from './env.process';
const {
    BASE_URL,
    GOOGLE_MAP_LAT_LNG_API,
    hasDevMode,
    LOCAL,
    UPLOAD_FILE_HOST,
    SCHEMA = 'https://',
} = ProcessedEnv;

const getURL = (url: string) => url?.startsWith?.('http') ? url : (SCHEMA || '') + url;

const getFileURL = (fileName: string) => getURL(UPLOAD_FILE_HOST) + '/' + fileName;

const getNewEmptyFileURL = () => {
    const fileName = uuid();
    const url = getFileURL(fileName);

    return {url, fileName};
};

const baseURL = getURL(BASE_URL);
const GoogleMapLatLngAPI = getURL(GOOGLE_MAP_LAT_LNG_API);
const local = getURL(LOCAL);

const Env = {
    getURL,
    baseURL,
    getFileURL,
    getNewEmptyFileURL,
    GoogleMapLatLngAPI,
    hasDevMode,
    local,
};

export default Env;

