import {Icon, Tooltip, TSpreadsheetTypes} from '@aktek/f4kit';
import {XField, XFieldConnectedRef} from '@aktek/types';
import {faAsterisk, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {validateCell} from '../helpers/validateCell';
import generateGetDeniedColumnProperty from './generateGetDeniedColumnProperty';
import {type TFieldsMap} from './Types/BuildColumns.types';

export default (
    field: XField,
    editableFieldsIDsSet: Set<string>,
    fieldsMap: TFieldsMap,
    clearAffectsByField: Record<string, TSpreadsheetTypes['TColumnAffectRelation'][]>,
): TSpreadsheetTypes['TColumn'] => {
    return {
        key: field.id,
        originalField: field,
        type: field.type,
        getValid: validateCell(field),
        getDenied: generateGetDeniedColumnProperty(editableFieldsIDsSet, fieldsMap, field),
        label: <span className="flex items-start">
            {field.name}
            {field.isRequired && <Tooltip content={strings('spreadsheet_message_is_required')}>
                <span className="text-error-500">*</span>
            </Tooltip>}
        </span>,
        // prefix: <Icon icon={faInfoCircle} />,
        suffix: field.description ? <Icon className="ml-1" icon={faInfoCircle} tooltip={field.description} /> : null,
        isRequired: field.isRequired,
        validatePastedCells: !['string'].includes(field.type.toLowerCase()),
        asyncValidateEditedCells: false,
        clearAffects: clearAffectsByField[field.id],
        affecter: (field as XFieldConnectedRef)?.connectedFieldId,
        description: field.description,
    };
};

