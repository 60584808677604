import {LoadingIndicator, TextBox, TSize} from '@aktek/f4kit';
import {faSearch, faTimes} from '@fortawesome/pro-regular-svg-icons';
import _debounce from 'lodash/debounce';
import {Dispatch, forwardRef, SetStateAction, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';

import {strings} from '@/localization/i18n';

import {GetClasses} from './styles/SearchBox.styles';

type TSearchBoxProps = {
    value?: string;
    onChange?: Dispatch<SetStateAction<string>>
    onKeyDown?: (k) => void
    onPressEnter?: () => void
    isGhost?: boolean
    isLoading?: boolean
    hasBottomBorder?: boolean
    placeholder?: string
    className?: string
    textBoxClassName?: string;
    textBoxInputClassName?: string;
    onSearch: Dispatch<SetStateAction<string>>| ((searchValue: string) => void);
    debounceTime?: number;
    autoFocusOnOpen?: boolean;
    size?:TSize
}

const SearchBox = ({
    onSearch,
    debounceTime = 250,
    isGhost = true,
    isLoading = false,
    placeholder = strings('search_folders'),
    className = 'font-medium text-sm',
    hasBottomBorder = true,
    textBoxClassName= '',
    textBoxInputClassName = '',
    autoFocusOnOpen = true,
    size = 'xs',
}: TSearchBoxProps, ref) => {
    const searchBoxRef = useRef(null);
    const [value, setValue] = useState('');

    const isSearching = value && value.length > 0;
    const icon = isSearching ? faTimes : undefined;

    const debouncedOnSearch = useCallback(_debounce((searchValue: string) => {
        onSearch?.(searchValue.trim());
    }, debounceTime), []);

    useEffect(() => {
        debouncedOnSearch(value);
    }, [value]);

    useImperativeHandle(ref, () => ({
        clear: () => {
            setValue('');
        },
        focus: focusFunction,
    }));

    const onCancelSearch = () => {
        setValue('');
        onSearch?.('');

        if (searchBoxRef.current) {
            searchBoxRef.current.focus();
        }
    };

    const focusFunction = () => {
        if (searchBoxRef.current) {
            searchBoxRef.current.focus();
        }
    };

    const handleOnKeyDown = (k: React.KeyboardEvent<HTMLElement>) => {
        if (k.code == 'Enter') {
            onSearch?.(value);
        }
    };

    const classes = GetClasses(hasBottomBorder, className);

    return (
        <div
            ref={ref}
            className={classes.container}>
            <TextBox
                autoFocus={autoFocusOnOpen}
                size={size}
                onKeyDown={handleOnKeyDown}
                ref={searchBoxRef}
                icons={{
                    left: [{icon: faSearch, color: 'neutral-600', onClick: focusFunction, className: 'hover:!text-neutral-600 hover:!opacity-100 pl-1'}],
                    right: [{icon, color: 'neutral-900', className: 'pr-2', onClick: onCancelSearch}]}}
                variant="neutral"
                value={value}
                inputClassName={textBoxInputClassName}
                className={textBoxClassName}
                isGhost={isGhost}
                type="text"
                placeholder={placeholder}
                onChange={setValue} />

            {isLoading && <LoadingIndicator color="primary-600" />}
        </div>
    );
};

export default forwardRef(SearchBox);
