import {Modal, TModalProps, TModalRef, usePropState} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {forwardRef, useCallback, useEffect, useMemo, useState} from 'react';

import {TFolder} from '@/types/Folder.t';

import ImageOrColorSelectorBody from './components/ImageOrColorSelector.Body';
import ImageOrColorSelectorFooter from './components/ImageOrColorSelector.Footer';
import ImageOrColorSelectorHeader from './components/ImageOrColorSelector.Header';
import GetCompleteSBO from './helpers/ImageOrColorSelector.GetCompleteSbo.fn';
import GetTabOptions from './helpers/ImageOrColorSelector.GetTabOptions.fn';
import useImageOrColorSelector from './hooks/useImageOrColorSelector';
import {getImageOrColorSelectorClasses} from './styles/ImageOrColorSelector.styles';

export type TImageColorSelectorValueType = 'image' | 'color';

export type TImageColorSelectorValue = {
    type:TImageColorSelectorValueType,
    value: string;
}

export type TImageColorSelectorProps = {
    modalProps?: Partial<TModalProps>;
    className?: string;
    availableTypes?: TImageColorSelectorValueType[];
    onChange?: (value: TImageColorSelectorValue) => void;
    value?: TImageColorSelectorValue;
    entity?: XSBO;
    info: {type: 'report' | 'folder', id?: string, initialValue?: TFolder};
    onConfirm?: (data: TImageColorSelectorValue) => void;
}

function ImageOrColorSelectorModal(props: TImageColorSelectorProps, ref: TModalRef) {
    const {
        className,
        modalProps = {size: 'xs'},
        availableTypes = ['image', 'color'],
        onChange,
        info = {type: 'report', id: ''},
        onConfirm,
    } = props;

    const [state, setState] = useState({
        entity: null as XSBO | null,
        loading: false,
    });

    const defaultValue = useMemo<TImageColorSelectorValue>(() => {
        const source = info.type === 'report' ? state.entity : info.initialValue;
        const hasIcon = source?.customIcon;
        const hasColor = source?.customColor;

        return {
            type: hasIcon ? 'image' : hasColor ? 'color' : 'image',
            value: hasIcon ? source.customIcon : hasColor ? source.customColor : null,
        };
    }, [state.entity, info]);

    const [option, setOption] = useState<'image' | 'color'>(defaultValue.type);
    const [finalValue, setValue] = usePropState<TImageColorSelectorValue>(defaultValue);

    const handleOptionChange = useCallback((newOption: 'image' | 'color') => {
        setOption(newOption);
    }, []);

    const handleValueReset = useCallback(() => {
        setValue({...finalValue, value: null});
    }, [finalValue]);

    const {handleConfirm, onCancel, isConfirmButtonLoading} = useImageOrColorSelector(
        info,
        state.entity,
        finalValue,
        onChange,
        onConfirm,
        ref,
    );

    useEffect(() => {
        if (info.type !== 'report') return;

        GetCompleteSBO({
            id: info.id,
            onResponse: (entity) => setState((prev) => ({...prev, entity})),
            onLoad: (loading) => setState((prev) => ({...prev, loading})),
        });
    }, [info.type, info.id]);

    useEffect(() => {
        if (defaultValue?.type) {
            setOption(defaultValue.type);
        }
    }, [defaultValue]);

    const tabOptions = useMemo(() => GetTabOptions(availableTypes), [availableTypes]);
    const classes = getImageOrColorSelectorClasses(className);
    const isButtonDisabled = finalValue == defaultValue || finalValue.type !== option;

    return (
        <Modal
            ref={ref}
            title={null}
            hasXIcon={false}
            headerClassName={classes.header}
            className={classes.modal}
            {...modalProps}
        >
            <div className={classes.container}>
                <ImageOrColorSelectorHeader
                    option={option}
                    onChange={handleOptionChange}
                    tabOptions={tabOptions}
                />
                <ImageOrColorSelectorBody
                    loading={state.loading}
                    option={option}
                    type={info.type}
                    finalValue={finalValue}
                    onChange={setValue}
                />
                <ImageOrColorSelectorFooter
                    isButtonDisabled={isButtonDisabled}
                    isConfirmButtonLoading={isConfirmButtonLoading}
                    finalValue={finalValue}
                    onCancel={onCancel}
                    option={option}
                    onConfirm={handleConfirm}
                    onResetToDefault={handleValueReset}
                />
            </div>
        </Modal>
    );
}

export default forwardRef(ImageOrColorSelectorModal);
