
import {faT} from '@fortawesome/pro-regular-svg-icons';

import {TRecordField} from '@/app/DataTable/types/Record.Types';
import F4Textbox from '@/components/F4Elements/F4TextBox';

import FieldBase from '../components/Field.Base';
import {StringClasses} from './styles/Strings.styles';

type TStringRecordEdit = {
    field:TRecordField;
    onChange?: (data) => void;
    value?:string;
    layout:boolean;
    isViewMode:boolean;
}

const StringRecordEdit = (props:TStringRecordEdit) => {
    const {field, onChange, layout, isViewMode} = props;
    const {name, id, showDescription, description, isRequired} = field;

    const classes = StringClasses(layout);

    return <div className={classes.container}>
        <FieldBase
            name={name}
            isRequired={isRequired}
            description={description}
            showDescription={showDescription}
            icon={faT}
            iconColor="success-600"
            classes={classes}
        />
        <div className={classes.textboxContainer}>

            <F4Textbox
                isGhost
                placeholder=""
                isDisabled={isViewMode}
                inputClassName={classes.inputClassName}
                name={id}
                isMultiLineFitHeight
                onChange={onChange}
                required={isRequired}
                className={classes.textbox}
            />
        </div>
    </div>;
};

export default StringRecordEdit;
