import {F4FormContainer, F4FormElementDecorator, F4FormStep, useF4FormContext} from '@aktek/f4form';
import _isEmpty from 'lodash/isEmpty';
import {TF4DecoratedElementProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4DecoratedElementProps.t';
import {useEffect, useMemo, useState} from 'react';

import AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4TextBox from '@/components/F4Elements/F4TextBox';
import {LANGUAGES} from '@/constants/languages';
import {useAppPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {COMPANY_SUBTYPE, JOB_PRIORITY, TYPES} from '../constants/constants.c';
import {ConvertToAccountManagers} from '../helpers/Agency.ConvertToAccountManagers.fn';
import GetDefaultLanguages from '../helpers/Agency.GetDefaultLanguages.fn';
import GetSortedAvailableLanguages from '../helpers/Agency.GetSortedAvailableLanguages.fn';
import useAgency from '../hooks/useAgency';

type TCompanyInfo = TF4DecoratedElementProps & {
    mode: string;
}

export function CompanyInfo({mode}: TCompanyInfo) {
    const {listAccountManagers, getAllAccountManagers} = useAgency();
    const {manageAccountManagers} = useAppPermissions();
    const context = useF4FormContext();
    const languages = context.data?.['languages'];

    const isViewMode = mode == 'view';
    const isEditMode = mode == 'edit';
    const [_availableLanguages, setAvailableLanguages] = useState([]);

    const defaultLanguages = useMemo(() => GetDefaultLanguages(_availableLanguages), [_availableLanguages]);

    const sortedAvailableLanguages = useMemo(GetSortedAvailableLanguages, [LANGUAGES]);

    useEffect(() => {
        if (languages) {
            setAvailableLanguages(languages);
        }
    }, [languages]);

    useEffect(() => {
        if (manageAccountManagers?.can_access) {
            getAllAccountManagers();
        }
    }, [manageAccountManagers]);

    const convertedToAccountManagers = useMemo(
        () => ConvertToAccountManagers(listAccountManagers),
        [listAccountManagers],
    );

    const hasNoAccountManagers = useMemo(
        () => _isEmpty(convertedToAccountManagers),
        [convertedToAccountManagers],
    );

    const accountManagersPlaceHolder = useMemo(() => {
        if (isViewMode) {
            return strings('not_specified');
        }

        if (hasNoAccountManagers) {
            return strings('no_account_managers_found');
        }

        return undefined;
    }, [isViewMode, hasNoAccountManagers]);

    return (
        <F4FormStep step="companyDetails">

            <div className="overflow-y-auto flex flex-col gap-4 pb-4">

                <F4FormContainer colSpan={12} className="w-full flex flex-col !gap-5">

                    <AvatarImageSelector
                        title={strings('company_logo')}
                        size="3xl"
                        isViewMode={isViewMode}
                        name="companyLogo"
                        allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                        maxSizeMB={1}
                    />

                    <F4TextBox
                        name="companyLoginId"
                        isDisabled={isViewMode}
                        label={strings('company_id')}
                        autoFocus
                        placeholder=""
                    />

                    <F4TextBox
                        required
                        isDisabled={isViewMode}
                        name="companyName"
                        label={strings('name')}

                        placeholder={strings('name')} />

                    <F4Dropdown
                        options={TYPES}
                        required
                        label={strings('type')}
                        isDisabled={isEditMode || isViewMode}
                        hideArrow={isViewMode}
                        name="companyType" />

                    <F4Dropdown
                        options={COMPANY_SUBTYPE}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        required
                        label={strings('company_subtype')}

                        name="companySubtype" />

                    <F4Dropdown
                        options={sortedAvailableLanguages}
                        isDisabled={isViewMode}
                        isClearable={!isViewMode}
                        hideArrow={isViewMode}
                        required
                        isMulti
                        label={strings('available_languages')}
                        onChange={(value) => {
                            if (value) {
                                setAvailableLanguages(value);

                                return;
                            }

                            setAvailableLanguages([]);
                        }}
                        validate={(value) => {
                            if (!value?.['length']) {
                                return {
                                    isValid: false,
                                    messages: [strings('field_is_mandatory')],
                                };
                            }

                            return {isValid: true};
                        }}
                        name="languages" />

                    <F4Dropdown
                        options={defaultLanguages}
                        isDisabled={_availableLanguages.length === 0 || isViewMode}
                        hideArrow={isViewMode}
                        required
                        label={strings('default_language')}
                        name="defaultLanguage" />

                    <F4Dropdown
                        options={JOB_PRIORITY}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        required
                        label={strings('job_priority')}
                        name="jobPriority" />

                    {manageAccountManagers?.can_access
                    && <F4Dropdown
                        options={convertedToAccountManagers}
                        isDisabled={isViewMode || hasNoAccountManagers}
                        label={strings('accountManager')}
                        name="accountManagers"
                        isClearable={!isViewMode && !hasNoAccountManagers}
                        hideArrow={isViewMode || hasNoAccountManagers}
                        placeholder={accountManagersPlaceHolder}
                        isMulti
                    />
                    }

                </F4FormContainer>
            </div>
        </F4FormStep>
    );
}

const F4CompanyInfo = F4FormElementDecorator<TCompanyInfo>(CompanyInfo);
export default F4CompanyInfo;
