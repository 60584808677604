import {TContextMenuEntry} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {faArrowTurnRight, faCopy, faTrash} from '@fortawesome/pro-regular-svg-icons';

import {TField, TSBOActions} from '../types/SBOEditor.t';

export default function GetFieldContextMenuEntries(
    sectionId: string,
    field: TField,
    sboActions: TSBOActions,
    currentSBO: XSBO,
): TContextMenuEntry[] {
    const result: TContextMenuEntry[] = [];
    const {id: fieldId} = field;

    result.push({
        key: 'duplicate',
        icon: faCopy,
        label: 'Duplicate',
        color: 'neutral-700',
        onClick: ()=> sboActions.addField(field, sectionId),
    });

    result.push({
        key: 'delete',
        icon: faTrash,
        label: 'Delete',
        color: 'error-700',
        onClick: ()=> sboActions.deleteField(sectionId, fieldId),
    });

    result.push({
        key: 'moveTo',
        icon: faArrowTurnRight,
        label: 'Move To',
        color: 'neutral-700',
        className: 'hover:bg-neutral-50',
        subEntries: (currentSBO.sections || []).map((sectionId) => {
            const section = currentSBO.sectionsMap[sectionId];
            // Don't show current section in the list
            if (section.id === field.parentSection) return null;

            return {
                key: `move-to-${section.id}`,
                label: section.name || `Section ${section.id}`,
                onClick: () => sboActions.moveField(field.id, field.parentSection, section.id),
            };
        }).filter(Boolean).concat({
            key: 'move-to-new-section',
            label: 'Add New Section +',
            labelProps: {className: '!text-primary-700'},
            onClick: () => {
                const newSection = {id: '', fields: []};
                const {sectionId: newSectionId} = sboActions.addSection(newSection);

                if (newSectionId) {
                    sboActions.moveField(field.id, field.parentSection, newSectionId);
                }
            },
        }),
    });

    return result;
}
