import {Card, Icon, Label, TabsManager, TContextMenuEntry} from '@aktek/f4kit';
import {faCircleNodes, faLink, faRefresh, faTable} from '@fortawesome/pro-regular-svg-icons';
import {useCallback} from 'react';

import AskForMenu from '@/asks/AskForMenu';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import {getRecentlyClasses} from '../styles/RecentlyUsed.styles';
import EmptyComponent from './EmptyComponent';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

export default function RecentlyUsed({explorerUIState}) {
    const {isLoading, recentlyUsed, refresh} = useGlobalFilesAndFolders();
    const getEntries = useCallback((item) => {
        return [
            {
                key: 'open',
                label: strings('open'),
                onClick: () => handleReportDoubleClick(item),
                icon: null,
            },
            // { label: strings('remove') },
        ] as TContextMenuEntry[];
    }, []);

    const handleReportDoubleClick = useCallback((item) => {
        const sboId = item.props.sboId;
        const label = item.component;
        TabsManager.focusOrOpenNewTab('dataTable', label, {label, sboId}, {tabKey: `dataTable-${sboId}`});
    }, []);

    const classes = getRecentlyClasses();

    const IconByType = {
        report: faTable,
        shortcut: faLink,
        geo: faCircleNodes,
    };

    const getIcon = (type) => IconByType[type || faLink];

    if (!recentlyUsed?.length) {
        return <EmptyComponent
            explorerUIState={explorerUIState}
            label={strings('no_recent_files')}
            description={strings('no_recent_files_desc')}
            hasCreateButton={false}
            rightPanelViewLabel={strings('recently_used')}
        />;
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Label
                    text={strings('recently_used')}
                    size="sm"
                    fontWeight="semibold"
                    color="primary-700"
                    className="pl-1"
                />
                <Icon
                    icon={faRefresh}
                    color="neutral-600"
                    className="cursor-pointer p-2.5"
                    size="md"
                    tooltip={strings('refresh')}
                    tooltipProps={{
                        showDelay: 500,
                    }}
                    onClick={refresh}
                />
            </div>

            <div className={classes.mapWrapper}>
                {recentlyUsed?.map((item, index) => (
                    <Card
                        className={classes.card}
                        key={`${item._id}-${index}`}
                        borderColor="neutral-200"
                        onContextMenu={(event) =>
                            AskForMenu(event, getEntries(item) as TContextMenuEntry[], {
                                placement: 'bottom-start',
                            })
                        }
                        backgroundColor="white"
                        onDoubleClick={() => handleReportDoubleClick(item)}
                    >
                        <Icon
                            icon={getIcon(item?.props?.type)}
                            className="mr-2"
                            color="neutral-400"
                        />
                        <Label
                            text={item.name}
                            size="xs"
                            className={classes.label}
                            color="neutral-900"
                        />
                    </Card>
                ))}
            </div>

            <ExplorerLoadingComponent condition={isLoading} />
        </div>
    );
}

