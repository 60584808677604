export default function GetNewElementSelectedAndFocused(prefix: 'section'| 'field', elementId: string) {
    // Initial delay to ensure DOM is ready
    setTimeout(() => {
        const element = document.getElementById(`${prefix}-${elementId}`);

        if (element) {
            // Find the name input within the field element
            const nameInput = element.querySelector(`input[name="fieldsMap.${elementId}.name"]`) as HTMLInputElement;

            // Expand the field if it's a field element
            if (prefix === 'field' && 'expandField' in element) {
                element.expandField();
            }

            if (prefix === 'section' && 'expandSection' in element) {
                element.expandSection();
            }

            // Calculate the middle of the viewport with additional offset
            const viewportHeight = window.innerHeight;
            const elementHeight = element.getBoundingClientRect().height;
            const offset = (viewportHeight - elementHeight) / 2;

            // Add extra padding to ensure element is fully visible
            element.style.scrollMarginTop = `${offset + 100}px`;
            element.style.scrollMarginBottom = `${offset + 100}px`;

            // First scroll
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });

            // Second scroll after a delay to ensure proper positioning
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });

                // Focus the name input after scrolling is complete
                setTimeout(() => {
                    if (nameInput) {
                        nameInput.focus();
                        nameInput.select(); // Optional: select all text if there's any
                    }
                }, 300);
            }, 300);
        }
    }, 200);
}
