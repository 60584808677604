import {fa00, faSquare3} from '@fortawesome/pro-regular-svg-icons';
import {useMemo} from 'react';

import {TRecordField} from '@/app/DataTable/types/Record.Types';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import {DISPLAY_CURRENCY_TYPES} from '@/constants/FieldsDisplay.c';

import FieldBase from '../components/Field.Base';
import GetIntegerFieldName from './helpers/Integer.GetFieldName.fn';
import useIntegerValidation from './hooks/useIntegerValidation';
import {IntegerClasses} from './styles/Integer.styles';

type TIntegerRecordEdit = {
    field:TRecordField;
    isDecimalField?:boolean;
    layout:boolean;
    isViewMode:boolean;
}

const IntegerRecordEdit = (props:TIntegerRecordEdit) => {
    const {field, isDecimalField = false, layout, isViewMode} = props;
    const {id, showDescription, description, maximum, minimum, isRequired, currencyType, numberType} = field;

    const isPercentageType = numberType == 'percentage';
    const rightPlaceholder = isPercentageType ? '%' : '';

    const name = useMemo(() => GetIntegerFieldName(field), [field]);

    const handleValidation = useIntegerValidation(maximum, minimum, isDecimalField);

    const classes = IntegerClasses(layout);

    return (
        <div className={classes.container}>
            <FieldBase
                name={name}
                isRequired={isRequired}
                description={description}
                showDescription={showDescription}
                icon={isDecimalField ? fa00 : faSquare3}
                iconColor={isDecimalField ? 'error-600' : 'semantic3-600'}
                classes={classes}
            />
            <div className={classes.textboxContainer}>

                <F4Textbox isGhost
                    name={id}
                    required={isRequired}
                    isMultiLineFitHeight
                    isNumeric
                    inputClassName={classes.inputClassName}
                    isDisabled={isViewMode}
                    leftPlaceholder={DISPLAY_CURRENCY_TYPES[currencyType] ?? ''}
                    rightPlaceholder={rightPlaceholder}
                    validate={handleValidation}
                    className={classes.textbox}
                />
            </div>
        </div>

    );
};

export default IntegerRecordEdit;
