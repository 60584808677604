import {TSpreadsheetTypes} from '@aktek/f4kit';
import {isFinite, isNumber, isObject} from 'lodash';

import BooleanParser from './helpers/BooleanParser';
import ColorParser from './helpers/ColorParser';
import DateParser from './helpers/DateParser';
import DateTimeParser from './helpers/DateTimeParser';
import FloatParser from './helpers/FloatParser';
import GPSTransformer from './helpers/GPSParser';
import IntegerParser from './helpers/IntegerParser';
import {reshape, reshapeArray} from './helpers/reshapeFunctions';
import TimeParser from './helpers/TimeParser';

export const fromCSV:TSpreadsheetTypes['valueConverter']['fromCSV'] = (csvValue: string, field: object) => {
    if (field.type == 'tags') {
        let value;

        if (Array.isArray(value?.tags)) {
            value = {
                tags: value.tags,
                joined: value.tags.join(','),
            };
        }

        if (typeof value=='number') value = value + '';

        if (typeof value=='string') {
            value = {
                tags: [],
                joined: '',
            };
        }

        return {
            status: 'final',
            value,
        };
    }

    const type = field.type.toString();

    if (csvValue == null || csvValue == undefined || (typeof csvValue == 'string' && csvValue?.trim()?.length == 0)) {
        return {
            status: 'final',
            value: null,
        };
    }

    switch (type) {

        case ('string'): {
            return {
                status: 'final',
                value: csvValue?.toString?.(),
            };
        }

        case ('boolean'): {
            return {
                status: 'final',
                value: BooleanParser(csvValue),
            };
        }

        case ('integer'): {
            return {
                status: 'final',
                value: IntegerParser(csvValue, {min: field.minimum, max: field.maximum}),
            };
        }

        case ('float'): {
            return {
                status: 'final',
                value: FloatParser(csvValue, {min: field.minimum, max: field.maximum}),
            };
        }

        case ('gps'): {
            if (isObject(csvValue) && ('lat' in csvValue|| 'lng' in csvValue)) {
                return {
                    status: 'final',
                    value: csvValue,
                };
            }

            if (typeof csvValue == 'string') {
                return {
                    status: 'final',
                    value: GPSTransformer(csvValue),
                };
            }

            break;
        }

        case ('date'): {
            // Means it's Unix Timestamp
            if (isFinite(Number(csvValue))) {
                return {
                    status: 'final',
                    value: csvValue,
                };
            }

            if (typeof csvValue == 'string') {
                return {
                    status: 'final',
                    value: DateParser(csvValue),
                };
            }

            break;
        }

        case ('time'): {
            // Means it's Unix Timestamp
            if (isFinite(Number(csvValue))) {
                return {
                    status: 'final',
                    value: csvValue,
                };
            }

            if (typeof csvValue == 'string') {
                return {
                    status: 'final',
                    value: TimeParser(csvValue),
                };
            }

            break;
        }

        case ('datetime'): {
            // Means it's Unix Timestamp
            if (isFinite(Number(csvValue))) {
                return {
                    status: 'final',
                    value: csvValue,
                };
            }

            if (typeof csvValue == 'string') {
                return {
                    status: 'final',
                    value: DateTimeParser(csvValue),
                };
            }

            break;
        }

        case ('meta'): {
            return {
                status: 'text',
                value: csvValue,
            };
        }

        case ('ref'): {
            if (!csvValue) {
                return {
                    status: 'final',
                    value: null,
                };
            }

            if (Array.isArray(csvValue) && '_id' in csvValue[0] && isFinite(csvValue[0]._id)) {
                return {
                    status: 'final',
                    value: {_id: csvValue[0]?._id},
                };
            }

            if (isObject(csvValue) && ('_id' in csvValue) && isNumber(csvValue._id)) {
                return {
                    status: 'final',
                    value: {_id: csvValue._id},
                };
            }

            return {
                status: 'text',
                value: reshape(csvValue),
            };
        }

        case ('refList'): {
            if (!csvValue) {
                return {
                    status: 'final',
                    value: null,
                };
            }

            if (Array.isArray(csvValue) && csvValue.every((e)=> ('_id' in e) && isFinite(e._id))) {
                return {
                    status: 'final',
                    value: csvValue.map((e)=> ({_id: e._id})),
                };
            }

            if (typeof csvValue?._id == 'number') {
                return {
                    status: 'final',
                    value: [{_id: csvValue?._id}],
                };
            }

            if (typeof csvValue == 'string') {
                return {
                    status: 'text',
                    // value: reshape(pastedValue)
                    value: reshapeArray(csvValue),
                };
            }

            break;
        }

        case ('connectedRef'): {
            if (!csvValue) {
                return {
                    status: 'final',
                    value: null,
                };
            }

            if (isObject(csvValue) && isNumber(csvValue?._id)) {
                return {
                    status: 'final',
                    value: csvValue,
                };
            }

            return {
                status: 'text',
                value: reshape(csvValue),
            };
        }

        case ('closedList'): {
            if (typeof csvValue == 'string') {
                return {status: 'final',
                    value: csvValue,
                };
            }
        }

        case ('color'): {
            if (typeof csvValue == 'string') {
                return {status: 'final',
                    value: ColorParser(csvValue)};
            }

            break;
        }

        // case ('formula'): {
        //     break;
        // }

        default: {
            return {
                status: 'final',
                value: null,
            };
        }
    }

    return {
        status: 'final',
        value: null,
    };
};
