import {VALID_INTEGER} from '@/constants/Regex.c';
import {strings} from '@/localization/i18n';

const ValidateIntegerNumberValue = (value: number | string, maximum?: number, minimum?: number, exception?: boolean) => {
    const formattedValue = value?.toString()?.replace?.(',', '.');
    const numberValue = parseFloat(formattedValue);

    if (!value) {
        return {isValid: true};
    }

    if (formattedValue.toString().includes('.') && !exception) {
        return {isValid: false, messages: [strings('only_integer_number_is_accepted')]};
    }

    if (!exception && !Number.isInteger(numberValue) && value && value !== '+' && value !== '-' ) {
        return {isValid: false, messages: [strings('only_integer_number_is_accepted')]};
    }

    if (!VALID_INTEGER.test(formattedValue) && !exception && value !== '+' && value !== '-' ) {
        return {isValid: false, messages: [strings('only_integer_number_is_accepted')]};
    }

    if (maximum && numberValue > maximum) {
        return {isValid: false, messages: [`${strings('operator_max')} ${maximum}`]};
    }

    if (minimum && numberValue < minimum) {
        return {isValid: false, messages: [`${strings('operator_min')} ${minimum}`]};
    }

    return {isValid: true};
};

export default ValidateIntegerNumberValue;
