import {Label} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';

import {strings} from '@/localization/i18n';

import RecordSection from './Record.Section';

type TRecordBodyProps = {
    sections: XSBO['sections']
    fieldsMap: XSBO['fieldsMap']
    hasSections: boolean
    layout: boolean
    sectionsMap: XSBO['sectionsMap']
    isViewMode: boolean
    editableFieldsIDs: string[]
}

const RecordBody = (props: TRecordBodyProps) => {
    const {sections, fieldsMap, hasSections, layout, sectionsMap, isViewMode, editableFieldsIDs} = props;

    return (
        <div className="flex flex-col gap-2">
            {!hasSections && (
                <div className="flex  justify-center items-center">
                    <Label color="neutral-500" size="md" text={strings('no_sections_found')} />
                </div>
            )}

            {hasSections && sections?.map((sectionId) => (
                <RecordSection
                    key={sectionId}
                    layout={layout}
                    section={sectionsMap?.[sectionId]}
                    fieldsMap={fieldsMap}
                    isViewMode={isViewMode}
                    editableFieldsIDs={editableFieldsIDs} />
            ))}
        </div>
    );
};

export default RecordBody;
