import {XFieldMedia} from '@aktek/types';

import {TValidationResult} from '../../types/Validation.types';

const strings = {
    number_of_files_range: 'Number of files accepted is between #min and #max',
};

export const isValidMedia = (
    value: unknown,
    field: XFieldMedia,
): TValidationResult => {
    const mediaValue = Array.isArray(value) ? value : null;

    const message = strings.number_of_files_range
        ?.replace('#min', field.minimum?.toString() ?? '')
        .replace('#max', field.maximum?.toString() ?? '');

    if (field.minimum && (mediaValue?.length || 0) < field.minimum) {
        return {isValid: false, message};
    }

    if (field.maximum && (mediaValue?.length || 0) > field.maximum) {
        return {isValid: false, message};
    }

    return {isValid: true};
};
