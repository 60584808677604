import {useCurrentTab} from '@aktek/f4kit';
import {TTabContext} from 'node_modules/@aktek/f4kit/dist/components/TierOne/TabsFlexLayout/TabsContext/types/TabsContext.t';

import {TIOTabConfig} from '@/types/CommonTypes';

export default function useF4CurrentTab<TabState extends object = object, ExtraConfig extends object = object>() {
    const currentTab = useCurrentTab({});

    return currentTab as TTabContext<TIOTabConfig & ExtraConfig, TabState>;
}
