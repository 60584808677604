import {DateTime} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const TimeCellEdit = ({value, onChange}) => {
    return <div className="flex items-center gap-1">

        <DateTime defaultOpen
            mode="time"
            onChange={onChange}
            timeFormat="HH:mm"
            value={value}
            placeholder={strings('select_time')}
            minDate={0}
            maxDate={0}
            dateFormat="DD/MM/YYYY"
        />

    </div>;
};

export default TimeCellEdit;
