import {Button, TextBox} from '@aktek/f4kit';
import {faMap} from '@fortawesome/pro-regular-svg-icons';
import {useRef} from 'react';

import AskForModal from '@/asks/AskForModal';

import {MapPicker} from './components/MapPicker';

const GpsCellEdit = ({lat, lng, onChange}) => {
    const lngRef = useRef();

    return <div className="flex items-center gap-1">
        <TextBox allowDecimal fitValueToMinMaxMode isNumeric
            onKeyDown={(event) => {
                if (event.code !== 'Tab') return;
                event.stopPropagation();
            }}
            placeholder={'lat'}
            value={lat}
            className="min-w-16"
            onChange={(lat) => onChange({lat, lng})}
            min={-90}
            max={90}
        />
        <TextBox allowDecimal fitValueToMinMaxMode isNumeric
            ref={lngRef}
            placeholder={'lng'}
            value={lng}
            className="min-w-16"
            onChange={(lng) => onChange({lat, lng})}
            min={-180}
            max={180}
        />
        <Button
            size="md"
            variant="white"
            icon={faMap}
            className="ml-2"
            onClick={() => {
                AskForModal(MapPicker, {
                    initialLat: lat,
                    initialLng: lng,
                    onConfirm: ({lat, lng}) => onChange({lat, lng}),
                });
            }}
        />

    </div>;
};

export default GpsCellEdit;
