/**
 * Helper function to process numeric field values for SBO fields
 * Handles:
 * 1. Standalone '+' or '-' signs
 * 2. Trailing commas or periods
 * 3. Leading zeros for decimal values starting with a period
 * 4. Comma to dot conversion
 */

/**
 * Processes a numeric value according to formatting rules
 * @param {string | number | null | undefined} value - The numeric value to process
 * @return {string | number | null | undefined} The processed numeric value
 */
export const processNumericValue = (value: string | number | null | undefined): string | number | null | undefined => {
    // If it's not a string or is empty, return as is
    if (value === null || value === undefined || value === '') {
        return value;
    }

    if (typeof value !== 'string') {
        return value;
    }

    // Remove standalone '+' or '-' characters
    if (value === '+' || value === '-') {
        return '';
    }

    let processedValue = value;

    // Remove trailing commas or periods
    if (processedValue.endsWith(',') || processedValue.endsWith('.')) {
        processedValue = processedValue.slice(0, -1);
    }

    // Add leading zero to decimals starting with a period
    if (processedValue.startsWith('.') || processedValue.startsWith(',')) {
        processedValue = '0' + processedValue;
    }

    // Convert commas to dots
    processedValue = processedValue.replace(',', '.');

    return processedValue;
};

/**
 * Interface for a field with numeric properties
 */
interface NumericField {
    type: string;
    minimum?: string | number | null;
    maximum?: string | number | null;
    [key: string]: unknown;
}

/**
 * Processes minimum and maximum values for numeric fields in the SBO editor
 * @param {NumericField} field - The field object containing minimum and maximum properties
 */
export const processNumericFieldValues = (field: NumericField): void => {
    if (field.type === 'integer' || field.type === 'float') {
        // Handle minimum field
        if ('minimum' in field && field.minimum !== null && field.minimum !== undefined) {
            field.minimum = processNumericValue(field.minimum);
        }

        // Handle maximum field
        if ('maximum' in field && field.maximum !== null && field.maximum !== undefined) {
            field.maximum = processNumericValue(field.maximum);
        }
    }
};
