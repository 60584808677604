const container = 'flex pb-6 flex-col px-4 bg-neutral-50 w-full h-full overflow-y-auto overflow-x-hidden border-l border-neutral-200';
const header = 'flex flex-row py-4 bg-neutral-50 justify-between items-center sticky elevation-2 !shadow-none top-0 w-full';
const mapWrapper = 'grid gap-6 w-full max-w-none sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
const card = 'flex items-center p-4 rounded-lg cursor-pointer border border-neutral-200 hover:border-neutral-300 !max-h-12 overflow-hidden';
const label = 'cursor-pointer !truncate !text-neutral-900';

export const getRecentlyClasses = () => ({
    container,
    header,
    mapWrapper,
    card,
    label,
});
